import config from "../config";
import { User } from "../schemas/user";
import { rtFetch } from "../models/APIClient";
import { useMutation } from "@tanstack/react-query";

const baseUrl = config.serverPath;

type UserInfo = {
    googleId: string;
    email?: string;
    firstName?: string;
    lastName?: string;
};

async function updateUser(userInfo: UserInfo) {
    const path = `${baseUrl}/user/update`;

    const response = await rtFetch(path, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo),
    });

    if (!response.ok) {
        throw new Error("Failed to update user");
    }

    const json = await response.json();
    return User.parse(json);
}

export function useCreateUser() {
    return useMutation({
        mutationFn: updateUser,
    });
}
