import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { Box, Grid, Typography } from "@mui/material";
import RTable from "../components/RTable";
import BulkThingCreator from "../components/BulkThingCreator";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import SelectorThingsLabelManager from "../components/SensorThingsLabelManager";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

class ThingManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            things: [],
            lookup: {},
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadState();
    }
    async loadState() {
        const apiClient = new APIClient();
        let result = await apiClient.getAllThings(),
            result2 = await apiClient.getAllLocations(),
            things = await result.json(),
            locations = await result2.json(),
            lookup = {};

        locations.forEach(({ id, name }) => {
            lookup[id] = `${name} - ${id}`;
        });
        things = things.map((thing) => {
            if (thing.locationId == null) thing.locationId = "";
            return thing;
        });
        this.setState({ lookup, things, isLoading: false });
    }

    onRowUpdate = (newData) =>
        new Promise((resolve, reject) => {
            //Update everything
            let newDataUpdate = { ...newData };
            try {
                if (newDataUpdate.properties)
                    newDataUpdate.properties = JSON.parse(
                        newDataUpdate.properties
                    );
                if (newDataUpdate.locationId === "")
                    newDataUpdate.locationId = null;
            } catch (e) {
                this.setState(
                    errorMessage("Properties - expecting valid JSON")
                );
                reject();
                return;
            }
            //Trim out new lines and white spaces from beginning and end of labels
            newDataUpdate.selectorLabels = newDataUpdate.selectorLabels.map(
                (label) => label.trim()
            );
            function isThing(thing) {
                return thing.id === newData.id;
            }
            new APIClient()
                .updateThing(newDataUpdate)
                .then(async (res) => {
                    if (res.status !== 200) {
                        res = await res.json();
                        this.setState(errorMessage(res));
                        reject();
                        return;
                    }
                    //Update State
                    let things = [...this.state.things],
                        i = things.findIndex(isThing);
                    things[i] = newData;
                    this.setState({ things });
                    this.setState(successMessage("Successfully updated"));
                    resolve();
                })
                .catch((e) => {
                    this.setState(errorMessage(e));
                    reject();
                });
        });

    onCreateComplete = (successfullyAdded, error) => {
        const message =
            error == null
                ? successMessage(`${successfullyAdded.length} things added`)
                : errorMessage(error);
        this.setState({
            ...message,
            things: [...successfullyAdded, ...this.state.things],
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item lg={1} xs={12}>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Grid>
                <Grid item lg={12} xs={12} className={classes.description}>
                    <div className={classes.description}>
                        <Typography className={classes.title} variant="h6">
                            Thing
                        </Typography>
                        <Typography
                            className={classes.paragraph}
                            variant="body1"
                        >
                            A Thing is an object or device that can be
                            represented by a collection of sensors, for example,
                            a buoy with multiple sensors attached to it. Each
                            Thing also has a specific location. With this
                            dashboard, you can create new Things, update
                            existing Things, and delete Things.
                        </Typography>
                    </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Box>
                        <RTable
                            className={classes.table}
                            title={
                                <Typography variant="h3">
                                    Thing Manager
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "ID",
                                    field: "id",
                                    editable: "never",
                                },
                                {
                                    title: "Name *",
                                    field: "name",
                                },
                                {
                                    title: "Description *",
                                    field: "description",
                                },
                                {
                                    title: "Selector ID (Unique)",
                                    field: "selectorId",
                                },
                                {
                                    title: "Selector Label",
                                    field: "selectorLabels",
                                    render: (rowData) => (
                                        <SelectorThingsLabelManager
                                            value={rowData.selectorLabels}
                                            edit={false}
                                        ></SelectorThingsLabelManager>
                                    ),
                                    editComponent: (rowData) => {
                                        return (
                                            <SelectorThingsLabelManager
                                                value={rowData.value}
                                                onChange={rowData.onChange}
                                                edit={true}
                                            ></SelectorThingsLabelManager>
                                        );
                                    },
                                },
                                {
                                    title: "Location - Location ID",
                                    field: "locationId",
                                    lookup: this.state.lookup,
                                },
                                {
                                    title: "Properties (JSON)",
                                    field: "properties",
                                },
                            ]}
                            data={this.state.things}
                            editable={{
                                isEditable: () => true,
                                onRowAddCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowUpdateCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //add thing
                                        let newDataUpdate = { ...newData };
                                        try {
                                            if (newDataUpdate.properties)
                                                newDataUpdate.properties =
                                                    JSON.parse(
                                                        newDataUpdate.properties
                                                    );
                                        } catch (e) {
                                            this.setState(
                                                errorMessage(
                                                    "Properties - expecting valid JSON"
                                                )
                                            );
                                            reject();
                                            return;
                                        }
                                        //Trim out new lines and white spaces from beginning and end of labels
                                        newDataUpdate.selectorLabels =
                                            newDataUpdate.selectorLabels.map(
                                                (label) => label.trim()
                                            );
                                        new APIClient()
                                            .createThing(newDataUpdate)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                res = await res.json();
                                                newData.id = res.id;
                                                let things = [
                                                    ...this.state.things,
                                                ];
                                                things.unshift(newData);
                                                this.setState({ things });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully added"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    this.onRowUpdate(newData, oldData),
                                onBulkUpdate: (changes) =>
                                    Promise.all(
                                        Object.values(changes).map(
                                            ({ newData, oldData }) =>
                                                this.onRowUpdate(
                                                    newData,
                                                    oldData
                                                )
                                        )
                                    ),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        new APIClient()
                                            .removeThing(oldData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    resolve();
                                                    return;
                                                }
                                                //Update State
                                                res = await res.json();
                                                let things = [
                                                    ...this.state.things,
                                                ];
                                                things = things.filter(
                                                    (thing) =>
                                                        thing.id !== oldData.id
                                                );
                                                this.setState({ things });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully deleted"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                resolve();
                                            });
                                    }),
                            }}
                            isLoading={this.state.isLoading}
                            options={{
                                search: true,
                                filtering: true,
                                paging: true,
                                addRowPosition: "first",
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <BulkThingCreator
                        apiClient={new APIClient()}
                        onCreateComplete={(successfullyAdded, errorMessage) =>
                            this.onCreateComplete(
                                successfullyAdded,
                                errorMessage
                            )
                        }
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ThingManager);
