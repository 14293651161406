import React, { Component } from "react";
import { Box, Paper, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

class MessageBox extends Component {
    constructor(props) {
        super(props);
        this.showSubmittedMessage = this.showSubmittedMessage.bind(this);
        this.initState();
    }

    initState() {
        let data = {
            openMessageOpen: false,
        };
        this.props.setState(data);
    }

    showSubmittedMessage = () => {
        let data = this.state;
        delete data.message;
        delete data.errorMessage;
        this.props.setState(data);
        this.props.setState({
            message: "Success",
            openMessageOpen: true,
        });
    };
    showErrorMessage = (message) => {
        let data = this.state;
        delete data.message;
        delete data.errorMessage;
        this.props.setState(data);
        this.props.setState({
            errorMessage: message,
            openMessageOpen: true,
        });
    };
    closeMessage = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.setState({ openMessageOpen: false });
    };
    message() {
        if (this.props.message !== undefined) {
            return <Alert severity="success">{this.props.message}</Alert>;
        } else if (this.props.errorMessage !== undefined) {
            return <Alert severity="error">{this.props.errorMessage}</Alert>;
        } else {
            return <div></div>;
        }
    }

    render() {
        return (
            <Paper>
                <Box>
                    <Snackbar
                        open={this.props.open}
                        onClose={this.closeMessage}
                        sx={this.props.raiseSnackbar} // For displaying multiple MessageHelpers
                    >
                        {this.message()}
                    </Snackbar>
                </Box>
            </Paper>
        );
    }
}

export default MessageBox;
