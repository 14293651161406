import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { Box, Grid, List, ListItem, Link, Typography } from "@mui/material";
import RTable from "../components/RTable";

import AutocompleteEditComponent from "./AutocompleteEditComponent";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    box: {
        padding: 10,
    },
    menu: {
        width: 200,
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    select: {
        "max-width": 500,
    },
});

class DataDashboardManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataDashboards: [],
            lookup: [],
            lookup2: {},
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadState();
    }
    async loadState() {
        let result = await new APIClient().getAllDataDashboards(),
            result2 = await new APIClient().getAllFois(),
            dataDashboards = await result.json(),
            fois = await result2.json(),
            lookup2 = {},
            lookup = [];

        fois.forEach(({ id, name }) => {
            lookup2[id] = `${name} - ${id}`;
            lookup.push({ name: name, id: id });
        });
        dataDashboards = dataDashboards.map((dashboard) => {
            dashboard.foi_ids = Array.isArray(dashboard.foi_ids)
                ? dashboard.foi_ids
                : [];
            return dashboard;
        });
        dataDashboards = this.generateDashboardURL(dataDashboards);
        this.setState({ lookup: lookup });
        this.setState({ lookup2: lookup2 });
        this.setState({ dataDashboards: dataDashboards });
        this.setState({ isLoading: false });
    }

    generateDashboardURL(dataDashboards = [...this.state.dataDashboards]) {
        return dataDashboards.map((dataDashboard) => {
            dataDashboard.url = `${window.location.protocol}//${window.location.host}/data/${dataDashboard.id}`;
            return dataDashboard;
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item lg={1} xs={12}>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Grid>
                <Grid item lg={12} xs={12} className={classes.description}>
                    <div className={classes.description}>
                        <Typography className={classes.title} variant="h6">
                            Data Dashboard
                        </Typography>
                        <Typography
                            className={classes.paragraph}
                            variant="body1"
                        >
                            The Data Dashboard lets you create dashboards with
                            all graphs from chosen Features of Interest.
                        </Typography>
                    </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Box>
                        <RTable
                            className={classes.table}
                            title={
                                <Typography variant="h3">
                                    Data Dashboard Manager
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Name *",
                                    field: "name",
                                },
                                {
                                    title: "Description",
                                    field: "description",
                                },
                                {
                                    title: "Feature of Interests",
                                    field: "foi_ids",
                                    render: (rowData) => (
                                        <List className={classes.root}>
                                            {rowData.foi_ids.map((id) => (
                                                <ListItem key={id}>
                                                    {this.state.lookup2[id]}
                                                </ListItem>
                                            ))}
                                        </List>
                                    ),
                                    lookup: this.state.lookup2,
                                    editComponent: (props) => (
                                        <AutocompleteEditComponent
                                            value={props.value}
                                            onChange={props.onChange}
                                            idsToLabels={this.state.lookup2}
                                            multiple={true}
                                        />
                                    ),
                                },
                                {
                                    title: "Dashboard URL",
                                    field: "url",
                                    editable: "never",
                                    render: (rowData) => (
                                        <Link
                                            href={rowData ? rowData.url : ""}
                                            target="_blank"
                                        >
                                            {rowData ? rowData.url : ""}
                                        </Link>
                                    ),
                                },
                            ]}
                            data={this.state.dataDashboards}
                            editable={{
                                isEditable: () => true,
                                onRowAddCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowUpdateCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //add thing
                                        newData.foi_ids =
                                            newData.foi_ids.filter(function (
                                                element
                                            ) {
                                                return element !== undefined;
                                            });
                                        new APIClient()
                                            .createDataDashboard(newData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                res = await res.json();
                                                newData.id = res.id;
                                                let dataDashboards = [
                                                    ...this.state
                                                        .dataDashboards,
                                                ];
                                                dataDashboards.unshift(newData);
                                                dataDashboards =
                                                    this.generateDashboardURL(
                                                        dataDashboards
                                                    );
                                                this.setState({
                                                    dataDashboards,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully added"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowUpdate: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //Update everything
                                        function isdataDashboard(
                                            dataDashboard
                                        ) {
                                            return (
                                                dataDashboard.id === newData.id
                                            );
                                        }
                                        new APIClient()
                                            .updateDataDashboard(newData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                let dataDashboards = [
                                                        ...this.state
                                                            .dataDashboards,
                                                    ],
                                                    i =
                                                        dataDashboards.findIndex(
                                                            isdataDashboard
                                                        );
                                                dataDashboards[i] = newData;
                                                dataDashboards =
                                                    this.generateDashboardURL(
                                                        dataDashboards
                                                    );
                                                this.setState({
                                                    dataDashboards,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully updated"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        new APIClient()
                                            .removeDataDashboard(oldData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    resolve();
                                                    return;
                                                }
                                                //Update State
                                                res = await res.json();
                                                let dataDashboards = [
                                                    ...this.state
                                                        .dataDashboards,
                                                ];
                                                dataDashboards =
                                                    dataDashboards.filter(
                                                        (dataDashboard) =>
                                                            dataDashboard.id !==
                                                            oldData.id
                                                    );
                                                this.setState({
                                                    dataDashboards,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully deleted"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                resolve();
                                            });
                                    }),
                            }}
                            isLoading={this.state.isLoading}
                            options={{
                                search: true,
                                maxColumnSort: 1,
                                filtering: true,
                                paging: true,
                                addRowPosition: "first",
                            }}
                        ></RTable>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(DataDashboardManager);
