import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { Box, Grid, Typography } from "@mui/material";
import RTable from "../components/RTable";

import AutocompleteEditComponent from "./AutocompleteEditComponent";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import config from "../../config";
import DatastreamCopier from "../components/DatastreamCopier";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

class DatastreamManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datastreams: [],
            thingLookup: {},
            sensorLookup: {},
            observedPropertyLookup: {},
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadState();
    }
    async loadState() {
        let resultDatastreams = await new APIClient().getAllDatastreams(),
            resultThings = await new APIClient().getAllThings(),
            resultSensors = await new APIClient().getAllSensors(),
            resultObservedProperties = await new APIClient(
                this.props.authState
            ).getAllObservedProperties(),
            datastreams = await resultDatastreams.json(),
            things = await resultThings.json(),
            sensors = await resultSensors.json(),
            observedProperties = await resultObservedProperties.json(),
            thingLookup = {},
            sensorLookup = {},
            observedPropertyLookup = {};

        things.forEach(({ id, name }) => {
            thingLookup[id] = `${name} - ${id}`;
        });
        sensors.forEach(({ id, name }) => {
            sensorLookup[id] = `${name} - ${id}`;
        });
        observedProperties.forEach(({ id, name }) => {
            observedPropertyLookup[id] = `${name}`;
        });
        datastreams = this.generateDatastreamURL(datastreams);
        this.setState({
            thingLookup: thingLookup,
            sensorLookup: sensorLookup,
            observedPropertyLookup: observedPropertyLookup,
            datastreams: datastreams,
            isLoading: false,
        });
    }

    generateDatastreamURL(datastreams = [...this.state.datastreams]) {
        return datastreams.map((datastream) => {
            datastream.url =
                config.serverPath + "/sensor-things/observe/" + datastream.id;
            return datastream;
        });
    }

    onCopyComplete = (successfullyAdded, error) => {
        const message =
            error == null
                ? successMessage(
                      `Successfully added ${successfullyAdded.length} datastreams`
                  )
                : errorMessage(error);
        this.setState({
            ...message,
            datastreams: [...successfullyAdded, ...this.state.datastreams],
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item lg={1} xs={12}>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Grid>
                <Grid item lg={12} xs={12} className={classes.description}>
                    <div className={classes.description}>
                        <Typography className={classes.title} variant="h6">
                            Datastream
                        </Typography>
                        <Typography
                            className={classes.paragraph}
                            variant="body1"
                        >
                            A Datastream is a combination of a Thing, a Sensor,
                            and an Observed Property, representing the
                            information that will be attached to the actual data
                            coming in from that Thing/Sensor. There can only be
                            one Datastream per unique combination of Thing,
                            Sensor, and Observed Property. Once it is created, a
                            Datastream will provide an optional URL to which a
                            sensor can send data directly (instead of dropping
                            files in the SFTP folder). An example of a
                            Datastream would be the collection of water
                            temperatures observed on the water sensor on a buoy
                            at Cousin&apos;s Island.
                        </Typography>
                    </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Box>
                        <RTable
                            className={classes.table}
                            title={
                                <Typography variant="h3">
                                    Datastream Manager
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "ID",
                                    field: "id",
                                    editable: "never",
                                },
                                {
                                    title: "Name *",
                                    field: "name",
                                },
                                {
                                    title: "Description *",
                                    field: "description",
                                },
                                {
                                    title: "unit of measurement *",
                                    field: "unitOfMeasurement",
                                },
                                {
                                    title: "Thing - Thing ID *",
                                    field: "thingId",
                                    lookup: this.state.thingLookup,
                                    editComponent: (props) => (
                                        <AutocompleteEditComponent
                                            value={props.value}
                                            onChange={props.onChange}
                                            idsToLabels={this.state.thingLookup}
                                        />
                                    ),
                                },
                                {
                                    title: "Sensor - Sensor ID *",
                                    field: "sensorId",
                                    lookup: this.state.sensorLookup,
                                    editComponent: (props) => (
                                        <AutocompleteEditComponent
                                            value={props.value}
                                            onChange={props.onChange}
                                            idsToLabels={
                                                this.state.sensorLookup
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Observed Property ID *",
                                    field: "observedPropertyId",
                                    lookup: this.state.observedPropertyLookup,
                                    editComponent: (props) => (
                                        <AutocompleteEditComponent
                                            value={props.value}
                                            onChange={props.onChange}
                                            idsToLabels={
                                                this.state
                                                    .observedPropertyLookup
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Data URL",
                                    field: "url",
                                    editable: "never",
                                },
                            ]}
                            data={this.state.datastreams}
                            editable={{
                                isEditable: () => true,
                                onRowAddCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowUpdateCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //add datastream
                                        new APIClient()
                                            .createDatastream(newData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                res = await res.json();
                                                newData.id = res.id;
                                                let datastreams = [
                                                    ...this.state.datastreams,
                                                ];
                                                datastreams.unshift(newData);
                                                datastreams =
                                                    this.generateDatastreamURL(
                                                        datastreams
                                                    );
                                                this.setState({
                                                    datastreams,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully added"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowUpdate: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //Update everything
                                        function isDatastream(datastream) {
                                            return datastream.id === newData.id;
                                        }
                                        new APIClient()
                                            .updateDatastream(newData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                let datastreams = [
                                                        ...this.state
                                                            .datastreams,
                                                    ],
                                                    i =
                                                        datastreams.findIndex(
                                                            isDatastream
                                                        );
                                                datastreams[i] = newData;
                                                datastreams =
                                                    this.generateDatastreamURL(
                                                        datastreams
                                                    );
                                                this.setState({
                                                    datastreams,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully updated"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        new APIClient()
                                            .removeDatastream(oldData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    resolve();
                                                    return;
                                                }
                                                //Update State
                                                let datastreams = [
                                                    ...this.state.datastreams,
                                                ];
                                                datastreams =
                                                    datastreams.filter(
                                                        (datastream) =>
                                                            datastream.id !==
                                                            oldData.id
                                                    );
                                                this.setState({
                                                    datastreams,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully deleted"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                resolve();
                                            });
                                    }),
                            }}
                            isLoading={this.state.isLoading}
                            options={{
                                search: true,
                                maxColumnSort: 1,
                                filtering: true,
                                paging: true,
                                addRowPosition: "first",
                            }}
                        ></RTable>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <DatastreamCopier
                        things={this.state.thingLookup}
                        apiClient={new APIClient()}
                        onCopyComplete={(successfullyAdded, errorMessage) =>
                            this.onCopyComplete(successfullyAdded, errorMessage)
                        }
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(DatastreamManager);
