import React, { Component } from "react";
import {
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    TextField,
} from "@mui/material";
import { ExpandMore, Sync, CloudDownload } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import JSZip from "jszip";
import FileSaver from "file-saver";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    tabel_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
    canvas: {
        // height: "auto",
        // width: "100%",
    },
});

/* Picture parameters default values. */
const DEFAULT_QUALITY = 80;
const DEFAULT_WIDTH = 416;
const DEFAULT_HEIGHT = 312;
const DEFAULT_FLASH = 100;
const DEFAULT_ADJUST_TIME = 10000;
/* Picture parameters limits. */
const MIN_QUALITY = 2;
const MAX_QUALITY = 100;
const MIN_WIDTH = 8;
const MAX_WIDTH = 1600;
const MIN_HEIGHT = 8;
const MAX_HEIGHT = 1200;
const MIN_FLASH = 0;
const MAX_FLASH = 100;
const MIN_ADJUST_TIME = 0;
const MAX_ADJUST_TIME = 60000;
/* Picture dimensions must be a multiple of 8 or divisible by 8. */
const DIMENSION_DIVISIBLE = 8;
/* Picture parameters are arranged in an array before sending. */
/* Picture parameters array size. */
const PICTURE_PARAMETERS_ARRAY_SIZE = 16;
/* Quality offset in the parameters array. */
const QUALITY_PARAMETERS_ARRAY_OFFSET = 0;
/* Width offset in the parameters array. */
const WIDTH_PARAMETERS_ARRAY_OFFSET = 2;
/* Height offset in the parameters array. */
const HEIGHT_PARAMETERS_ARRAY_OFFSET = 4;
/* Flash offset in the parameters array. */
const FLASH_PARAMETERS_ARRAY_OFFSET = 6;
/* Adjustment time offset in the parameters array. */
const ADJUST_TIME_PARAMETERS_ARRAY_OFFSET = 8;
/* Result picture size index. */
const RESULT_PICTURE_SIZE_INDEX = 2;

class BluetoothCameraCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            requestOnConnect: props.requestOnConnect,
            typeHandler: props.typeHandler,
            csvHeader: props.csvHeader,
            values: [],
            loading: false,
            disabled: false,
            pictureQuality: DEFAULT_QUALITY,
            pictureWidth: DEFAULT_WIDTH,
            pictureHeight: DEFAULT_HEIGHT,
            pictureFlash: DEFAULT_FLASH,
            pictureAdjustTime: DEFAULT_ADJUST_TIME,
            errors: {},
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.readValueCallback = this.readValueCallback.bind(this);
        this.downloadCallback = this.downloadCallback.bind(this);
        this.widthUpdate = this.widthUpdate.bind(this);
        this.heightUpdate = this.heightUpdate.bind(this);
        this.qualityUpdate = this.qualityUpdate.bind(this);
        this.flashUpdate = this.flashUpdate.bind(this);
        this.adjustTimeUpdate = this.adjustTimeUpdate.bind(this);
        this.enable_notify_ble_characteristic();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.values = [];
    }
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    handleNotifications(event) {
        let value = event.target.value;
        //ignore 0 byte messages
        if (value.byteLength) {
            this.values.push(value);
            let value_return = this.state.typeHandler(value, this.values);
            if (value_return.csv[RESULT_PICTURE_SIZE_INDEX] > 0) {
                this.setState({
                    values: [...this.state.values, value_return],
                    loading: false,
                    disabled: false,
                });
            }
        }
    }
    async downloadCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        const zip = new JSZip();
        this.state.values.forEach((blob) => {
            const byteCharacters = atob(blob.value);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const imageBlob = new Blob([byteArray], { type: "image/jpeg" });
            let date = blob.csv[0].replaceAll(" ", "_");
            zip.file(
                `${this.state.name.toLowerCase()}_${date}.jpg`.replaceAll(
                    " ",
                    "_"
                ),
                imageBlob
            );
        });
        await zip.generateAsync({ type: "blob" }).then((content) => {
            FileSaver.saveAs(
                content,
                `${this.state.name.toLowerCase()}_pictures.zip`.replaceAll(
                    " ",
                    "_"
                )
            );
        });
    }

    async readValueCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        /* Get the picture resolution and quality parameters. */
        let quality = this.state.pictureQuality;
        let width = this.state.pictureWidth;
        let height = this.state.pictureHeight;
        let flash = this.state.pictureFlash;
        let adjustTime = this.state.pictureAdjustTime;
        /* Check the values are between parameters. */
        let validQuality = true;
        let validWidth = true;
        let validHeight = true;
        let validFlash = true;
        let validAdjustTime = true;
        if (quality < MIN_QUALITY || quality > MAX_QUALITY) {
            validQuality = false;
        }
        if (
            width < MIN_WIDTH ||
            width > MAX_WIDTH ||
            width % DIMENSION_DIVISIBLE !== 0
        ) {
            validWidth = false;
        }
        if (
            height < MIN_HEIGHT ||
            height > MAX_HEIGHT ||
            height % DIMENSION_DIVISIBLE !== 0
        ) {
            validHeight = false;
        }
        if (flash < MIN_FLASH || flash > MAX_FLASH) {
            validFlash = false;
        }
        if (adjustTime < MIN_ADJUST_TIME || adjustTime > MAX_ADJUST_TIME) {
            validAdjustTime = false;
        }
        /* If any values is invalid set the error and return. */
        if (
            !validQuality ||
            !validWidth ||
            !validHeight ||
            !validFlash ||
            !validAdjustTime
        ) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    pictureQuality: validQuality
                        ? ""
                        : "Please enter valid quality in the range [2-100]%",
                    pictureWidth: validWidth
                        ? ""
                        : "Please enter valid width in the range [8-1600]px and divisible by 8",
                    pictureHeight: validHeight
                        ? ""
                        : "Please enter valid height in the range [8-1200]px and divisible by 8",
                    pictureFlash: validFlash
                        ? ""
                        : "Please enter valid flash brightness in the range [0-100]%",
                    pictureAdjustTime: validAdjustTime
                        ? ""
                        : "Please enter valid adjustment time in the range [0-60000]ms",
                },
            });
            return;
        }
        /* If all is good lets set the control to loading and clear any error messages. */
        this.setState({
            loading: true,
            disabled: true,
            errors: {
                ...this.state.errors,
                pictureQuality: "",
                pictureWidth: "",
                pictureHeight: "",
                pictureFlash: "",
                pictureAdjustTime: "",
            },
        });
        /* Clear old values. */
        this.values = [];
        /* Build a parameters array. */
        let buffer = new ArrayBuffer(PICTURE_PARAMETERS_ARRAY_SIZE);
        let dataview = new DataView(buffer, 0);
        dataview.setUint16(QUALITY_PARAMETERS_ARRAY_OFFSET, quality, true);
        dataview.setUint16(WIDTH_PARAMETERS_ARRAY_OFFSET, width, true);
        dataview.setUint16(HEIGHT_PARAMETERS_ARRAY_OFFSET, height, true);
        dataview.setUint16(FLASH_PARAMETERS_ARRAY_OFFSET, flash, true);
        dataview.setUint16(
            ADJUST_TIME_PARAMETERS_ARRAY_OFFSET,
            adjustTime,
            true
        );
        /* Finally, request the picture with these values. */
        await this.state.characteristic.writeValueWithResponse(buffer);
        console.log(`read ${this.state.name}'s readings`);
    }
    async enable_notify_ble_characteristic() {
        try {
            await this.state.characteristic.startNotifications();
            this.state.characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotifications
            );
            if (this.state.requestOnConnect) {
                await this.state.characteristic.readValue();
            }
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }

    qualityUpdate(event) {
        this.setState({
            pictureQuality: parseInt(event.target.value),
        });
    }

    widthUpdate(event) {
        this.setState({
            pictureWidth: parseInt(event.target.value),
        });
    }

    heightUpdate(event) {
        this.setState({
            pictureHeight: parseInt(event.target.value),
        });
    }

    flashUpdate(event) {
        this.setState({
            pictureFlash: parseInt(event.target.value),
        });
    }

    adjustTimeUpdate(event) {
        this.setState({
            pictureAdjustTime: parseInt(event.target.value),
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Accordion
                        className={classes.accordion}
                        expanded={this.state.expanded}
                        onChange={this.accordianToggle}
                    >
                        <AccordionSummary
                            className={classes.space_between}
                            expandIcon={<ExpandMore />}
                            id={this.state.uuid}
                        >
                            <div className={classes.flex_div100}>
                                <Typography
                                    sx={{ width: "30%", flexShrink: 0 }}
                                >
                                    {this.state.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        width: "50%",
                                        color: "text.secondary",
                                    }}
                                >
                                    {this.state.values?.length > 0 ? (
                                        <img
                                            src={`data:image/jpg;base64,${
                                                this.state.values?.[
                                                    this.state.values?.length -
                                                        1
                                                ]?.value
                                            }`}
                                            alt=""
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Typography>
                                <div className={classes.flex_div}>
                                    <TextField
                                        required
                                        id="picture-quality"
                                        value={this.state.pictureQuality}
                                        label="Quality (%)"
                                        variant="filled"
                                        type="number"
                                        onChange={this.qualityUpdate}
                                        helperText={
                                            this.state.errors.pictureQuality &&
                                            this.state.errors.pictureQuality
                                        }
                                        error={
                                            !!this.state.errors.pictureQuality
                                        }
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    <TextField
                                        required
                                        id="picture-width"
                                        value={this.state.pictureWidth}
                                        label="Width (px)"
                                        variant="filled"
                                        type="number"
                                        onChange={this.widthUpdate}
                                        helperText={
                                            this.state.errors.pictureWidth &&
                                            this.state.errors.pictureWidth
                                        }
                                        error={!!this.state.errors.pictureWidth}
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    <TextField
                                        required
                                        id="picture-height"
                                        value={this.state.pictureHeight}
                                        label="Height (px)"
                                        variant="filled"
                                        type="number"
                                        onChange={this.heightUpdate}
                                        helperText={
                                            this.state.errors.pictureHeight &&
                                            this.state.errors.pictureHeight
                                        }
                                        error={
                                            !!this.state.errors.pictureHeight
                                        }
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    <TextField
                                        required
                                        id="picture-flash"
                                        value={this.state.pictureFlash}
                                        label="Flash (%)"
                                        variant="filled"
                                        type="number"
                                        onChange={this.flashUpdate}
                                        helperText={
                                            this.state.errors.pictureFlash &&
                                            this.state.errors.pictureFlash
                                        }
                                        error={!!this.state.errors.pictureFlash}
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    <TextField
                                        required
                                        id="picture-adjust-time"
                                        value={this.state.pictureAdjustTime}
                                        label="Adj.Time (ms)"
                                        variant="filled"
                                        type="number"
                                        onChange={this.adjustTimeUpdate}
                                        helperText={
                                            this.state.errors
                                                .pictureAdjustTime &&
                                            this.state.errors.pictureAdjustTime
                                        }
                                        error={
                                            !!this.state.errors
                                                .pictureAdjustTime
                                        }
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    {this.state.loading && (
                                        <CircularProgress
                                            color="secondary"
                                            size={30}
                                        />
                                    )}
                                    <Chip
                                        icon={<Sync />}
                                        label="Refresh"
                                        onClick={this.readValueCallback}
                                        disabled={
                                            !this.props.connected ||
                                            this.state.disabled
                                        }
                                        color="success"
                                    />
                                    <Chip
                                        icon={<CloudDownload />}
                                        label="Download"
                                        onClick={this.downloadCallback}
                                        color="success"
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow className={classes.tabel_row}>
                                            {this.state.csvHeader.map((v) => (
                                                <TableCell key={this.getKey()}>
                                                    {v}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.values.map((v, i) => (
                                            <TableRow
                                                className={
                                                    i ===
                                                    this.state.values.length - 1
                                                        ? ""
                                                        : classes.tabel_row
                                                }
                                                key={this.getKey()}
                                            >
                                                {v.csv.map((cell, index) => (
                                                    <TableCell
                                                        key={this.getKey()}
                                                    >
                                                        {index === 1 ? (
                                                            <img
                                                                src={`data:image/jpg;base64,${cell}`}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            cell
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(BluetoothCameraCharacteristic);
