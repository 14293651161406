/**
 * A component that displays a buoy's logs by job.
 */

import React, { Component } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import APIClient from "../../models/APIClient";
import InfiniteScrollList from "../../components/InfiniteScrollList";

const JOBS = {
    hbs: "boot shutdown",
    hc: "collect",
    hgp: "gps ping",
    hnh: "network handler",
    hs: "serve",
    hsc: "sensor collect",
    ht: "test",
    hu: "update",
    hwp: "wave processing",
    hrb: "resource broker",
};

const styles = {
    monospace: {
        whiteSpace: "pre-line",
        fontFamily: "Monospace",
    },
};

class BuoyLogExplorer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            job: "hbs",
        };
        this.fetchLogs = this.fetchLogs.bind(this);
    }

    async fetchLogs(currentLogs) {
        const apiClient = new APIClient();
        const logs = await apiClient.fetchLogsByJob(
            this.props.buoyId,
            this.state.job,
            currentLogs == null ? 0 : currentLogs.page + 1
        );
        return logs;
    }

    renderLog = (log) => (
        <Typography style={styles.monospace}>{log}</Typography>
    );

    renderNoLogs = () => (
        <Typography style={styles.monospace}>No logs available</Typography>
    );

    render() {
        return (
            <Paper style={{ padding: 24 }}>
                <Typography variant="h6" style={{ marginBottom: 24 }}>
                    Buoy Logs by Job
                </Typography>
                <Box>
                    {Object.keys(JOBS).map((job) => (
                        <Button
                            variant={
                                this.state.job === job
                                    ? "contained"
                                    : "outlined"
                            }
                            /* TODO These are default MUI variants, the current goal is
                                to be using RT defined primary/secondary/tertiary
                                but while "contained" == "secondary" 
                                there is no current equivelant to "outlined" */
                            onClick={() => {
                                this.setState({ job });
                            }}
                            key={job}
                        >
                            {JOBS[job]}
                        </Button>
                    ))}
                </Box>
                <InfiniteScrollList
                    maxHeight={400}
                    fetchData={this.fetchLogs}
                    renderData={this.renderLog}
                    renderEmpty={this.renderNoLogs}
                    key={this.state.job}
                />
            </Paper>
        );
    }
}

export default BuoyLogExplorer;
