import { Buffer } from "buffer";
import { FirmwareBuoyProtocols } from "@running-tide/firmware-buoy-protocols";
const ServerBound = FirmwareBuoyProtocols.FirmwareServerBound;

const BYTES_FLOAT = 4;
const BYTES_DOUBLE = 8;

export const FIRMWARE_BUOY_TEST_PASS = 1;
export const FIRMWARE_BUOY_TEST_FAIL = 0;
export const FIRMWARE_BUOY_TEST_PASS_WITH_WARNING = 2;

export class FirmwareServerBound {
    constructor(binary = null, json = null) {
        this.binary = binary?.buffer || binary;
        this.json = json;

        this.logs = [];
        this.sensors = [];
        this.time = undefined;
        this.buoy_tests = [];
        this.buoy_test_suite_tests = [];

        if (this.binary) {
            this.computeJson();
        }
    }

    /** Deserialize binary to JSON object, then parse all binary data fields to human-readable values
     *
     */
    computeJson() {
        const firmwareServerBound = ServerBound.deserializeBinary(this.binary);
        this.json = firmwareServerBound.toObject();

        if (this.json.testJobDataUpload) {
            this.parseTestJobDataUpload();
            this.getTestJobStatus();
        } else if (this.json.testSuiteResults) {
            this.parseTestSuiteResults();
        } else {
            console.log("Unknown or unsupported message type.");
        }
    }

    //Based on protobuff enum test suite type get the String of the test type
    getTestTypeStringFromID(testTypeId) {
        return Object.keys(FirmwareBuoyProtocols.TestType).find(
            (key) => FirmwareBuoyProtocols.TestType[key] === testTypeId
        );
    }

    //Extract the key from enum
    getProtobuffLogEnumString(proto_enum, proto_enums) {
        let proto_string = Object.keys(proto_enums).find(
            (key) => proto_enums[key] === proto_enum
        );
        if (proto_string == null) {
            console.log(`WARNING: unexpected enum: ${proto_enum}`);
            return "";
        }
        return `${proto_string.toLowerCase().replace(/_/g, " ")}`;
    }

    /** Extract the test from the test suite run as Type/(pass/fail)/message
     *
     */
    parseTestSuiteResults() {
        if (!this.json.testSuiteResults) {
            return;
        }
        //Set time of test run
        this.time = this.parseTimestamp(this.json.testSuiteResults.time);
        //extract tests
        this.json.testSuiteResults.testResultsList.forEach((test_result) => {
            let test = {
                test: this.getTestTypeStringFromID(test_result.testType),
                pass: test_result.testResult,
                message: this.getProtobuffLogEnumString(
                    test_result.message.log,
                    FirmwareBuoyProtocols.FirmwareServerBound.LogMessage.LogEnum
                ),
            };
            this.buoy_test_suite_tests.push(test);
        });
    }

    /** Get test job pass/fail results from parsed log messages
     *
     */
    getTestJobStatus() {
        // If the message did not come from a test job, exit
        if (!this.json.testJobDataUpload) {
            return;
        }
        // If there are no logs, message might not have been parsed yet
        if (!this.logs.length) {
            // Try to parse the message as a test job result
            if (!this.parseTestJobDataUpload()) return; // If this fails, exit
        }
        // Clear buoy test array
        let test_results = [];
        let sensor_data_object = undefined;
        // Use log messages to determine which tests were run and each test's pass/fail status
        this.logs.forEach((log_msg) => {
            switch (log_msg.log_enum) {
                // Battery Voltage
                case ServerBound.LogMessage.LogEnum.BATTERY_VOLTAGE_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "Battery Voltage",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message:
                            (log_msg.data / 1000).toFixed(3).toString() + " V",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .READ_BATTERY_VOLTAGE_TEST_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Battery Voltage",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // Battery Current
                case ServerBound.LogMessage.LogEnum.BATTERY_CURRENT_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "Battery Current",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message:
                            (log_msg.data / 1000).toFixed(3).toString() + " A",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .READ_BATTERY_CURRENT_TEST_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Battery Current",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // External Temperature
                case ServerBound.LogMessage.LogEnum.OCEAN_TEMPERATURE_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "External Temperature",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message:
                            log_msg.data.toFixed(2).toString() +
                            " degrees Celsius",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .READ_OCEAN_TEMPERATURE_TEST_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "External Temperature",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // Enclosure Temperature
                case ServerBound.LogMessage.LogEnum.BUOY_TEMPERATURE_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "Enclosure Temperature",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message:
                            log_msg.data.toFixed(2).toString() +
                            " degrees Celsius",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .READ_BUOY_TEMPERATURE_SENSOR_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Enclosure Temperature",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // Enclosure Humidity
                case ServerBound.LogMessage.LogEnum.BUOY_HUMIDITY_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "Enclosure Humidity",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: log_msg.data.toFixed(2).toString() + " % RH",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .READ_BUOY_HUMIDITY_SENSOR_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Enclosure Humidity",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // Pressure
                case ServerBound.LogMessage.LogEnum.BUOY_PRESSURE_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "Enclosure Pressure",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: log_msg.data.toFixed(2).toString() + " mBar",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .READ_BUOY_PRESSURE_SENSOR_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Enclosure Pressure",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // Accelerometer
                case ServerBound.LogMessage.LogEnum
                    .READ_BUOY_ACCELEROMETER_SENSOR_PASS:
                    test_results.push({
                        category: "Sensor",
                        test: "Accelerometer",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: "",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_POWER_ON_BUOY_ACCELEROMETER:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_POWER_OFF_BUOY_ACCELEROMETER:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_ACCELEROMETER_OUTPUT_ALL_ZEROS:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_ACCELEROMETER_SENSOR:
                    test_results.push({
                        category: "Sensor",
                        test: "Accelerometer",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: log_msg.log_msg,
                    });
                    break;
                // Gyroscope
                case ServerBound.LogMessage.LogEnum
                    .READ_BUOY_GYROSCOPE_SENSOR_PASS:
                    test_results.push({
                        category: "Sensor",
                        test: "Gyroscope",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: "",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_POWER_ON_BUOY_GYROSCOPE:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_POWER_OFF_BUOY_GYROSCOPE:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_GYROSCOPE_OUTPUT_ALL_ZEROS:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_GYROSCOPE_SENSOR:
                    test_results.push({
                        category: "Sensor",
                        test: "Gyroscope",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: log_msg.log_msg,
                    });
                    break;
                // Magnetometer
                case ServerBound.LogMessage.LogEnum
                    .READ_BUOY_MAGNETOMETER_SENSOR_PASS:
                    test_results.push({
                        category: "Sensor",
                        test: "Magnetometer",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: "",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_POWER_ON_BUOY_MAGNETOMETER:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_POWER_OFF_BUOY_MAGNETOMETER:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_MAGNETOMETER_OUTPUT_ALL_ZEROS:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_MAGNETOMETER_SENSOR:
                    test_results.push({
                        category: "Sensor",
                        test: "Magnetometer",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: log_msg.log_msg,
                    });
                    break;
                // GPS
                case ServerBound.LogMessage.LogEnum.GPS_TEST_PASS:
                case ServerBound.LogMessage.LogEnum.GPS_RECEIVED_LOCATION_FIX:
                case ServerBound.LogMessage.LogEnum.GPS_RECEIVED_UTC_TIME:
                    test_results.push({
                        category: "Sensor",
                        test: "GPS",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: "",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .GPS_TIMED_OUT_BEFORE_UTC_DATETIME:
                case ServerBound.LogMessage.LogEnum
                    .GPS_TIMED_OUT_BEFORE_LOCATION_FIX:
                    test_results.push({
                        category: "Sensor",
                        test: "GPS",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS_WITH_WARNING, // This does not count as an overall fail
                        message: log_msg.log_msg,
                    });
                    break;
                case ServerBound.LogMessage.LogEnum.GPS_TEST_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "GPS",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .GPS_LATITUDE_LONGITUDE_READS:
                    sensor_data_object = this.parseSensorData(log_msg.data)[1];
                    test_results.push({
                        category: "Sensor",
                        test: "GPS",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message:
                            "Latitude: " +
                            sensor_data_object.latitude.degrees.toString() +
                            " deg " +
                            sensor_data_object.latitude.minutes.toString() +
                            " min " +
                            sensor_data_object.latitude.seconds.toString() +
                            "-" +
                            sensor_data_object.latitude.centiseconds.toString() +
                            "/100 sec, Longitude: " +
                            sensor_data_object.longitude.degrees.toString() +
                            " deg " +
                            sensor_data_object.longitude.minutes.toString() +
                            " min " +
                            sensor_data_object.longitude.seconds.toString() +
                            "-" +
                            sensor_data_object.longitude.centiseconds.toString() +
                            "/100 sec",
                    });
                    break;
                // Modem
                case ServerBound.LogMessage.LogEnum.SATELLITE_SIGNAL_STRENGTH:
                    test_results.push({
                        category: "Sensor",
                        test: "Satellite Modem",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: "Signal Strength: " + log_msg.data.toString(),
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_TURN_ON_SATELLITE_MODEM:
                case ServerBound.LogMessage.LogEnum
                    .SATELLITE_MODEM_COMMUNICATION_TIMEDOUT:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_COMMUNICATE_WITH_SATELLITE_MODEM:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_GET_SATELLITE_MODEM_SIGNAL_STRENGTH:
                    test_results.push({
                        category: "Sensor",
                        test: "Satellite Modem",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: log_msg.log_msg,
                    });
                    break;
                // Top Temperature
                case ServerBound.LogMessage.LogEnum
                    .TOP_TEMPERATURE_SENSOR_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "Top Enclosure Temperature",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message:
                            log_msg.data.toFixed(2).toString() +
                            " degrees Celsius",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .TOP_TEMPERATURE_SENSOR_TEST_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Top Enclosure Temperature",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // Top Humidity
                case ServerBound.LogMessage.LogEnum.TOP_HUMIDITY_SENSOR_READS:
                    test_results.push({
                        category: "Sensor",
                        test: "Top Enclosure Humidity",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: log_msg.data.toFixed(2).toString() + " % RH",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .TOP_HUMIDITY_SENSOR_TEST_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Top Enclosure Humidity",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                // Solar Panels
                case ServerBound.LogMessage.LogEnum
                    .SOLAR_PANEL_POWER_SENSOR_READS:
                    sensor_data_object = this.parseSensorData(log_msg.data)[1];
                    test_results.push({
                        category: "Sensor",
                        test: "Solar Panels",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message:
                            "Solar Panel " +
                            sensor_data_object.number.toString() +
                            ": " +
                            sensor_data_object.voltage.toFixed(3).toString() +
                            " V " +
                            sensor_data_object.current.toFixed(3).toString() +
                            " A",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .SOLAR_PANEL_VOLTAGE_SENSOR_TEST_FAIL:
                    test_results.push({
                        category: "Sensor",
                        test: "Solar Panels",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: "",
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_SOLAR_PANEL_VOLTAGE_SENSOR:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_READ_SOLAR_PANEL_CURRENT_SENSOR:
                    test_results.push({
                        category: "Sensor",
                        test: "Solar Panels",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: log_msg.log_msg,
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .SOLAR_PANEL_VOLTAGE_SENSOR_NOT_PRESENT_OR_CONFIGURED:
                case ServerBound.LogMessage.LogEnum
                    .SOLAR_PANEL_CURRENT_SENSOR_NOT_PRESENT_OR_CONFIGURED:
                    test_results.push({
                        category: "Sensor",
                        test: "Solar Panels",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS_WITH_WARNING,
                        message: log_msg.log_msg,
                    });
                    break;
                // Config Update
                case ServerBound.LogMessage.LogEnum
                    .CONFIG_UPDATE_FOUND_IN_FLASH:
                case ServerBound.LogMessage.LogEnum.CONFIG_UPDATE_MESSAGE_VALID:
                case ServerBound.LogMessage.LogEnum
                    .CONFIG_UPDATE_MESSAGE_DECODE_SUCCESS:
                    test_results.push({
                        category: "Config",
                        test: "Config",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_PASS,
                        message: log_msg.log_msg,
                    });
                    break;
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_FIND_CONFIG_UPDATE_IN_FLASH:
                case ServerBound.LogMessage.LogEnum
                    .CONFIG_UPDATE_MESSAGE_INVALID:
                case ServerBound.LogMessage.LogEnum
                    .FAILED_TO_DECODE_CONFIG_UPDATE_MESSAGE:
                    test_results.push({
                        category: "Config",
                        test: "Config",
                        log_enum: log_msg.log_enum,
                        pass: FIRMWARE_BUOY_TEST_FAIL,
                        message: log_msg.log_msg,
                    });
                    break;
                default:
                    break;
            }
        });
        // Which tests have been tracked?
        let tests_tracked = [];
        // Consolidate entries for multiple tests
        test_results.forEach((test_result) => {
            // If this test has not yet been tracked, track it and all log rows for the same test
            if (!tests_tracked.includes(test_result.test)) {
                let related_results = test_results.filter(
                    (e) => e.test === test_result.test
                );
                if (!related_results[0]) return;
                let final_result = {
                    category: related_results[0].category,
                    test: related_results[0].test,
                    log_enums: [],
                    pass: related_results[0].pass,
                    message: "",
                };
                related_results.forEach((related_result) => {
                    // If message related to test is not pass and if no fail messages have been detected, overwrite test status
                    if (
                        related_result.pass !== FIRMWARE_BUOY_TEST_PASS &&
                        final_result.pass !== FIRMWARE_BUOY_TEST_FAIL
                    ) {
                        final_result.pass = related_result.pass;
                    }
                    // Append messages
                    if (related_result.message && !final_result.message) {
                        final_result.message = related_result.message;
                    } else if (related_result.message) {
                        final_result.message =
                            final_result.message +
                            ", " +
                            related_result.message;
                    }
                    final_result.log_enums.push(related_result.log_enum);
                });
                this.buoy_tests.push(final_result);
                // We have now tracked this test
                tests_tracked.push(test_result.test);
            }
        }, this);
    }

    /** Attempt to parse message contents as a test job data upload type
     *
     * @returns True if the Protobuf message is a test job data upload, false otherwise
     */
    parseTestJobDataUpload() {
        if (this.json.testJobDataUpload) {
            if (this.json.testJobDataUpload.time) {
                this.time = this.parseTimestamp(
                    this.json.testJobDataUpload.time
                );
            }
            this.json.testJobDataUpload.allLogsList.forEach((log_message) => {
                let parsed_log = this.parseLogMessage(log_message);
                let next_log = {
                    log_enum: log_message.log,
                    log_msg: parsed_log[0], // Log message string
                    data: parsed_log[2] ? parsed_log[2] : parsed_log[1], // Log message sensor data or log message binary data
                };
                this.logs.push(next_log);
            });
            return true;
        } else {
            return false;
        }
    }

    /** Parse Protobuf timestamp fields from binary integers to numbers
     *
     * @param {FirmwareBuoyProtocols.Timestamp} timestamp Timestamp Protobuf JSON object
     * @returns Timestamp object with field converted from bytes to integers
     */
    parseTimestamp(timestamp) {
        return {
            year: Buffer.from(timestamp.year, "base64").readUInt16LE(0),
            month: Buffer.from(timestamp.month, "base64").readUInt8(0),
            day: Buffer.from(timestamp.day, "base64").readUInt8(0),
            hour: Buffer.from(timestamp.hour, "base64").readUInt8(0),
            minute: Buffer.from(timestamp.minute, "base64").readUInt8(0),
            second: Buffer.from(timestamp.second, "base64").readUInt8(0),
            millisecond: Buffer.from(
                timestamp.millisecond,
                "base64"
            ).readUInt16LE(0),
        };
    }

    /** Parse Protobuf sensor data fields from binary to human-readable numeric values
     *
     * @param {FirmwareBuoyProtocols.SensorData} sensor_datum SensorData Protobuf JSON object
     * @returns Array containing sensor enum and parsed binary data
     */
    parseSensorData(sensor_datum) {
        // Search for matching log enum for each log message
        let sensor_enum = 0;
        for (const [key, val] of Object.entries(
            FirmwareBuoyProtocols.SensorEnum
        )) {
            if (FirmwareBuoyProtocols.SensorEnum[key] === sensor_datum.type) {
                sensor_enum = val;
                break;
            }
        }
        // Parse binary data/value field
        let data = undefined;
        let data_buffer = undefined;
        switch (sensor_enum) {
            case FirmwareBuoyProtocols.SensorEnum.BATTERY_LEVEL:
                data =
                    Buffer.from(sensor_datum.charge, "base64").readInt32LE(0) /
                    100;
                break;
            case FirmwareBuoyProtocols.SensorEnum.BATTERY_VOLTAGE:
                data =
                    Buffer.from(sensor_datum.voltage, "base64").readInt32LE(0) /
                    1000;
                break;
            case FirmwareBuoyProtocols.SensorEnum.BATTERY_CURRENT:
                data =
                    Buffer.from(sensor_datum.current, "base64").readInt32LE(0) /
                    1000;
                break;
            case FirmwareBuoyProtocols.SensorEnum.BUOY_TEMPERATURE:
            case FirmwareBuoyProtocols.SensorEnum.OCEAN_TEMPERATURE:
            case FirmwareBuoyProtocols.SensorEnum.TOP_TEMPERATURE:
                data_buffer = Buffer.from(sensor_datum.temperature, "base64");
                if (data_buffer.buffer.byteLength === BYTES_DOUBLE) {
                    data = data_buffer.readDoubleLE(0);
                } else if (data_buffer.buffer.byteLength === BYTES_FLOAT) {
                    data = data_buffer.readFloatLE(0);
                }
                break;
            case FirmwareBuoyProtocols.SensorEnum.BUOY_HUMIDITY:
            case FirmwareBuoyProtocols.SensorEnum.TOP_HUMIDITY:
                data_buffer = Buffer.from(sensor_datum.humidity, "base64");
                if (data_buffer.buffer.byteLength === BYTES_DOUBLE) {
                    data = data_buffer.readDoubleLE(0);
                } else if (data_buffer.buffer.byteLength === BYTES_FLOAT) {
                    data = data_buffer.readFloatLE(0);
                }
                break;
            case FirmwareBuoyProtocols.SensorEnum.BUOY_BAROMETER:
                data_buffer = Buffer.from(sensor_datum.pressure, "base64");
                if (data_buffer.buffer.byteLength === BYTES_DOUBLE) {
                    data = data_buffer.readDoubleLE(0);
                } else if (data_buffer.buffer.byteLength === BYTES_FLOAT) {
                    data = data_buffer.readFloatLE(0);
                }
                break;
            case FirmwareBuoyProtocols.SensorEnum.GPS:
                data = {
                    latitude: {
                        degrees: Buffer.from(
                            sensor_datum.location.latitude.degrees,
                            "base64"
                        ).readInt16LE(0),
                        minutes: Buffer.from(
                            sensor_datum.location.latitude.minutes,
                            "base64"
                        ).readUInt8(0),
                        seconds: Buffer.from(
                            sensor_datum.location.latitude.seconds,
                            "base64"
                        ).readUInt8(0),
                        centiseconds: Buffer.from(
                            sensor_datum.location.latitude.centiseconds,
                            "base64"
                        ).readUInt8(0),
                    },
                    longitude: {
                        degrees: Buffer.from(
                            sensor_datum.location.longitude.degrees,
                            "base64"
                        ).readInt16LE(0),
                        minutes: Buffer.from(
                            sensor_datum.location.longitude.minutes,
                            "base64"
                        ).readUInt8(0),
                        seconds: Buffer.from(
                            sensor_datum.location.longitude.seconds,
                            "base64"
                        ).readUInt8(0),
                        centiseconds: Buffer.from(
                            sensor_datum.location.longitude.centiseconds,
                            "base64"
                        ).readUInt8(0),
                    },
                };
                break;
            case FirmwareBuoyProtocols.SensorEnum.SOLAR_PANEL:
                data = {
                    voltage:
                        Buffer.from(
                            sensor_datum.solarPanel.voltage,
                            "base64"
                        ).readInt32LE(0) / 1000,
                    current:
                        Buffer.from(
                            sensor_datum.solarPanel.current,
                            "base64"
                        ).readInt32LE(0) / 1000,
                    number: sensor_datum.solarPanel.number,
                };
                break;
            default:
                break;
        }
        return [sensor_enum, data];
    }

    /**
     *
     * @param {FirmwareBuoyProtocols.LogMessage} log_message Log Message Protobuf JSON object
     * @returns Array containing log message string (empty string of not recognized), parsed binary data (undefined if empty), and parsed sensor data (undefined if empty)
     */
    parseLogMessage(log_message) {
        // Search for matching log enum for each log message
        let log_enum = 0;
        let log_msg = "";
        for (const [key, val] of Object.entries(
            ServerBound.LogMessage.LogEnum
        )) {
            if (val === log_message.log) {
                log_enum = val;
                log_msg = key.toString();
                log_msg = log_msg.replaceAll("_", " "); // Replace underscores with spaces
                log_msg = log_msg.slice(0, 1) + log_msg.slice(1).toLowerCase(); // De-capitalize all but first letter
                break;
            }
        }

        let data = undefined;
        let sensor = log_message.sensor;
        if (log_message.data.length > 0) {
            let data_buff = Buffer.from(log_message.data, "base64");
            // Decode any data field if present
            switch (log_enum) {
                // String
                case ServerBound.LogMessage.LogEnum.ZOOPLANKTON_VERSION:
                case ServerBound.LogMessage.LogEnum.BUOY_APP_VERSION:
                case ServerBound.LogMessage.LogEnum.WAVE_PROCESSING_VERSION:
                    data = data_buff.toString();
                    break;
                // Int32
                case ServerBound.LogMessage.LogEnum.BATTERY_LEVEL_READS:
                case ServerBound.LogMessage.LogEnum.BATTERY_VOLTAGE_READS:
                case ServerBound.LogMessage.LogEnum.BATTERY_CURRENT_READS:
                    data = data_buff.readInt32LE(0);
                    break;
                // Float
                case ServerBound.LogMessage.LogEnum.OCEAN_TEMPERATURE_READS:
                case ServerBound.LogMessage.LogEnum.BUOY_TEMPERATURE_READS:
                case ServerBound.LogMessage.LogEnum.BUOY_PRESSURE_READS:
                case ServerBound.LogMessage.LogEnum.BUOY_HUMIDITY_READS:
                case ServerBound.LogMessage.LogEnum
                    .TOP_TEMPERATURE_SENSOR_READS:
                case ServerBound.LogMessage.LogEnum.TOP_HUMIDITY_SENSOR_READS:
                    if (data_buff.length === BYTES_DOUBLE) {
                        data = data_buff.readDoubleLE(0);
                    } else if (data_buff.length === BYTES_FLOAT) {
                        data = data_buff.readFloatLE(0);
                    }
                    break;
                // Int8
                case ServerBound.LogMessage.LogEnum.SATELLITE_SIGNAL_STRENGTH:
                    data = data_buff.readInt8(0);
                    break;
                case ServerBound.LogMessage.LogEnum
                    .TEST_JOB_NUMBER_OF_TESTS_RUN:
                case ServerBound.LogMessage.LogEnum
                    .TEST_JOB_NUMBER_OF_TESTS_FAILED:
                    data = data_buff.readUInt8(0);
                    break;
                default:
                    break;
            }
        }

        return [log_msg, data, sensor];
    }
}

export default FirmwareServerBound;
