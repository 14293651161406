import { useAuthState } from "react-firebase-hooks/auth";
import { useQuery } from "@tanstack/react-query";
import { auth } from "../config/firebase";
import config from "../config";
import { User } from "../schemas/user";
import { rtFetch } from "../models/APIClient";

const baseUrl = config.serverPath;

async function fetchCurrentUser() {
    const path = `${baseUrl}/user/me`;

    const response = await rtFetch(path, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error("Failed to get user");
    }

    const json = await response.json();
    return User.parse(json);
}

export const useCurrentUser = () => {
    const [user] = useAuthState(auth);

    return useQuery({
        queryKey: ["user", "current"],
        queryFn: fetchCurrentUser,
        enabled: !!user,
        staleTime: 5 * (60 * 1000), // 5 mins
        cacheTime: 5 * (60 * 1000), // 5 mins
        retry: false,
    });
};
