import React, { Component } from "react";
import { useParams } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import GraphComponent from "./GraphComponent";

import APIClient from "../../models/APIClient";

const styles = () => ({
    button: {
        "margin-right": "8px",
    },
});

class DownloadableGraphComponent extends Component {
    state = {
        downloadingData: false,
    };

    async downloadData() {
        this.setState({ downloadingData: true });
        const apiClient = new APIClient();
        await apiClient.downloadCsvData(this.props.params.foi_id);
        this.setState({ downloadingData: false });
    }

    render() {
        return (
            <Grid>
                <Button
                    variant="secondary"
                    disabled={this.state.downloadingData}
                    onClick={() => this.downloadData()}
                    className={this.props.classes.button}
                >
                    Download all sensor data
                </Button>
                <GraphComponent
                    foi_id={this.props.params.foi_id}
                    size="large"
                />
            </Grid>
        );
    }
}

export default withStyles(styles)((props) => (
    <DownloadableGraphComponent {...props} params={useParams()} />
));
