import React, { Component } from "react";
import { Tabs, Tab, AppBar, Grid, Typography } from "@mui/material";
import SwipeViews from "react-swipeable-views";
import { useParams } from "react-router-dom";
import KelpBuoys from "./KelpBuoys";
import APIClient from "../models/APIClient";
import sortNumericNull from "./helper/SortNumericNull";

let BuoyPageManager = class BuoyPageManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thingGroupings: [],
            index: 0,
            selectedTab: this.props.params.selector_label ?? false,
        };
    }

    async componentDidMount() {
        await this.getThingGroupings();
    }

    async getThingGroupings() {
        const apiClient = new APIClient();
        try {
            const thingGroupings = await apiClient.fetchThingGroupings();
            thingGroupings.sort((a, b) =>
                sortNumericNull(a.tab_order, b.tab_order)
            );
            const defaultIndex = this.state.selectedTab
                ? thingGroupings.findIndex(
                      (group) => group.selector_label === this.state.selectedTab
                  )
                : thingGroupings.findIndex(
                      (group) => group.default_tab === true
                  );
            const index = defaultIndex >= 0 ? defaultIndex : 0;
            this.setState({
                thingGroupings,
                index,
                selectedTab: thingGroupings[index].selector_label,
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        // Create tabs for each thing grouping and the buoy control manager.
        const tabProps = this.state.thingGroupings.map((grouping) => ({
            label: grouping.name,
            value: grouping.selector_label,
        }));

        // Add variants to first and last tab.
        if (tabProps.length === 1) {
            tabProps[0].variant = "single";
        } else if (tabProps.length > 0) {
            tabProps[0].variant = "leftmost";
            tabProps[tabProps.length - 1].variant = "rightmost";
        }
        return (
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography variant="h2" sx={{ mb: 3 }}>
                        Kelp Buoy Data
                    </Typography>
                    <AppBar position="static">
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={(_, newValue) => {
                                window.history.pushState(
                                    {
                                        additionalInformation: `Tab Navigation to ${newValue}`,
                                    },
                                    "",
                                    `/kelp-buoys/${newValue}`
                                );
                                this.setState({
                                    selectedTab: newValue,
                                    index: this.state.thingGroupings.findIndex(
                                        (group) =>
                                            group.selector_label === newValue
                                    ),
                                });
                            }}
                            aria-label="Kelp Buoys Tabs"
                        >
                            {tabProps.map((props, i) => (
                                <Tab key={i} {...props} />
                            ))}
                        </Tabs>
                    </AppBar>
                    {/*use SwipeViews to map tab and tab panel*/}
                    <SwipeViews
                        slideStyle={{ overflow: "hidden" }}
                        index={this.state.index}
                        onChangeIndex={(index) => {
                            this.setState({
                                selectedTab:
                                    this.state.thingGroupings[index]
                                        .selector_label,
                                index,
                            });
                        }}
                    >
                        {this.state.thingGroupings.map((x, index) =>
                            this.state.index === index ? (
                                <KelpBuoys
                                    key={index}
                                    thingSelector={x.selector_label}
                                />
                            ) : (
                                <div key={index}></div>
                            )
                        )}
                    </SwipeViews>
                </Grid>
            </Grid>
        );
    }
};

const BuoyPageManagerPage = (props) => (
    <BuoyPageManager {...props} params={useParams()} />
);

export default BuoyPageManagerPage;
