import config from "../config";
import moment from "moment-timezone";

import { auth } from "../config/firebase";

/**
 * A wrapper around fetch that handles 403 errors by fetching a new token and
 * retrying.
 */
export const rtFetch = async (resource, init) => {
    const authToken = auth.currentUser.accessToken;

    const res = await fetch(resource, {
        ...init,
        headers: {
            ...init.headers,
            Authorization: `Bearer ${authToken}`,
        },
    });

    if (res.status === 403) {
        // Get a new ID token (using force refresh).
        const newToken = await auth?.currentUser?.getIdToken(true);
        if (newToken == null) {
            throw new Error("unable to refresh token");
        }

        // Update headers with the new token and try again.
        const headers = {
            ...init.headers,
            Authorization: `Bearer ${newToken}`,
        };

        return await fetch(resource, { ...init, headers });
    }
    return res;
};

export const downloadBlob = (blob, downloadName) => {
    // We create a link, click it, and remove it (rather than just create
    // the file and assign it to window.location) so that we can set the
    // name of the downloaded file.
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = downloadName;
    link.click();
    link.remove();
};

export const downloadFile = (presignedUrl, downloadName) => {
    // We create a link, click it, and remove it (rather than just create
    // the file and assign it to window.location) so that we can set the
    // name of the downloaded file.
    const link = document.createElement("a");
    link.href = presignedUrl;
    link.download = downloadName;
    link.click();
    link.remove();
};

export default class APIClient {
    getAuthHeader() {
        return {
            "x-request-origin": "firebase",
        };
    }

    getJsonHeader() {
        let header = this.getAuthHeader();
        header["Content-Type"] = "application/json";
        return header;
    }

    /**
     *
     * @param {User} userInfo
     */
    async updateUser(userInfo) {
        return rtFetch(config.serverPath + "/user/update", {
            method: "POST",
            body: JSON.stringify(userInfo),
            headers: this.getJsonHeader(),
        })
            .then((res) => res.json())
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * @param {string} thingSelector
     * @param {number} [limit]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {string} [unit] - Instructs the API only to select things where
     * the associated datastream's unit matches the specified one
     * (e.g. unit_of_measurement = 'GPS Point')
     * @param {string[]} [dataStreamNames]
     */
    async getThingAll(
        thingSelector,
        limit,
        startDate,
        endDate,
        unit,
        dataStreamNames
    ) {
        const params = { id: thingSelector };
        (limit || !(startDate || endDate)) && (params.limit = limit || 50);
        startDate && (params.startDate = moment(startDate).toISOString());
        endDate && (params.endDate = moment(endDate).toISOString());
        params.unit = unit || "";
        params.dataStreamNames = dataStreamNames || [];

        let url =
            config.serverPath +
            `/sensor-things/thing-all/?` +
            new URLSearchParams(params);

        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    async getThingAllNoObservations(thingSelector) {
        const params = { id: thingSelector };
        let url =
            config.serverPath +
            `/sensor-things/thing-all-no-observations/?` +
            new URLSearchParams(params);

        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * @param {string} thingSelector
     */
    async getBuoysPowerStatus(thingSelector) {
        const params = { selectorLabel: thingSelector };
        let url =
            config.serverPath +
            `/kelp/buoylabel-power-status?` +
            new URLSearchParams(params);

        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     *
     *
     */
    async getBuoyTestResults(buoyId) {
        return rtFetch(
            config.serverPath + `/kelp/buoy-test-results/?id=${buoyId}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }

    /**
     * @param {string} buoyId
     */
    async getBuoyInstalledConfigs(buoyId) {
        const url = config.serverPath + `/kelp/buoy-configs?id=${buoyId}`;
        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * @param {string} buoyId
     * @param {number} limit
     */
    async getBuoySentConfigs(buoyId, limit) {
        const url =
            config.serverPath +
            `/kelp/buoy-sent-configs?id=${buoyId}&limit=${limit}`;
        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * @param {string} buoyId
     * @param {Object} configsObject
     * @param {Boolean} sendToBuoy
     */
    async createConfigRequest(buoyId, configsObject, sendToBuoy) {
        return await rtFetch(config.serverPath + `/kelp/create-config`, {
            method: "POST",
            body: JSON.stringify({
                id: buoyId,
                config: configsObject,
                send_to_buoy: sendToBuoy,
            }),
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            const jsonRes = await res.json();
            if (res.status === 200) {
                return jsonRes;
            } else {
                throw jsonRes;
            }
        });
    }

    /**
     * @param {string} buoyId
     * @param {Object} configsObject
     * @param {Boolean} sendToBuoy
     * @param {string} description
     */
    async createFirmwareConfigRequest(
        buoyId,
        configsObject,
        sendToBuoy,
        description
    ) {
        return await rtFetch(
            config.serverPath + `/kelp/create-firmware-config`,
            {
                method: "POST",
                body: JSON.stringify({
                    id: buoyId,
                    config: configsObject,
                    send_to_buoy: sendToBuoy,
                    description,
                }),
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const jsonRes = await res.json();
            if (res.status === 200) {
                return jsonRes;
            } else {
                throw jsonRes;
            }
        });
    }

    /**
     * @param {string} buoyId
     * @param {Object} requestObject
     * @param {string} buoyProtoVersion
     */
    async createDataRequest(buoyId, requestObject, buoyProtoVersion) {
        return await rtFetch(config.serverPath + `/kelp/send-data-request`, {
            method: "POST",
            body: JSON.stringify({
                id: buoyId,
                "data-request": requestObject,
                version: buoyProtoVersion,
            }),
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            const jsonRes = await res.json();
            if (res.status === 200) {
                return jsonRes;
            } else {
                throw jsonRes;
            }
        });
    }

    /**
     * Send a cancel data request
     *
     * @param {string} buoyId
     * @param {string} requestType
     * @param {string} buoyProtoVersion
     */
    async cancelDataRequest(buoyId, requestType, buoyProtoVersion) {
        return await rtFetch(config.serverPath + `/kelp/send-cancel-request`, {
            method: "POST",
            body: JSON.stringify({
                id: buoyId,
                "request-type": requestType,
                version: buoyProtoVersion,
            }),
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            const jsonRes = await res.json();
            if (res.status === 200) {
                return jsonRes;
            } else {
                throw jsonRes;
            }
        });
    }

    /**
     * Pull config templates data from database.
     *
     * @returns {Promise}
     */
    async fetchConfigTemplates() {
        return rtFetch(config.serverPath + `/kelp/buoy-config-templates`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * create Template
     */
    async createConfigTemplate(data) {
        return await rtFetch(
            config.serverPath + `/kelp/create-buoy-config-template`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * update Template
     */
    async updateConfigTemplate(data) {
        return await rtFetch(
            config.serverPath + `/kelp/update-buoy-config-template`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * delete Template
     */
    async deleteConfigTemplate(data) {
        return await rtFetch(
            config.serverPath + `/kelp/delete-buoy-config-template`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * Get the allowlist of selectorIds from database.
     *
     * @returns {Promise}
     */
    async fetchSelectorIdAllowlist() {
        return await rtFetch(
            config.serverPath + `/kelp/selector-id-allowlist`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * create selectorID in allowlist
     */
    async createSelectorIdInAllowlist(data) {
        return await rtFetch(config.serverPath + `/kelp/create-selector-id`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            const jsonRes = await res.json();
            if (res.status === 200) {
                return jsonRes;
            } else {
                throw jsonRes;
            }
        });
    }

    /**
     * create selectorID in allowlist UI table
     */
    async createSelectorIdInAllowlistTable(data) {
        return await rtFetch(config.serverPath + `/kelp/create-selector-id`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        });
    }

    /**
     * update selector id in allowlist
     */
    async updateSelectorIdInAllowlist(data) {
        return await rtFetch(config.serverPath + `/kelp/update-selector-id`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        });
    }

    /**
     * delete selector id in allowlist
     */
    async deleteSelectorIdInAllowlist(data) {
        return await rtFetch(config.serverPath + `/kelp/delete-selector-id`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        });
    }

    /**
     * Pull sensor thing groupings data from database.
     *
     * @returns {Promise}
     */
    async fetchThingGroupings() {
        return rtFetch(config.serverPath + `/sensor-things/thing-groupings`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * update thing grouping
     */
    async updateThingGrouping(data) {
        return await rtFetch(
            config.serverPath + `/sensor-things/update-thing-grouping`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * create a thing grouping
     */
    async createThingGrouping(data) {
        return await rtFetch(
            config.serverPath + `/sensor-things/create-thing-grouping`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * delete a thing grouping
     */
    async deleteThingGrouping(data) {
        return await rtFetch(
            config.serverPath + `/sensor-things/delete-thing-grouping`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * Downloads sensor data for a given buoy as a ZIP file.
     *
     * @param {string} buoyId
     * @returns {Promise}
     */
    async downloadSensorData(buoyId) {
        return rtFetch(
            config.serverPath + `/kelp/all-sensor-data/?id=${buoyId}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        )
            .then((res) => res.blob())
            .then((blob) => {
                downloadBlob(blob, `${buoyId}-observations.zip`);
            });
    }

    /**
     * Downloads images for a given buoy as a ZIP file.
     *
     * @param {string} buoyId
     * @returns {Promise}
     */
    async downloadImages(buoyId) {
        return rtFetch(config.serverPath + `/kelp/buoy-images?id=${buoyId}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        })
            .then((res) => res.json())
            .then((json) => {
                downloadFile(json.url, `${buoyId}-images.zip`);
            });
    }

    /**
     * Downloads logs for a given buoy as a ZIP file.
     *
     * @param {string} buoyId
     * @returns {Promise}
     */
    async downloadLogs(buoyId) {
        return rtFetch(config.serverPath + `/kelp/buoy-logs?id=${buoyId}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        })
            .then((res) => res.blob())
            .then((blob) => {
                downloadBlob(blob, `${buoyId}-logs.zip`);
            });
    }

    /**
     * Returns logs for the given buoy and job, ordered by descending date.
     *
     * @param {string} buoyId
     * @param {string} job
     * @param {number} page
     * @returns
     */
    async fetchLogsByJob(buoyId, job, page) {
        return rtFetch(
            config.serverPath +
                `/kelp/buoy-logs-by-job?id=${buoyId}&job=${job}&page=${page}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * Returns signed URLs for every image from the given buoy's bucket
     *
     * @param {string} buoyId
     * @returns
     */
    async fetchBuoyImages(buoyId, startDate, endDate) {
        return rtFetch(
            config.serverPath +
                `/kelp/buoy-display-images?id=${buoyId}&startDate=${startDate.format(
                    "YYYY-MM-DDTHH:mm:ss"
                )}&endDate=${endDate.format(
                    "YYYY-MM-DDTHH:mm:ss"
                )}&pageSize=100`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * Get all FOIs from DB
     */
    async getAllFois() {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-all-fois",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * update Foi
     */
    async updateFoi(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/update-feature-of-interest",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create Foi
     */
    async createFoi(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-feature-of-interest",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create Foi
     */
    async removeFoi(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/delete-feature-of-interest",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * get all locations
     */
    async getAllLocations() {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-all-locations",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create Location
     */
    async createLocation(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-location",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * update Location
     */
    async updateLocation(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/update-location",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * remove Location
     */
    async removeLocation(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/delete-location",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * Get things with given thingSelector from database.
     *
     * @returns {Promise}
     */
    async fetchThingsByThingSelector(thingSelector) {
        return await rtFetch(
            config.serverPath +
                `/sensor-things/get-things?thingSelector=${thingSelector}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * get all things
     */
    async getAllThings() {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-all-things",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * update Thing
     */
    async updateThing(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/update-thing",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create Thing
     */
    async createThing(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-thing",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * create Thing with new name and description and copies datastreams from an existing thing
     */
    async createThingAndDatastreams(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-thing-and-datastreams",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const jsonRes = await res.json();
            if (res.status === 200) {
                return jsonRes;
            } else {
                throw jsonRes;
            }
        });
    }

    /**
     * remove Thing
     */
    async removeThing(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/delete-thing",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * get all sensors
     */
    async getAllSensors() {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-all-sensors",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * update Sensor
     */
    async updateSensor(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/update-sensor",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create Sensor
     */
    async createSensor(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-sensor",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * remove Sensor
     */
    async removeSensor(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/delete-sensor",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * get all ObservedProperties
     */
    async getAllObservedProperties() {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-all-observed-properties",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * update ObservedProperty
     */
    async updateObservedProperty(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/update-observed-property",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create ObservedProperty
     */
    async createObservedProperty(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-observed-property",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * remove ObservedProperty
     */
    async removeObservedProperty(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/delete-observed-property",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * get all datastreams
     */
    async getAllDatastreams() {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-all-datastreams",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * update Datastream
     */
    async updateDatastream(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/update-datastream",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create Datastream
     */
    async createDatastream(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-datastream",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * remove Datastream
     */
    async removeDatastream(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/delete-datastream",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * get all datastream to foi connections
     */
    async getAllDatastreamToFOIConnections() {
        return await rtFetch(
            config.serverPath +
                "/sensor-things/get-all-datastream-to-foi-connections",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * update datastream to foi connection
     */
    async updateDatastreamToFOIConnection(data) {
        return await rtFetch(
            config.serverPath +
                "/sensor-things/update-datastream-to-foi-connection",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * create datastream to foi connection
     */
    async createDatastreamToFOIConnection(data) {
        return await rtFetch(
            config.serverPath +
                "/sensor-things/create-datastream-to-foi-connection",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * remove datastream to foi connection
     */
    async removeDatastreamToFOIConnection(data) {
        return await rtFetch(
            config.serverPath +
                "/sensor-things/delete-datastream-to-foi-connection",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * get all exavault users
     */
    async getAllExavaultUsers() {
        return await rtFetch(config.serverPath + "/exavault/get-all-users", {
            method: "GET",
            headers: this.getJsonHeader(),
        });
    }

    /**
     * create exavault users
     */
    async createExavaultUser(data) {
        return await rtFetch(config.serverPath + "/exavault/create-user", {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        });
    }
    /**
     * remove ExavaultUser
     */
    async removeExavaultUser(data) {
        return await rtFetch(config.serverPath + "/exavault/delete-user", {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        });
    }
    /**
     * remove ExavaultUser
     */
    async updateExavaultUser(data) {
        return await rtFetch(config.serverPath + "/exavault/update-user", {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        });
    }

    /**
     * get all DataDashboards
     */
    async getAllDataDashboards() {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-all-data-dashboards",
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * create DataDashboard
     */
    async createDataDashboard(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/create-data-dashboard",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * remove DataDashboard
     */
    async removeDataDashboard(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/delete-data-dashboard",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * remove DataDashboard
     */
    async updateDataDashboard(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/update-data-dashboard",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * get Feature of interest - Observed property Data
     */
    async getFOIData(data) {
        return rtFetch(config.serverPath + "/sensor-things/get-foi-data", {
            method: "POST",
            body: JSON.stringify(data),
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }
    /**
     * get Feature of interest - Observed property Data
     */
    async getDataDashboard(data) {
        return await rtFetch(
            config.serverPath + "/sensor-things/get-data-dashboard",
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * Downloads all data for a given feature of interest as a ZIP file.
     *
     * @param {string} foiId
     * @returns {Promise}
     */
    async downloadCsvData(foiId) {
        return rtFetch(
            config.serverPath + `/sensor-things/all-sensor-data?id=${foiId}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        )
            .then((res) => res.blob())
            .then((blob) => {
                downloadBlob(blob, `${foiId}-observations.zip`);
            });
    }

    /**
     * Downloads a buoys latest applied configs.
     *
     * @param {string} buoyId
     * @returns {Promise}
     */
    async downloadBuoyConfig(buoyId) {
        let config_file_name = `${buoyId}.json`;
        return rtFetch(
            config.serverPath + `/kelp/download-buoy-configs?id=${buoyId}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        )
            .then((res) => {
                config_file_name = res.headers
                    .get("content-disposition")
                    .split("=")?.[1];
                return res.blob();
            })
            .then((blob) => {
                downloadBlob(blob, config_file_name);
            });
    }

    /**
     * Downloads a buoys latest 1000 PSD files.
     *
     * @param {string} buoyId
     * @returns {Promise}
     */
    async downloadBuoyPSDs(buoyId) {
        let config_file_name = `${buoyId}.json`;
        return rtFetch(config.serverPath + `/kelp/buoy-psds?id=${buoyId}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        })
            .then((res) => {
                config_file_name = res.headers
                    .get("content-disposition")
                    .split("=")?.[1];
                return res.blob();
            })
            .then((blob) => {
                downloadBlob(blob, config_file_name);
            });
    }

    /**
     * Returns latest observation with the given FOI ID.
     */
    getLatestObservation = (foiId) => {
        return rtFetch(
            config.serverPath + `/sensor-things/latest-from-foi?id=${foiId}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Returns the latest observation for each thing matching the thing selector
     * for the passed Observed Property.
     * @param {string} thingSelector
     * @param {string} observedProperty
     * @returns {Array}
     */
    getLatestPropertyObservationForThing = (
        thingSelector,
        observedProperty
    ) => {
        return rtFetch(
            config.serverPath +
                `/sensor-things/datastream/observations/latest?selector=${thingSelector}&property=${observedProperty}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * get List of available accel buoy firmware
     */
    async getAccelFirmwareList() {
        return await rtFetch(
            config.serverPath + `/kelp/get-accel-firmware-list`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }
    /**
     * get firmware binary
     */
    async getFirmwareFile(filename) {
        return await rtFetch(
            config.serverPath + `/kelp/get-firmware-file?filename=${filename}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const resBlob = await res.blob();
            if (res.status === 200) {
                return resBlob;
            } else {
                throw resBlob;
            }
        });
    }

    /**
     * Get all the firmware versions from DB
     *
     * @returns {Promise}
     */
    async getFirmwareVersions() {
        return await rtFetch(config.serverPath + `/kelp/firmware-versions`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }

    /**
     * create a firmware version
     */
    async createFirmwareVersion(data) {
        return await rtFetch(
            config.serverPath + `/kelp/create-firmware-version`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * update a firmware version
     */
    async updateFirmwareVersion(data) {
        return await rtFetch(
            config.serverPath + `/kelp/update-firmware-version`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * delete a firmware version
     */
    async deleteFirmwareVersion(data) {
        return await rtFetch(
            config.serverPath + `/kelp/delete-firmware-version`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * Get all the buoy firmware versions from DB
     *
     * @returns {Promise}
     */
    async getBuoyFirmwareVersions() {
        return await rtFetch(
            config.serverPath + `/kelp/buoy-firmware-versions`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * create a buoy firmware version
     * @param {object} data - {version_id,thing_id}
     *  version_id {string} - uuid of version
     *  thing_id {string} - uuid of thing_id
     */
    async createBuoyFirmwareVersion(data) {
        return await rtFetch(
            config.serverPath + `/kelp/create-buoy-firmware-version`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * update a buoy firmware version
     */
    async updateBuoyFirmwareVersion(data) {
        return await rtFetch(
            config.serverPath + `/kelp/update-buoy-firmware-version`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }

    /**
     * delete a buoy firmware version
     */
    async deleteBuoyFirmwareVersion(data) {
        return await rtFetch(
            config.serverPath + `/kelp/delete-buoy-firmware-version`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.getJsonHeader(),
            }
        );
    }
    /**
     * @param {string} buoyId
     */
    async getBuoyFirmwareVersion(buoyId) {
        const url =
            config.serverPath + `/kelp/get-buoy-firmware-version?id=${buoyId}`;
        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * Get's the observations for a datastream
     *
     * @param {string} datastreamId
     * @param {moment} startDate
     * @param {moment} endDate
     * @returns {Promise}
     */
    async getDatastreamObservations(datastreamId, startDate, endDate) {
        return rtFetch(
            config.serverPath +
                `/sensor-things/datastream/${datastreamId}/observations?from=${startDate}&to=${endDate}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            return res.json();
        });
    }

    async getAPISpecs() {
        const url = config.serverPath + `/docs`;
        return rtFetch(url, {
            method: "GET",
            headers: this.getAuthHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }

    /**
     * @param {string} buoyId
     */
    async getLatestTestSuiteResultsForBuoy(buoyId) {
        const url =
            config.serverPath + `/kelp/get-test-results?buoyId=${buoyId}`;
        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        });
    }
}
