import { z } from "zod";

export const Permission = z.object({
    id: z.string(),
    resource: z.string(),
    access_level: z.enum(["create", "read", "update", "write", "delete"]),
});

export const Group = z.object({
    id: z.string(),
    name: z.string(),
    default: z.boolean(),
    permissions: z.array(Permission),
});

export const User = z.object({
    id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    google_id: z.string(),
    groups: z.array(Group),
});
