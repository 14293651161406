import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import MapComponent from "./MapComponent";
import formatcoords from "formatcoords";

class KelpMap extends Component {
    state = {
        startDate: moment().subtract(1, "weeks"),
        endDate: moment(),
    };

    processData(startDate, endDate) {
        if (this.props.buoyData == null) {
            return null;
        }

        const gpsDatastream = this.props.buoyData.datastreams.filter(
            (datastream) =>
                datastream.name === "GPS" || datastream.name === "latitude"
        );
        if (gpsDatastream.length > 1) {
            console.error(
                `Unexpected number of GPS datastreams: ${gpsDatastream.length}`
            );
        }

        const locations =
            gpsDatastream.length === 0
                ? []
                : gpsDatastream[0].sensorObservations;

        const timeData = locations.map((data) => data.phenomenonTime);
        const locationData = locations.map((data) => {
            const [lng, lat] = data.resultLocation;
            return JSON.stringify({
                type: "Point",
                coordinates: [lng, lat],
            });
        });

        // extract time_to_fix
        const timesToFix = locations.map((sensorObervation) => {
            //try and parse fre form properties to json
            try {
                const properties = JSON.parse(sensorObervation.properties);
                const timeToFix = properties?.time_to_fix;
                return timeToFix;
            } catch (e) {
                return undefined;
            }
        });

        let data = {
            ds_id: gpsDatastream.length === 0 ? "" : gpsDatastream[0].id,
            date_time: timeData,
            result_location: locationData,
            op_name: "buoy_location",
            thing_name: this.props.buoyData.name,
            times_to_fix: timesToFix,
        };

        data = this.processGeoGraphData(data);
        data = this.filterByDate(startDate, endDate, data);
        data.subType = "default";
        return data;
    }

    filterByDate(startDate, endDate, fullData) {
        const filteredTimeData = [];
        const filteredLocationData = [];
        const filteredFeatures = [];
        let earliestStartDate = null;

        fullData.date_time.forEach((date, i) => {
            const dateObject = moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ");
            if (dateObject > startDate && dateObject < endDate) {
                filteredTimeData.push(date);
                filteredLocationData.push(fullData.coordinates[i]);
                filteredFeatures.push(fullData.geoJson.data.features[i]);

                if (
                    earliestStartDate == null ||
                    dateObject < earliestStartDate
                ) {
                    earliestStartDate = dateObject;
                }
            }
        });

        const filteredGeoJson = { ...fullData.geoJson };
        filteredGeoJson.data.features = filteredFeatures;

        return {
            ...fullData,
            date_time: filteredTimeData,
            coordinates: filteredLocationData,
            geoJson: filteredGeoJson,
            earliestStartDate,
        };
    }

    processGeoGraphData(data) {
        return {
            ds_id: data.ds_id,
            thing_name: data.thing_name,
            date_time: data.date_time,
            unit: "GPS Point",
            coordinates: data.result_location.map(
                (point) => JSON.parse(point).coordinates
            ),
            color: "#565656",
            geoJson: {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: data.result_location.map((point, i) => {
                        let coordinates = JSON.parse(point).coordinates,
                            [lng, lat] = coordinates;
                        return {
                            type: "Feature",
                            properties: {
                                description: `<b>Name:</b> ${
                                    data.thing_name
                                } <br> <b>Coordinates(DMS):</b> ${formatcoords(
                                    lat,
                                    lng
                                ).format()}<br><b>Coordinates(DD):</b> ${coordinates} <br><b>Time:</b> ${moment
                                    .utc(data.date_time[i])
                                    .format("YYYY-MM-DD HH:mm:ss z")}<br>
                                    ${moment
                                        .tz(
                                            data.date_time[i],
                                            moment.tz.guess()
                                        )
                                        .format("YYYY-MM-DD HH:mm:ss z")}<br>
                                    <b>Time to get GPS fix:</b> ${
                                        data?.times_to_fix[i]
                                            ? data?.times_to_fix[i] + "ms"
                                            : "N/A"
                                    }`,
                            },
                            geometry: {
                                type: "Point",
                                coordinates: coordinates,
                            },
                        };
                    }),
                },
            },
            earliestStartDate: data.date_time.reduce(
                (acc, time) => (acc.isAfter(moment(time)) ? moment(time) : acc),
                moment()
            ),
        };
    }

    render() {
        // NOTE: MapComponent is not a fully controlled component. It stores the
        // initial dataset we pass in and updates it with the return value of the
        // update function.
        const data = this.processData(this.state.startDate, this.state.endDate);

        //This code renders the time period picker correctly in the event of no data being present
        const initialDateRange =
            data == null || data.earliestStartDate == null
                ? {
                      from: this.state.startDate,
                      to: this.state.endDate,
                  }
                : null;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {data != null ? (
                        <MapComponent
                            picker={true}
                            addPoints={true}
                            datasets={[data]}
                            update={(startDate, endDate) => {
                                this.setState(
                                    { startDate, endDate },
                                    async () =>
                                        await this.props.fetchBuoyData(
                                            startDate,
                                            endDate
                                        )
                                );
                            }}
                            initialDateFilter={"month"}
                            showLegend={false}
                            initialDateRange={initialDateRange}
                        />
                    ) : (
                        <h1>Loading</h1>
                    )}
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        );
    }
}

export default KelpMap;
