import React, { Fragment, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { RequirePermissions } from "./pages/components/RequirePermissions";
import Icon from "@mui/material/Icon";
import boatIconSrc from "./static/icons/noun-boat-3219677-FFFFFF.svg";
import buoyIconSrc from "./static/icons/noun-buoy-1841212-FFFFFF.svg";
import iotIconSrc from "./static/icons/noun-iot-5344841-FFFFFF.svg";
import gearSrc from "./static/icons/noun-setting-652826-FFFFFF.svg";
import developerSrc from "./static/icons/noun-developer-3134505-FFFFFF.svg";
import { makeStyles } from "@mui/styles";
import {
    VERIFICATION_BUOYS,
    Action,
    AUTH_ADMINISTRATION,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";

const NavLinkMui = React.forwardRef((props, ref) => (
    <RouterLink sx={{ color: "text.primary" }} ref={ref} {...props} />
));
NavLinkMui.displayName = "NavLinkMui";

const expandIconStyle = { marginRight: "8px" };

const useStyles = makeStyles({
    imageIcon: {
        height: "100%",
    },
    iconRoot: {
        textAlign: "center",
    },
});

const MainListItems = (props) => {
    const MenuListItemButton = ({ path, text, icon, children, sx }) => {
        const location = useLocation();
        const [isExpanded, setIsExpanded] = useState(false);

        const childPaths =
            children == null || !Array.isArray(children)
                ? []
                : children.map((child) =>
                      child.props !== undefined ? child.props.path : ""
                  );

        // The item should only act like a link if the menu is closed or it has
        // no children (subpages).
        const linkProps =
            !props.isMenuOpen || children == null
                ? {
                      component: NavLinkMui,
                      to: path,
                  }
                : {};

        return (
            <Fragment>
                <ListItemButton
                    {...linkProps}
                    divider={true}
                    selected={
                        location.pathname === path ||
                        childPaths.includes(location.pathname)
                    }
                    onClick={() => {
                        // Only expand if the menu is open.
                        if (props.isMenuOpen) {
                            setIsExpanded(!isExpanded);
                        }
                    }}
                >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText sx={sx} primary={text} />
                    {children != null &&
                        (isExpanded ? (
                            <IconExpandLess sx={expandIconStyle} />
                        ) : (
                            <IconExpandMore sx={expandIconStyle} />
                        ))}
                </ListItemButton>
                <Collapse in={isExpanded}>{children}</Collapse>
            </Fragment>
        );
    };

    const classes = useStyles();

    const buoyPermissions = [
        `${VERIFICATION_BUOYS}:${Action.CREATE}`,
        `${VERIFICATION_BUOYS}:${Action.UPDATE}`,
    ];

    const adminPermissions = [
        `${AUTH_ADMINISTRATION}:${Action.CREATE}`,
        `${AUTH_ADMINISTRATION}:${Action.UPDATE}`,
        `${AUTH_ADMINISTRATION}:${Action.DELETE}`,
        `${AUTH_ADMINISTRATION}:${Action.READ}`,
    ];

    const boatIcon = (
        <Icon classes={{ root: classes.iconRoot }}>
            <img
                className={classes.imageIcon}
                src={boatIconSrc}
                alt="boat icon"
            />
        </Icon>
    );

    const buoyIcon = (
        <Icon classes={{ root: classes.iconRoot }}>
            <img
                className={classes.imageIcon}
                src={buoyIconSrc}
                alt="buoy icon"
            />
        </Icon>
    );

    const iotIcon = (
        <Icon classes={{ root: classes.iconRoot }}>
            <img
                className={classes.imageIcon}
                src={iotIconSrc}
                alt="iot icon"
            />
        </Icon>
    );

    const gearIcon = (
        <Icon classes={{ root: classes.iconRoot }}>
            <img className={classes.imageIcon} src={gearSrc} alt="gear icon" />
        </Icon>
    );

    const developerIcon = (
        <Icon classes={{ root: classes.iconRoot }}>
            <img
                className={classes.imageIcon}
                src={developerSrc}
                alt="developer icon"
            />
        </Icon>
    );

    return (
        <div>
            <ListItemButton
                divider={true}
                onClick={() => {
                    window.location = "https://deployments.runningtide.com";
                }}
            >
                <ListItemIcon>{boatIcon}</ListItemIcon>
                <ListItemText
                    sx={{ color: "text.primary" }}
                    primary="Deployment Tracker"
                />
            </ListItemButton>
            <MenuListItemButton path="/kelp-buoys" text="Buoys" icon={buoyIcon}>
                <MenuListItemButton path="/kelp-buoys" text="Overview" />
                <MenuListItemButton text="Buoy Statuses" path="/kelp-buoys" />
                <ListItemButton
                    divider={true}
                    onClick={() => {
                        window.open(
                            "https://fleet-management.runningtide.com/monitoring/buoy"
                        );
                    }}
                >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText
                        sx={{ color: "text.primary" }}
                        primary="Buoy Creator"
                    />
                </ListItemButton>
                <MenuListItemButton
                    path="/buoy-control/bluetooth-setup"
                    text="Bluetooth Connect"
                />
                <RequirePermissions permissions={buoyPermissions}>
                    <MenuListItemButton
                        path="/buoy-control/buoys-manager"
                        text="Buoy Manager"
                    />

                    <MenuListItemButton
                        path="/buoy-control/config-updates"
                        text="Config Updates"
                    />

                    <MenuListItemButton
                        path="/buoy-control/config-templates"
                        text="Config Templates"
                    />

                    <MenuListItemButton
                        path="/buoy-control/allowlist-manager"
                        text="Allow List Manager"
                    />

                    <MenuListItemButton
                        path="/buoy-control/firmware-version-manager"
                        text="Firmware Version Manager"
                    />

                    <MenuListItemButton
                        path="/buoy-control/buoy-metadata-manager"
                        text="Buoy Metadata Manager"
                    />
                </RequirePermissions>
            </MenuListItemButton>
            <MenuListItemButton
                path="/sensor-things-api-manager"
                text="Sensor Manager"
                icon={iotIcon}
            >
                <MenuListItemButton
                    path="/sensor-things-api-manager"
                    text="Sensor Things API Manager"
                />
                <RequirePermissions permissions={buoyPermissions}>
                    <MenuListItemButton
                        path="/buoy-control/thing-groupings"
                        text="Thing Groupings"
                    />
                </RequirePermissions>
            </MenuListItemButton>

            <MenuListItemButton
                path="/swagger-docs/intervention"
                text="Dev Center"
                icon={developerIcon}
            >
                <MenuListItemButton
                    path="/swagger-docs/intervention"
                    text="Intervention API Docs"
                />
                <RequirePermissions permissions={buoyPermissions}>
                    <MenuListItemButton
                        path="/swagger-docs/cloud-api"
                        text="Cloud API Docs"
                    />
                </RequirePermissions>
            </MenuListItemButton>

            <RequirePermissions permissions={adminPermissions}>
                <MenuListItemButton
                    path="/administration"
                    text="Administration"
                    icon={gearIcon}
                >
                    <MenuListItemButton
                        path="/administration/users"
                        text="Users"
                    />
                    <MenuListItemButton
                        path="/administration/groups"
                        text="Groups"
                    />
                </MenuListItemButton>
            </RequirePermissions>
        </div>
    );
};

export default MainListItems;
