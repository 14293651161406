import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import APIClient from "../models/APIClient";
import CarbonAccountingAPIClient from "../models/CarbonAccountingAPIClient";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import VerificationBuoyStatusTable from "./components/VerificationBuoyStatusTable";
import ImageExplorer from "./components/ImageExplorer";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment-timezone";
import GraphComponentWithoutData from "./components/GraphComponent/GraphComponentWithoutData";
import { Box, Divider, Typography } from "@mui/material";

const voltageObservedProperty = "Voltage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function VerificationBuoyStatus(props) {
    const { params } = props;
    const [buoys, setBuoys] = useState([]);
    const [dateRange, setDateRange] = useState([
        moment().add(-90, "days"),
        moment(),
    ]);
    const [buoysPowerStatus, setBuoysPowerStatus] = useState([]);
    const [selectedDeployments, setSelectedDeployments] = useState([]);
    const [allDeployments, setAllDeployments] = useState([]);
    const [humidityDatastreams, setHumidityDatastreams] = useState([]);
    const [voltageDatastreams, setVoltageDatastreams] = useState([]);
    const [milliampsDatastreams, setMilliampsDatastreams] = useState([]);

    const interventionAPI = new CarbonAccountingAPIClient();

    // On page load get all deployments and set the selected deployments to the one in the URL
    useEffect(() => {
        const getAllDeployments = async () => {
            const deployments = await interventionAPI.getDeployments(50, 1);
            setAllDeployments(deployments.data);

            const initiallySelectedDeployments = deployments.data.filter(
                (d) => params.deployment_id === d.id
            );
            setSelectedDeployments(initiallySelectedDeployments);
        };
        getAllDeployments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.deployment_id]);

    // When the selected deployments change, update the buoy data
    useEffect(() => {
        const fetchBuoyStatus = async () => {
            await updateData(selectedDeployments);
        };
        fetchBuoyStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDeployments, dateRange]);

    const handleDeploymentsChanged = (event) => {
        const {
            target: { value },
        } = event;

        const updatedSelectedDeployments = allDeployments.filter((d) =>
            value.includes(d.id)
        );
        setSelectedDeployments(updatedSelectedDeployments);
    };

    const loadNewDateRange = ([from, to]) => {
        setDateRange([moment(from), moment(to)]);
    };

    const updateData = async (deployments) => {
        if (deployments.length == 0) return;

        const apiClient = new APIClient();

        let updatedBuoys = [],
            updatedBuoysPowerStatus = [];
        await Promise.all(
            deployments.map(async (deployment) => {
                const [
                    deploymentBuoys,
                    deploymentBuoysPowerStatus,
                    deploymentFirmwareLatestPower,
                ] = await Promise.all([
                    apiClient.getThingAll(
                        deployment.deploymentResponse.buoySelectorLabel,
                        1000,
                        dateRange[0],
                        dateRange[1],
                        null,
                        [
                            "GPS",
                            "latitude",
                            "Buoy Update",
                            "Image",
                            "Solar Power Sensor Current",
                            "Battery Power Sensor Current",
                            "Solar Power Sensor Voltage",
                            "Battery Power Sensor Voltage",
                            "CAM1 Humidity",
                            "CAM2 Humidity",
                            "Buoy Humidity Sensor Humidity",
                            "Top Humidity",
                            "Enclosure Humidity",
                            "Battery Voltage",
                            "Battery Current",
                        ]
                    ),
                    apiClient.getBuoysPowerStatus(
                        deployment.deploymentResponse.buoySelectorLabel
                    ),
                    apiClient.getLatestPropertyObservationForThing(
                        deployment.deploymentResponse.buoySelectorLabel,
                        voltageObservedProperty
                    ),
                ]);

                for (const obs of deploymentFirmwareLatestPower) {
                    obs.id in deploymentBuoysPowerStatus ||
                        (deploymentBuoysPowerStatus[obs.id] = {
                            batteryVolts: obs.result_numeric,
                        });
                }
                updatedBuoys = updatedBuoys.concat(deploymentBuoys);
                updatedBuoysPowerStatus = {
                    ...updatedBuoysPowerStatus,
                    ...deploymentBuoysPowerStatus,
                };
            })
        );

        const verificationBuoys = updatedBuoys.filter(
            (buoys) =>
                buoys.selectorLabels.includes("verification-buoy") ||
                buoys.selectorLabels.includes("camera-buoy")
        );

        extractHumidityDataForGraphs(verificationBuoys);
        extractVoltageDataForGraphs(verificationBuoys);
        extractMilliampDataForGraphs(verificationBuoys);

        setBuoys(updatedBuoys);
        setBuoysPowerStatus(updatedBuoysPowerStatus);
        return updatedBuoys;
    };

    const extractMilliampDataForGraphs = (buoys) => {
        const localMilliampsDatastreams = buoys
            .map((buoy) => {
                return buoy.datastreams
                    .filter((ds) =>
                        ds.description.toLowerCase().includes("buoy current")
                    )
                    .map((ds) => {
                        ds.thing_name = buoy.name;
                        return formatDatastreamForDisplay(ds);
                    });
            })
            .flat();
        setMilliampsDatastreams(localMilliampsDatastreams);
    };

    const extractVoltageDataForGraphs = (buoys) => {
        const localVoltageDatastreams = buoys
            .map((buoy) => {
                return buoy.datastreams
                    .filter(
                        (ds) =>
                            ds.name.toLowerCase().includes("sensor voltage") ||
                            ds.name.toLowerCase().includes("battery voltage")
                    )
                    .map((ds) => {
                        ds.thing_name = buoy.name;
                        return formatDatastreamForDisplay(ds);
                    });
            })
            .flat();

        setVoltageDatastreams(localVoltageDatastreams);
    };

    const extractHumidityDataForGraphs = (buoys) => {
        const localHumidityDatastreams = buoys
            .map((buoy) => {
                return buoy.datastreams
                    .filter(
                        (ds) =>
                            ds.name.toLowerCase().includes("humidity") &&
                            !ds.name.toLowerCase().includes("temp")
                    )
                    .map((ds) => {
                        ds.thing_name = buoy.name;
                        return formatDatastreamForDisplay(ds);
                    });
            })
            .flat();
        setHumidityDatastreams(localHumidityDatastreams);
    };

    const formatDatastreamForDisplay = (datastream) => {
        const numericData = datastream.sensorObservations.map((data) => ({
            dateTime: moment(data.phenomenonTime),
            resultNumeric: data.resultNumeric,
        }));

        numericData.sort((a, b) => a.dateTime - b.dateTime);
        datastream.date_time = numericData.map((d) => d.dateTime);
        datastream.ds_id = datastream.id;
        datastream.ds_name = datastream.name;
        datastream.op_name = datastream.name;
        datastream.op_id = datastream.id;
        datastream.op_unit = datastream.unitOfMeasurement;
        datastream.result_numeric = numericData.map((d) => d.resultNumeric);

        return datastream;
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h2" component="h2">
                    Deployment Buoy Status
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="deployment-selector-label">
                        Deployments
                    </InputLabel>
                    <Select
                        labelId="deployment-selector-label"
                        id="deployment-selector"
                        multiple
                        value={selectedDeployments.map((d) => d.id)}
                        onChange={handleDeploymentsChanged}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                    >
                        {allDeployments.map((deployment) => (
                            <MenuItem key={deployment.id} value={deployment.id}>
                                {deployment.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <Box
                    m={1}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <DateRangePicker
                        onChange={(range) => loadNewDateRange(range)}
                        value={[dateRange[0].toDate(), dateRange[1].toDate()]}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3" component="h3">
                    Charts
                </Typography>
            </Grid>
            {/* Humidity Graph */}
            <Grid item xs={6}>
                <GraphComponentWithoutData
                    name="Humidity"
                    description="Humidity for all buoys that are part of the selected deployment(s)"
                    numericGraphData={humidityDatastreams}
                    geoGraphData={[]}
                    size="large"
                    picker={false}
                    cappedGraphHeight={200}
                />
            </Grid>

            {/* Power Graph */}
            <Grid item xs={6}>
                <GraphComponentWithoutData
                    name="Power"
                    description="Power for all buoys that are part of the selected deployment(s)"
                    numericGraphData={voltageDatastreams}
                    geoGraphData={[]}
                    size="large"
                    picker={false}
                    cappedGraphHeight={200}
                />
            </Grid>

            <Grid item xs={6}>
                <GraphComponentWithoutData
                    name="Power"
                    description="Power for all buoys that are part of the selected deployment(s)"
                    numericGraphData={milliampsDatastreams}
                    geoGraphData={[]}
                    size="large"
                    picker={false}
                    cappedGraphHeight={200}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3" component="h3">
                    Images
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ImageExplorer
                    buoys={buoys.filter((b) =>
                        b.selectorLabels.includes("camera-buoy")
                    )}
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                ></ImageExplorer>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3" component="h3">
                    Status Table
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <VerificationBuoyStatusTable
                    buoys={buoys}
                    buoysPowerStatus={buoysPowerStatus}
                ></VerificationBuoyStatusTable>
            </Grid>
        </Grid>
    );
}

function VerificationBuoyStatusPage(props) {
    return <VerificationBuoyStatus {...props} params={useParams()} />;
}

export default VerificationBuoyStatusPage;
