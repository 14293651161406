import { z } from "zod";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Group, Permission, User } from "../../schemas/user";

type PermissionT = z.infer<typeof Permission>;
type GroupT = z.infer<typeof Group>;
type UserT = z.infer<typeof User>;

export function hasValidPermissions(
    user: UserT,
    requiredPermissions: string[]
) {
    if (!requiredPermissions) return false;
    if (user === null || user === undefined || user.groups === undefined)
        return false;

    const permissions = user.groups
        .map((g: GroupT) =>
            g.permissions.map(
                (p: PermissionT) => `${p.resource}:${p.access_level}`
            )
        )
        .flat();
    for (const rp in requiredPermissions) {
        if (!permissions.includes(requiredPermissions[rp])) {
            return false;
        }
    }
    return true;
}

type RequirePermissionsProps = {
    permissions: string[];
    showFallback: boolean;
    children: ChildNode;
};

export function RequirePermissions(props: RequirePermissionsProps) {
    const { data: user, isLoading } = useCurrentUser();

    if (isLoading || user == undefined) {
        return null;
    }

    if (hasValidPermissions(user, props.permissions)) {
        return props.children;
    }

    if (props.showFallback) {
        return <p>Unauthorized</p>;
    }

    return null;
}
