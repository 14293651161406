/**
 * An autocomplete component that can be used in editable columns of a
 * material-table.
 *
 * It can be used as follows:
 * editComponent: (props) => (
 *      <AutocompleteEditComponent
 *          value={props.value}
 *          onChange={props.onChange}
 *          idsToLabels={ ... }
 *      />
 * ),
 */

import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

const styles = () => ({
    autocomplete: {
        fontSize: 13,
    },
});

class AutocompleteEditComponent extends Component {
    render() {
        const {
            classes,
            value,
            onChange,
            idsToLabels,
            multiple,
            label,
            sort = true,
        } = this.props;

        // Invert the map so we can easily get an ID given a label (the Autocomplete
        // component needs the options to be labels in order to be searchable).
        const labelsToIds = {};
        Object.entries(idsToLabels).forEach(([id, name]) => {
            labelsToIds[name] = id;
        });

        const options = Object.keys(labelsToIds);
        if (sort) {
            options.sort();
        }

        return (
            <Autocomplete
                value={
                    multiple
                        ? (value || []).map((id) => idsToLabels[id])
                        : idsToLabels[value] || ""
                }
                options={options}
                renderInput={(params) => (
                    <TextField {...params} multiline label={label} />
                )}
                PopperComponent={(params) => (
                    <Popper {...params} style={{ width: "auto" }} />
                )}
                onChange={(_, value) => {
                    multiple
                        ? onChange(value.map((label) => labelsToIds[label]))
                        : onChange(labelsToIds[value]);
                }}
                classes={{ input: classes.autocomplete }}
                multiple={multiple || false}
            />
        );
    }
}

AutocompleteEditComponent.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    idsToLabels: PropTypes.object.isRequired,
    multiple: PropTypes.bool,
};

export default withStyles(styles)(AutocompleteEditComponent);
