import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { Box, Grid, Typography } from "@mui/material";
import RTable from "../components/RTable";

import AutocompleteEditComponent from "./AutocompleteEditComponent";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import moment from "moment-timezone";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

class DatastreamManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datastreamToFOIConnections: [],
            datastreamLookup: {},
            foiLookup: {},
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadState();
    }
    async loadState() {
        const apiClient = new APIClient();
        const resultDatastreams = await apiClient.getAllDatastreams(),
            resultFOIs = await apiClient.getAllFois(),
            resultDatastreamToFOIConnections =
                await apiClient.getAllDatastreamToFOIConnections(),
            resultThings = await apiClient.getAllThings(),
            datastreams = await resultDatastreams.json(),
            fois = await resultFOIs.json(),
            datastreamToFOIConnections =
                await resultDatastreamToFOIConnections.json(),
            things = await resultThings.json(),
            foiLookup = {},
            datastreamLookup = {},
            thingLookup = {};

        things.forEach(({ id, name }) => {
            thingLookup[id] = name;
        });
        fois.forEach(({ id, name }) => {
            foiLookup[id] = `${name} - ${id}`;
        });
        datastreams.forEach(({ id, name, thingId }) => {
            const thingName = thingLookup[thingId];
            datastreamLookup[id] = `${name} - ${id} - ${thingName}`;
        });

        this.setState({
            foiLookup,
            datastreamLookup,
            datastreamToFOIConnections,
            isLoading: false,
        });
    }

    async onRowAdd(newData) {
        const apiClient = new APIClient();
        const addSuccesses = await Promise.all(
            newData.datastream_id.map(async (datastreamId) => {
                try {
                    const requestData = {
                        ...newData,
                        datastream_id: datastreamId,
                    };

                    const response =
                        await apiClient.createDatastreamToFOIConnection(
                            requestData
                        );

                    const json = await response.json();
                    if (response.status !== 200) {
                        console.error(json);
                        return false;
                    }
                    requestData.id = json.id;

                    // Update state.
                    const datastreamToFOIConnections = [
                        requestData,
                        ...this.state.datastreamToFOIConnections,
                    ];
                    this.setState({ datastreamToFOIConnections });

                    return true;
                } catch (e) {
                    console.error(e);
                    return false;
                }
            })
        );

        const totalAttempts = addSuccesses.length;
        const successCount = addSuccesses.filter(
            (wasSuccess) => wasSuccess
        ).length;
        const failureCount = addSuccesses.length - successCount;

        if (failureCount === 0) {
            this.setState(successMessage(`${successCount} connections added`));
        } else {
            this.setState(
                errorMessage(
                    `Failed to add ${failureCount} of ${totalAttempts} connections`
                )
            );
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item lg={1} xs={12}>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Grid>
                <Grid item lg={12} xs={12} className={classes.description}>
                    <div className={classes.description}>
                        <Typography className={classes.title} variant="h6">
                            Datastream to FOI(Feature Of Interest) connections
                        </Typography>
                        <Typography
                            className={classes.paragraph}
                            variant="body1"
                        >
                            Here we keep track of which Datastream (or sensor)
                            was recording data for which Feature of Interest at
                            a certain time. For example, if a reef gets plugged
                            into a different outlet or a fluorometer gets put
                            into a different tank, a new Datastream to Feature
                            of Interest (FOI) connection needs to be made so
                            that we know that the data pertains to that specific
                            Feature of Interest. This allows us to keep track of
                            which sensor was measuring which Feature of Interest
                            and when, which is important for accurate data
                            analysis.
                        </Typography>
                    </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Box>
                        <RTable
                            className={classes.table}
                            title={
                                <Typography variant="h3">
                                    Datastream to FOI connection Manager
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Datastream name - ID - thing name *",
                                    field: "datastream_id",
                                    lookup: this.state.datastreamLookup,
                                    editComponent: (props) => (
                                        <AutocompleteEditComponent
                                            value={props.value}
                                            onChange={props.onChange}
                                            idsToLabels={
                                                this.state.datastreamLookup
                                            }
                                            // Allow multi-add, but not multi-update.
                                            multiple={
                                                props.value == null ||
                                                Array.isArray(props.value)
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Feature Of Interest - ID *",
                                    field: "feature_of_interest_id",
                                    lookup: this.state.foiLookup,
                                    editComponent: (props) => (
                                        <AutocompleteEditComponent
                                            value={props.value}
                                            onChange={props.onChange}
                                            idsToLabels={this.state.foiLookup}
                                        />
                                    ),
                                },
                                {
                                    title: `Start time (Timezone: ${moment.tz.guess()})*`,
                                    field: "start_date",
                                    type: "datetime",
                                },
                                {
                                    title: `End Date (Timezone: ${moment.tz.guess()})*`,
                                    field: "end_date",
                                    type: "datetime",
                                },
                            ]}
                            data={this.state.datastreamToFOIConnections}
                            editable={{
                                isEditable: () => true,
                                onRowAddCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowUpdateCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowAdd: (newData) => this.onRowAdd(newData),
                                onRowUpdate: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //Update everything
                                        function isDatastreamToFOIConnection(
                                            DatastreamToFOIConnection
                                        ) {
                                            return (
                                                DatastreamToFOIConnection.id ===
                                                newData.id
                                            );
                                        }
                                        new APIClient()
                                            .updateDatastreamToFOIConnection(
                                                newData
                                            )
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                let datastreamToFOIConnections =
                                                        [
                                                            ...this.state
                                                                .datastreamToFOIConnections,
                                                        ],
                                                    i =
                                                        datastreamToFOIConnections.findIndex(
                                                            isDatastreamToFOIConnection
                                                        );
                                                datastreamToFOIConnections[i] =
                                                    newData;
                                                this.setState({
                                                    datastreamToFOIConnections,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully updated"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        new APIClient()
                                            .removeDatastreamToFOIConnection(
                                                oldData
                                            )
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    resolve();
                                                    return;
                                                }
                                                //Update State
                                                let datastreamToFOIConnections =
                                                    [
                                                        ...this.state
                                                            .datastreamToFOIConnections,
                                                    ];
                                                datastreamToFOIConnections =
                                                    datastreamToFOIConnections.filter(
                                                        (datastream) =>
                                                            datastream.id !==
                                                            oldData.id
                                                    );
                                                this.setState({
                                                    datastreamToFOIConnections,
                                                });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully deleted"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                resolve();
                                            });
                                    }),
                            }}
                            isLoading={this.state.isLoading}
                            options={{
                                search: true,
                                maxColumnSort: 1,
                                filtering: true,
                                paging: true,
                                addRowPosition: "first",
                            }}
                        ></RTable>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(DatastreamManager);
