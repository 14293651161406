// src/AppWithRouterAccess.jsx

import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../config/firebase";
import queryString from "query-string";

import Dashboard from "../../Dashboard";
import Login from "../Login";

import SensorThingsApiManager from "../../pages/SensorThingsApiManager";
import BuoyPageManager from "../../pages/buoyPageManager";
import DataDashboard from "../../pages/DataDashboard";
import DownloadableGraphComponent from "../../pages/components/DownloadableGraphComponent";
import BuoysHistoryAndControlManager from "../../pages/BuoysHistoryAndControlManager";
import UnderConstruction from "../../pages/UnderConstruction";
import ConfigTemplateManager from "../../pages/sections/ConfigTemplateManager";
import ThingGroupingsManager from "../../pages/sections/ThingGroupingsManager";
import ConfigUpdatesManager from "../../pages/sections/ConfigUpdatesManager";
import BuoyCreatorManager from "../../pages/sections/BuoyCreatorManager";
import BuoysInfoManager from "../../pages/sections/BuoysInfoManager";
import SelectorIdAllowlistManager from "../../pages/sections/SelectorIdAllowlistManager";
import BluetoothBuoySetup from "../../pages/components/BluetoothBuoySetup";
import Administration from "../../pages/Administration/Administration";
import Users from "../../pages/Administration/users/Users";
import Groups from "../../pages/Administration/groups/Groups";
import FirmwareVersionManager from "../../pages/sections/FirmwareVersionManager";
import BuoyFirmwareVersionManager from "../../pages/sections/BuoyFirmwareVersionManager";
import VerificationBuoyStatus from "../../pages/VerificationBuoyStatus";
import SwaggerComponent from "../../pages/SwaggerComponent";
import NotFound from "./NotFound";

function AppWithRouter(props) {
    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (loading) return;
        if (!user) {
            if (location.pathname === "/login") {
                return;
            } else if (location.pathname === "/") {
                return navigate(`/login`);
            } else {
                return navigate(`/login?redirectUri=${location.pathname}`);
            }
        } else {
            const params = queryString.parse(location.search);
            if ("redirectUri" in params) return navigate(params.redirectUri);
        }
    }, [user, loading, navigate, location]);

    if (loading) {
        return <p>Loading...</p>;
    }
    if (user) {
        datadogRum.setUser({
            id: user.id,
            name: user.firstName + " " + user.lastName,
            email: user.email,
        });
    }

    const DeploymentTrackerRedirect = function () {
        window.location = "https://deployments.runningtide.com";
        return null;
    };

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Dashboard user={user} />}>
                <Route
                    path="/"
                    element={<Navigate replace to="/kelp-buoys" />}
                />
                <Route
                    path="/cdr/deployments"
                    element={<DeploymentTrackerRedirect />}
                />
                <Route
                    path="/sensor-things-api-manager"
                    element={<SensorThingsApiManager />}
                />
                <Route path="/kelp-buoys" element={<BuoyPageManager />} />
                <Route
                    path="/kelp-buoys/:selector_label"
                    element={<BuoyPageManager />}
                />
                <Route
                    path="/feature_of_interest/:foi_id"
                    element={<DownloadableGraphComponent />}
                />
                <Route
                    path="/buoy-control/config-templates"
                    element={<ConfigTemplateManager />}
                />
                <Route
                    path="/buoy-control/thing-groupings"
                    element={<ThingGroupingsManager />}
                />
                <Route
                    path="/buoy-control/config-updates"
                    element={<ConfigUpdatesManager />}
                />
                <Route
                    path="/buoy-control/buoy-creator"
                    element={<BuoyCreatorManager />}
                />
                <Route
                    path="/buoy-control/buoys-manager"
                    element={<BuoysInfoManager />}
                />
                <Route
                    path="/buoy-control/allowlist-manager"
                    element={<SelectorIdAllowlistManager />}
                />
                <Route
                    path="/buoy-control/firmware-version-manager"
                    element={<FirmwareVersionManager />}
                />
                <Route
                    path="/buoy-control/buoy-metadata-manager"
                    element={<BuoyFirmwareVersionManager />}
                />
                <Route
                    path="/buoy-control/bluetooth-setup"
                    element={<BluetoothBuoySetup />}
                />
                <Route
                    path="/kelp_buoy/:thing_id"
                    element={<BuoysHistoryAndControlManager />}
                />
                <Route
                    path="/data/:dash_id"
                    element={<DataDashboard {...props} />}
                />
                <Route
                    path="/swagger-docs/:api"
                    element={<SwaggerComponent {...props} />}
                />
                {/* <Route
                    path="/cdr/deployments/:deployment_id"
                    element={<DeploymentDetails {...props} />}
                /> */}
                <Route
                    path="/cdr/deployments/:deployment_id/verificationBuoyStatus"
                    element={<VerificationBuoyStatus {...props} />}
                />
                {/* <Route
                    path="/cdr/deployments/latest"
                    element={<LatestDeployment {...props} />}
                />
                <Route
                    path="/cdr/deployments/:deployment_id/events/create"
                    element={<DeploymentEventCreator {...props} />}
                />
                <Route
                    path="/cdr/deployments/:deployment_id/estimates/create"
                    element={<DeploymentEstimateCreator {...props} />}
                />
                <Route
                    path="/cdr/deployments/batch/:deployment_id/:batch_id"
                    element={<CarbonBuoyBatch {...props} />}
                />
                <Route
                    path="/cdr/deployments/:deployment_id/batch/create"
                    element={<CarbonBuoyBatchCreator {...props} />}
                />
                <Route
                    path="/cdr/deployments/estimates/:deployment_id/:estimate_id"
                    element={<DeploymentEstimate {...props} />}
                />
                <Route
                    path="/cdr/deployments/create"
                    element={<DeploymentCreator />}
                /> */}
                <Route path="/cdr/emissions" element={<UnderConstruction />} />
                <Route
                    path="/administration"
                    element={<Administration {...props} />}
                />
                <Route
                    path="/administration/users"
                    element={<Users {...props} />}
                />
                <Route
                    path="/administration/groups"
                    element={<Groups {...props} />}
                />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

export default AppWithRouter;
