import React, { Component } from "react";
import {
    Chip,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
} from "@mui/material";
import { ExpandMore, Sync } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import { FirmwareServerBound } from "../helper/FirmwareBuoyServerBoundHelper";
import { FirmwareBuoyTestComponent } from "../components/FirmwareBuoyTestComponent";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    tabel_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
});

class BluetoothTestJobCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            typeHandler: props.typeHandler,
            test_job_protobuf_packets: [],
            test_job_protobuf_buffer: [],
            test_job_protobuf_msg: {},
            test_job_running: false,
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.kickOffJob = this.kickOffJob.bind(this);

        this.handleNotificationsTestJobProtobufMessage =
            this.handleNotificationsTestJobProtobufMessage.bind(this);

        this.state.characteristic.startNotifications();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
    }
    /**
     * Update Results Table
     */
    componentDidUpdate() {
        // TODO
    }
    download(content, filename) {
        let encodedUri = encodeURI(content);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }
    /**
     * returns unique key for react elements
     */
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    /**
     * @param {*} event
     * @param {bool} isExpanded state to set to
     */
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    /**
     * BLE characteristic hander
     * receives new part of test job result Protobuf message
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsTestJobProtobufMessage(event) {
        let value = event.target.value;
        let new_packets = [...this.state.test_job_protobuf_packets];
        new_packets.push(value);
        let new_buffer = this.state.typeHandler(
            value,
            this.state.test_job_protobuf_packets
        );
        let new_msg = {};
        if (new_buffer.byteLength) {
            new_msg = new FirmwareServerBound(new_buffer);
            console.log(new_msg);
        }
        this.setState({
            test_job_protobuf_packets: new_packets,
            test_job_protobuf_buffer: new_buffer,
            test_job_protobuf_msg: new_msg,
            expanded: new_msg.buoy_tests ? true : false, // If test results received and parsed, automatically expand the accordion
            test_job_running: false,
        });
        this.enable_notify_ble_characteristic(false);
    }
    /**
     * Start notification on test job result message channels. to start listening for Protobuf messages
     * to be sent over BLE from buoy once ready
     */
    async enable_notify_ble_characteristic(enable = true) {
        try {
            if (enable) {
                this.state.characteristic.addEventListener(
                    "characteristicvaluechanged",
                    this.handleNotificationsTestJobProtobufMessage
                );
            } else {
                this.state.characteristic.removeEventListener(
                    "characteristicvaluechanged",
                    this.handleNotificationsTestJobProtobufMessage
                );
            }
        } catch (error) {
            //TODO Add proper error handling
            console.log("Argh! " + error);
        }
    }

    async kickOffJob(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            test_job_protobuf_packets: [],
            test_job_protobuf_buffer: [],
            test_job_protobuf_msg: {},
            test_job_running: true,
            expanded: false,
        });
        await this.state.characteristic.readValue();
        this.enable_notify_ble_characteristic(true);
    }

    stopProp(event) {
        event.stopPropagation();
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Accordion
                        className={classes.accordion}
                        expanded={this.state.expanded}
                        onChange={this.accordianToggle}
                    >
                        <AccordionSummary
                            className={classes.space_between}
                            expandIcon={<ExpandMore />}
                            id={this.state.uuid}
                        >
                            <div className={classes.flex_div100}>
                                <Typography
                                    sx={{ width: "30%", flexShrink: 0 }}
                                >
                                    {this.state.name}
                                </Typography>
                                <div className={classes.flex_div}>
                                    {this.state.test_job_running ? (
                                        <CircularProgress
                                            color="secondary"
                                            size={30}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <Chip
                                        icon={<Sync />}
                                        label="Run Job"
                                        disabled={
                                            !this.props.connected ||
                                            this.state.test_job_running
                                        }
                                        onClick={this.kickOffJob}
                                        color="success"
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                {this.state.test_job_protobuf_msg.buoy_tests ? (
                                    <FirmwareBuoyTestComponent
                                        timestamp={
                                            this.state.test_job_protobuf_msg
                                                .time
                                        }
                                        tests={
                                            this.state.test_job_protobuf_msg
                                                .buoy_tests
                                        }
                                        light_mode={true}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(BluetoothTestJobCharacteristic);
