import { Buffer } from "buffer";
import moment from "moment-timezone";
import FirmwareBuoyConfig from "./FirmwareBuoyConfigHelper";
//enum
export const BluetoothCharacteristicType = {
    BOOL: (val) => {
        let value = Boolean(new Uint8Array(val.buffer)?.[0]);
        let timestamp = moment.utc();
        return {
            value,
            csv: [timestamp.format("YYYY-MM-DD HH:mm:ss z"), value],
            timestamp,
        };
    },
    DOUBLE: (val) => {
        let value = val.getFloat64(0, true);
        let timestamp = moment.utc();
        return {
            value,
            csv: [timestamp.format("YYYY-MM-DD HH:mm:ss z"), value],
            timestamp,
        };
    },
    FLOAT: (val, from_sensor = true) => {
        let value = val.getFloat32(0, true);
        if (from_sensor) {
            let timestamp = moment.utc();
            return {
                value,
                csv: [timestamp.format("YYYY-MM-DD HH:mm:ss z"), value],
                timestamp,
            };
        } else {
            return value; // If streaming live data > 1 Hz, can skip timestamps and CSV formatting to save time
        }
    },
    INT32: (val) => {
        const value = Number(val.getInt32(0, true));
        const timestamp = moment.utc();
        return {
            value,
            csv: [timestamp.format("YYYY-MM-DD HH:mm:ss z"), value],
            timestamp,
        };
    },
    UINT64: (val) => {
        const value = Number(val.getBigInt64(0, true));
        const timestamp = moment.utc();
        return {
            value,
            csv: [timestamp.format("YYYY-MM-DD HH:mm:ss z"), value],
            timestamp,
        };
    },
    THREE_AXIS_DOUBLE: (val) => {
        let x = val.getFloat32(0, true);
        let y = val.getFloat32(4, true);
        let z = val.getFloat32(8, true);
        let timestamp = moment.utc();
        return {
            value: `${x},${y},${z}`,
            csv: [timestamp.format("YYYY-MM-DD HH:mm:ss.SSS z"), x, y, z],
            timestamp,
        };
    },
    UNIX_TIME: (val) => {
        const buoyRtcInMs = val.getBigInt64(0, true);
        const buoyRtcInS = Number(buoyRtcInMs) / 1000;
        const cur = moment().valueOf();
        const delta = cur - Number(buoyRtcInMs);
        const localTimestamp = moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");
        const momentFromBuoy = moment.unix(buoyRtcInS);
        const deltaString = momentFromBuoy.fromNow();
        const rtcFromBuoyTimestamp = momentFromBuoy
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");

        return {
            value: rtcFromBuoyTimestamp,
            csv: [rtcFromBuoyTimestamp, localTimestamp, deltaString, delta],
            localTimestamp,
        };
    },
    BATTERY_POWER: (val) => {
        const mVolts = val.getInt32(0, true);
        const batteryPptt = val.getInt32(4, true);
        const floatPercentage = batteryPptt / 100.0;
        const localTimestamp = moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");
        return {
            value: `${mVolts} mV, ~${floatPercentage} %`,
            csv: [localTimestamp, mVolts, floatPercentage],
            localTimestamp,
        };
    },
    ROCKBLOCK_SIGNAL: (val) => {
        // code comes in as ascii number, so we have to convert
        const signalStrength = val.getInt32(0, true);
        const localTime = moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS z");
        const statusObj = {
            0: "No",
            1: "Bad",
            2: "Poor",
            3: "Okay",
            4: "Good",
            5: "Great",
        };
        return {
            value: `${signalStrength} - ${statusObj[signalStrength]} Signal`,
            csv: [
                localTime,
                signalStrength,
                `${statusObj[signalStrength]} Signal`,
            ],
            localTime,
        };
    },
    ROCKBLOCK_RETURN_CODE: (val) => {
        const returnCode = val.getUint8(0, true);
        const localTime = moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS z");
        const status = returnCode === 0 ? "Successful" : "Failed";
        const errCodeMap = {
            0: "Success",
            "-1": "Modem Acquisition Failure",
            "-2": "Modem Power Failure",
            32: "No Signal",
        };
        const reason = errCodeMap[`${returnCode}`];
        return {
            value: `Send ${status} - Return Code: ${returnCode} - Reason: ${reason}`,
            csv: [localTime, returnCode, status, reason],
            localTime,
        };
    },
    BATTERY_CURRENT: (val) => {
        const mAmps = val.getInt32(0, true);
        const localTimestamp = moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");
        return {
            value: `${mAmps} mA`,
            csv: [localTimestamp, mAmps],
            localTimestamp,
        };
    },
    SOLAR_PANEL: (val) => {
        const mVolts = val.getInt32(0, true);
        const mAmps = val.getInt32(4, true);
        const localTimestamp = moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");
        return {
            value: `${mVolts} mV, ${mAmps} mA`,
            csv: [localTimestamp, mVolts, mAmps],
            localTimestamp,
        };
    },
    LED_TEST: (val) => {
        const testPass = val.getInt32(0, true);
        const current = val.getInt32(4, true);
        const localTimestamp = moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");
        return {
            value: `${testPass ? "OK" : "FAIL"}, ~${current} mA`,
            csv: [localTimestamp, testPass, current],
            localTimestamp,
        };
    },
    COMBINE_AND_EXTRACT_PSD: (packet, packets) => {
        // packet header first 2 bytes file_id, 3rd byte index,
        // 4th byte total packets as part of message
        let file_id = packet.getUint16(0, true);
        let num_of_packets = packet.getUint8(3, true);
        packets.push(packet);
        //filter out wrong IDs
        packets = packets.filter((p) => p.getUint16(0, true) === file_id);
        //check if we have all packets
        let indexes = new Set(packets.map((p) => p.getUint8(2, true)));
        let result = [];
        if (indexes.size === num_of_packets) {
            //compine & return uint16 array
            for (let index = 0; index < num_of_packets; index++) {
                let pac = packets.filter(
                    (p) => p.getUint8(2, true) === index
                )?.[0];
                if (!pac) return [];
                for (let j = 4; j < pac.byteLength; j += 2) {
                    result.push(pac.getUint16(j, true));
                }
            }
        }
        //else return empty array
        return result;
    },
    EXTRACT_AND_COMBINE_RAW_WAVE_SENSOR_DATA: (packet, packets) => {
        // packet header first 2 bytes file_id, 3rd byte index,
        // 4th byte total packets as part of message
        let file_id = packet.getUint16(0, true);
        let num_of_packets = packet.getUint8(3, true);
        packets.push(packet);
        //filter out wrong IDs
        packets = packets.filter((p) => p.getUint16(0, true) === file_id);
        //check if we have all packets
        let indexes = new Set(packets.map((p) => p.getUint8(2, true)));
        let result = [];
        if (indexes.size === num_of_packets) {
            //combine & return float array
            for (let index = 0; index < num_of_packets; index++) {
                let pac = packets.filter(
                    (p) => p.getUint8(2, true) === index
                )?.[0];
                if (!pac) return [];
                for (let j = 4; j + 4 <= pac.byteLength; j += 4) {
                    result.push(pac.getFloat32(j, true));
                }
            }
        }
        //else return empty array
        return result;
    },
    COMBINE_PICTURE_DATA_V1: (packet, packets) => {
        // !!Camlite V1.0 To be deprecated soon. Only supports images that can be fit in 256 BLE packets.
        // !!Pictures are using another approach to wave processing on packet handling!!
        //   In firmware, the 4 byte header approach required to know the full buffer size
        // and have it loaded in the stack before hand. Images jpeg have different compression
        // rates and sizes differ, making it difficult to reserve the stack in firmware.
        //   This approach just sends indexed data as it gets a full packet from the camera
        // and it has a status byte per packet telling when it's done. This results in a 1/40
        // of the memory being used whe comparing with the 4 byte header approach.

        // get current time
        const localTimestamp = moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");
        // packet indexes are in byte 0
        let indexes = new Set(packets.map((p) => p.getUint8(0, true)));
        // packet status are in byte 1.
        let status = packet.getUint8(1, true);

        // prepare a dummy in case image is not complete
        let temp_pic = window.btoa("");
        let result = {
            value: temp_pic,
            csv: [localTimestamp, temp_pic, 0],
            localTimestamp,
        };
        // Check the picture status. > 0 if we have all packets.
        if (status > 0) {
            //combine & return float array
            var binary = "";
            var count = 0;
            for (let index = 0; index < indexes.size; index++) {
                let pac = packets.filter(
                    (p) => p.getUint8(0, true) === index
                )?.[0];
                if (!pac) return result;
                for (let j = 2; j < pac.byteLength; j += 1) {
                    let bin = String.fromCharCode(pac.getUint8(j, true));
                    binary += bin;
                    count += 1;
                }
            }
            const picture = window.btoa(binary);
            const picture_size = count;
            return {
                value: picture,
                csv: [localTimestamp, picture, picture_size],
                localTimestamp,
            };
        }
        //else return empty array
        return result;
    },
    COMBINE_PICTURE_DATA: (packet, packets) => {
        // !!Pictures are using another approach to wave processing on packet handling!!
        //   In firmware, the 4 byte header approach required to know the full buffer size
        // and have it loaded in the stack before hand. Images jpeg have different compression
        // rates and sizes differ, making it difficult to reserve the stack in firmware.
        //   This approach just sends indexed data as it gets a full packet from the camera
        // and it has a status byte per packet telling when it's done. This results in a 1/40
        // of the memory being used whe comparing with the 4 byte header approach.

        // get current time
        const localTimestamp = moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ss.SSS z");
        // packet indexes are in byte 0
        let indexes = new Set(packets.map((p) => p.getUint16(0, true)));
        // packet status are in byte 1.
        let status = packet.getUint8(2, true);
        // prepare a dummy in case image is not complete
        let temp_pic = window.btoa("");
        let result = {
            value: temp_pic,
            csv: [localTimestamp, temp_pic, 0],
            localTimestamp,
        };
        // Check the picture status. > 0 if we have all packets.
        if (status > 0) {
            //combine & return float array
            var binary = "";
            var count = 0;
            for (let index = 0; index < indexes.size; index++) {
                let pac = packets.filter(
                    (p) => p.getUint16(0, true) === index
                )?.[0];
                if (!pac) return result;
                for (let j = 3; j < pac.byteLength; j += 1) {
                    let bin = String.fromCharCode(pac.getUint8(j, true));
                    binary += bin;
                    count += 1;
                }
            }
            const picture = window.btoa(binary);
            const picture_size = count;
            return {
                value: picture,
                csv: [localTimestamp, picture, picture_size],
                localTimestamp,
            };
        }
        //else return empty array
        return result;
    },
    CONFIG_PARSER: (buf) => {
        return new FirmwareBuoyConfig(buf);
    },
    PROTOBUF_PACKET: (packet, packets) => {
        // packet header first 2 bytes file_id, 3rd byte index,
        // 4th byte total packets as part of message
        let file_id = packet.getUint16(0, true);
        let num_of_packets = packet.getUint8(3, true);
        packets.push(packet);
        //filter out wrong IDs
        packets = packets.filter((p) => p.getUint16(0, true) === file_id);
        //check if we have all packets
        let indexes = new Set(packets.map((p) => p.getUint8(2, true)));
        let contents = [];
        let result = new ArrayBuffer(0);
        if (indexes.size === num_of_packets) {
            //combine & return byte array
            for (let index = 0; index < num_of_packets; index++) {
                let pac = packets.filter(
                    (p) => p.getUint8(2, true) === index
                )?.[0];
                if (!pac) return [];
                contents.push(pac.buffer.slice(4)); // Start slice at 5th byte, first byte after 4-byte packet header
            }
            result = Buffer.concat(
                contents.map((e) => {
                    return Buffer.from(e);
                })
            );
        }
        return Buffer.from(result);
    },
};

export const knownCharacteristics = [
    {
        name: `Water Temperature`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `00000002-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`datetime`, `Water Temperature (C)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `PHT Pressure`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `00000003-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`datetime`, `PHT Pressure (mBar)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `PHT Humidity`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `00000004-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`datetime`, `PHT Humidity (%)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `PHT Temperature`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `00000005-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`datetime`, `PHT Temperature (C)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `IMU Accelerometer`,
        typeHandler: BluetoothCharacteristicType.THREE_AXIS_DOUBLE,
        uuid: `00000006-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [
            `datetime`,
            `Acceleration X (meters/sec/sec)`,
            `Acceleration Y (meters/sec/sec)`,
            `Acceleration Z (meters/sec/sec)`,
        ],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `IMU Gyroscope`,
        typeHandler: BluetoothCharacteristicType.THREE_AXIS_DOUBLE,
        uuid: `00000007-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [
            `datetime`,
            `Angular Velocity X (rad/sec)`,
            `Angular Velocity Y (rad/sec)`,
            `Angular Velocity Z (rad/sec)`,
        ],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `IMU Magnometer`,
        typeHandler: BluetoothCharacteristicType.THREE_AXIS_DOUBLE,
        uuid: `00000008-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [
            `datetime`,
            `Magnetic Field X (Gs)`,
            `Magnetic Field Y (Gs)`,
            `Magnetic Field Z (Gs)`,
        ],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `RTC`,
        typeHandler: BluetoothCharacteristicType.UNIX_TIME,
        uuid: `00000009-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [
            `Buoy RTC`,
            `Local to UTC`,
            `RTC Time from Local`,
            `Raw Buoy RTC Drift (ms)`,
        ],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Battery`,
        typeHandler: BluetoothCharacteristicType.BATTERY_POWER,
        uuid: `0000000a-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Voltage (mV)`, `Percent Remaining`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Battery Current`,
        typeHandler: BluetoothCharacteristicType.BATTERY_CURRENT,
        uuid: `00000012-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Current (mA)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Solar Panel 1`,
        typeHandler: BluetoothCharacteristicType.SOLAR_PANEL,
        uuid: `00000013-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Voltage (mV)`, `Current (mA)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Solar Panel 2`,
        typeHandler: BluetoothCharacteristicType.SOLAR_PANEL,
        uuid: `00000014-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Voltage (mV)`, `Current (mA)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Solar Panel 3`,
        typeHandler: BluetoothCharacteristicType.SOLAR_PANEL,
        uuid: `00000015-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Voltage (mV)`, `Current (mA)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Solar Panel 4`,
        typeHandler: BluetoothCharacteristicType.SOLAR_PANEL,
        uuid: `00000016-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Voltage (mV)`, `Current (mA)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Rockblock Signal`,
        typeHandler: BluetoothCharacteristicType.ROCKBLOCK_SIGNAL,
        uuid: `0000000b-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Signal Strength`, `Signal Status`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Hibernate`,
        uuid: `000000c8-d3a4-4cc0-8980-479a49d7e621`,
        type: "hibernate",
    },
    {
        name: `Rockblock Packet Send`,
        typeHandler: BluetoothCharacteristicType.ROCKBLOCK_RETURN_CODE,
        uuid: `0000000c-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Status Code`, `Status`, `Reason`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Camera 1 V1.0`,
        typeHandler: BluetoothCharacteristicType.COMBINE_PICTURE_DATA_V1,
        uuid: `0000000e-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Picture`, `Picture Size`],
        requestOnConnect: false,
        type: "sensor",
        typeTemplate: "CameraV1",
    },
    {
        name: `Camera 2 V1.0`,
        typeHandler: BluetoothCharacteristicType.COMBINE_PICTURE_DATA_V1,
        uuid: `0000000f-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Picture`, `Picture Size`],
        requestOnConnect: false,
        type: "sensor",
        typeTemplate: "CameraV1",
    },
    {
        name: `Top Temperature`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `00000017-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`datetime`, `Top Temperature (C)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Top Humidity`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `00000018-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`datetime`, `Top Humidity (%)`],
        requestOnConnect: true,
        type: "sensor",
    },
    {
        name: `Camera UVC Test`,
        typeHandler: BluetoothCharacteristicType.LED_TEST,
        uuid: `00000019-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Test`, `Current`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Camera Flash Test`,
        typeHandler: BluetoothCharacteristicType.LED_TEST,
        uuid: `00000023-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Test`, `Current`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Flash UVC Test`,
        typeHandler: BluetoothCharacteristicType.LED_TEST,
        uuid: `00000024-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Test`, `Current`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Fluorometer Calibrated Output`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `0000001b-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Time`, `Calibrated Output (ug/L)`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Fluorometer Temperature`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `0000001c-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Time`, `Temperature (C)`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Fluorometer LED Voltage`,
        typeHandler: BluetoothCharacteristicType.INT32,
        uuid: `0000001d-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Time`, `LED Voltage`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Fluorometer Calibration Coefficient`,
        typeHandler: BluetoothCharacteristicType.INT32,
        uuid: `0000001e-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Time`, `Calibration Coefficient`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Fluorometer Calibration Offset`,
        typeHandler: BluetoothCharacteristicType.INT32,
        uuid: `0000001f-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Time`, `Calibration Offset`],
        requestOnConnect: false,
        type: "sensor",
    },
    {
        name: `Wave Processing`,
        typeHandler: BluetoothCharacteristicType.COMBINE_AND_EXTRACT_PSD,
        input_parameters: [
            {
                description:
                    "Amount of seconds of accel data collectionSeconds to collect accel data for",
                type: "int",
                write_characteristics_uuid: `00000064-d3a4-4cc0-8980-479a49d7e621`,
            },
            {
                description: "Enable or disable transmission of live wave data",
                type: "int",
                write_characteristics_uuid: `00000064-d3a4-4cc0-8980-479a49d7e621`,
            },
            {
                description:
                    "Sample frequency for FFT and live data output in Hz",
                type: "float",
                write_characteristics_uuid: `00000064-d3a4-4cc0-8980-479a49d7e621`,
            },
        ],
        uuid: `00000064-d3a4-4cc0-8980-479a49d7e621`,
        related_characteristics_uuids: {
            psd_north: `0000006e-d3a4-4cc0-8980-479a49d7e621`,
            psd_east: `00000078-d3a4-4cc0-8980-479a49d7e621`,
            psd_down: `00000082-d3a4-4cc0-8980-479a49d7e621`,
            live_data_north: `000000d2-d3a4-4cc0-8980-479a49d7e621`,
            live_data_east: `000000dc-d3a4-4cc0-8980-479a49d7e621`,
            live_data_down: `000000e6-d3a4-4cc0-8980-479a49d7e621`,
        },
        requestOnConnect: false,
        type: "job",
        typeTemplate: "wave_processing",
    },
    {
        name: `Wave Raw Sensor Collect`,
        typeHandler:
            BluetoothCharacteristicType.EXTRACT_AND_COMBINE_RAW_WAVE_SENSOR_DATA,
        input_parameters: [
            {
                description: "Amount of seconds of raw sensor data collection",
                type: "int",
                write_characteristics_uuid: `00000065-d3a4-4cc0-8980-479a49d7e621`,
            },
            {
                description: "Raw sensor sampling frequency in Hz",
                type: "float",
                write_characteristics_uuid: `00000065-d3a4-4cc0-8980-479a49d7e621`,
            },
            {
                description: "Accelerometer Output Data Rate in Hz",
                type: "int",
                write_characteristics_uuid: `00000065-d3a4-4cc0-8980-479a49d7e621`,
            },
            {
                description: "Gyroscope Output Data Rate in Hz",
                type: "int",
                write_characteristics_uuid: `00000065-d3a4-4cc0-8980-479a49d7e621`,
            },
        ],
        uuid: `00000065-d3a4-4cc0-8980-479a49d7e621`,
        related_characteristics_uuids: {
            raw_accelerometer_x: `0000008c-d3a4-4cc0-8980-479a49d7e621`,
            raw_accelerometer_y: `00000096-d3a4-4cc0-8980-479a49d7e621`,
            raw_accelerometer_z: `000000a0-d3a4-4cc0-8980-479a49d7e621`,
            raw_gyroscope_x: `000000aa-d3a4-4cc0-8980-479a49d7e621`,
            raw_gyroscope_y: `000000b4-d3a4-4cc0-8980-479a49d7e621`,
            raw_gyroscope_z: `000000be-d3a4-4cc0-8980-479a49d7e621`,
        },
        requestOnConnect: false,
        type: "job",
        typeTemplate: "wave_raw_sensor_collect",
    },
    {
        name: `Test Job`,
        typeHandler: BluetoothCharacteristicType.PROTOBUF_PACKET,
        uuid: `000000e7-d3a4-4cc0-8980-479a49d7e621`,
        requestOnConnect: false,
        type: "job",
        typeTemplate: "test_job",
    },
    {
        name: `Test Suite`,
        typeHandler: BluetoothCharacteristicType.PROTOBUF_PACKET,
        uuid: `000000e8-d3a4-4cc0-8980-479a49d7e621`,
        requestOnConnect: false,
        type: "job",
        typeTemplate: "test_suite",
    },
    {
        name: `IMEI`,
        typeHandler: BluetoothCharacteristicType.UINT64,
        uuid: `0000000d-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `IMEI`],
        requestOnConnect: true,
        type: "stats",
        typeTemplate: "imei",
    },
    {
        name: `Configs`,
        typeHandler: BluetoothCharacteristicType.CONFIG_PARSER,
        uuid: `00000011-d3a4-4cc0-8980-479a49d7e621`,
        requestOnConnect: true,
        type: "utils",
        typeTemplate: "config",
    },
    {
        name: `RTT`,
        typeHandler: BluetoothCharacteristicType.BOOL,
        uuid: `0000001a-d3a4-4cc0-8980-479a49d7e621`,
        requestOnConnect: true,
        type: "stats",
        typeTemplate: "stat",
    },
    {
        name: `Camera 1`,
        typeHandler: BluetoothCharacteristicType.COMBINE_PICTURE_DATA,
        uuid: `00000020-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Picture`, `Picture Size`],
        requestOnConnect: false,
        type: "sensor",
        typeTemplate: "Camera",
    },
    {
        name: `Camera 2`,
        typeHandler: BluetoothCharacteristicType.COMBINE_PICTURE_DATA,
        uuid: `00000021-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Local Time`, `Picture`, `Picture Size`],
        requestOnConnect: false,
        type: "sensor",
        typeTemplate: "Camera",
    },
    {
        name: `2nd Fluorometer`,
        typeHandler: BluetoothCharacteristicType.DOUBLE,
        uuid: `00000022-d3a4-4cc0-8980-479a49d7e621`,
        csvHeader: [`Time`, `Chlorophyll concentration (ug/L)`],
        requestOnConnect: false,
        type: "sensor",
        typeTemplate: "Fluorometer2",
    },
];

export default knownCharacteristics;
