import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import withStyles from "@mui/styles/withStyles";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import APIClient from "../../models/APIClient";
import BuoyConfigSender from "../helper/BuoyConfigSender";
import MessageHelper from "../helper/MessageHelper";
import { Typography } from "@mui/material";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    formControl: {
        minWidth: "200px",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    titleForConfigPush: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1),
    },
});

class ConfigUpdatesManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thingsWithSelectedLabel: [],
            selectedThings: [],
            allLabels: [],
            selectedLabel: "",
        };
        this.pushConfigs = this.pushConfigs.bind(this);
    }

    async componentDidMount() {
        this.getAllThings();
    }

    async getAllThings() {
        const labels = ["cage-buoy-v1", "sonde-buoy-v2"];
        this.setState({ allLabels: labels });
    }

    async getThingsBySelectorLabel(selectorLabel) {
        const apiClient = new APIClient();
        try {
            const result = await apiClient.fetchThingsByThingSelector(
                selectorLabel
            );
            const things = await result.json();
            const filteredThings = things.filter(
                (thing) =>
                    thing.selectorLabels &&
                    thing.selectorLabels.length > 0 &&
                    thing.selectorLabels.includes("rt-buoy") &&
                    (thing.selectorLabels.includes("cage-buoy-v1") ||
                        thing.selectorLabels.includes("sonde-buoy-v2"))
            );
            this.setState({ thingsWithSelectedLabel: filteredThings });
        } catch (e) {
            console.error(e);
        }
    }

    pushConfigs = async (configsObject, sendToBuoy) => {
        const apiClient = new APIClient();

        if (Object.entries(configsObject).length === 0) {
            return;
        }

        try {
            let resultList = [];
            const selectedThings = this.state.selectedThings;
            for (let i = 0; i < selectedThings.length; i++) {
                // a valid result should be a configID
                // TODO(yanjin): We could make these requests in parallel by waiting on all calls to apiClient.createConfigRequests using await Promise.all(...).
                const result = await apiClient.createConfigRequest(
                    selectedThings[i].id,
                    configsObject,
                    sendToBuoy
                );
                if (result && result.length > 0) {
                    resultList.push(selectedThings[i].name);
                } else {
                    const successMessage =
                        resultList.length === 0
                            ? ""
                            : `Config for buoy(s) ${resultList.join(
                                  ", "
                              )} successfully pushed.`;
                    this.setState(
                        errorMessage(
                            `Config for buoy ${selectedThings[i].name} failed!${successMessage}`
                        )
                    );
                }
            }
            this.setState(
                successMessage(
                    `Config for buoy(s) ${resultList.join(
                        ", "
                    )} successfully pushed!`
                )
            );
        } catch (e) {
            this.setState(errorMessage(e));
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper>
                <div className={classes.container}>
                    <h1>Config Updates Manager</h1>
                    <Typography>
                        Note* firmware buoys can only be updated on their buoy
                        detail page.
                    </Typography>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Label</InputLabel>
                        <Select
                            value={this.state.selectedLabel}
                            onChange={(event) => {
                                const selectedLabel = event.target.value;
                                this.setState({
                                    selectedLabel,
                                });
                                this.getThingsBySelectorLabel(selectedLabel);
                            }}
                        >
                            {this.state.allLabels.map((label, i) => (
                                <MenuItem value={label} key={i}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel>Buoys</InputLabel>
                        <Select
                            multiple
                            value={this.state.selectedThings}
                            onChange={(event) => {
                                const selectedThings = event.target.value;
                                this.setState({ selectedThings });
                            }}
                        >
                            {this.state.thingsWithSelectedLabel.map(
                                (thing, i) => (
                                    <MenuItem value={thing} key={i}>
                                        {thing.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </div>
                <BuoyConfigSender pushConfigs={this.pushConfigs} />
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Paper>
        );
    }
}

export default withStyles(styles)(ConfigUpdatesManager);
