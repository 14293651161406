/**
 * A component that lazy loads a buoy's images.
 */

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import APIClient from "../../models/APIClient";
import ImageList from "@mui/material/ImageList";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";
import moment from "moment-timezone";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";

function ImageExplorer(props) {
    const { buoys, startDate, endDate } = props;
    const [images, setImages] = useState([]);
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            if (!buoys) return;
            const apiClient = new APIClient();

            let imageList = await Promise.all(
                buoys.map(async (buoy) => {
                    const is = await apiClient.fetchBuoyImages(
                        buoy.id,
                        startDate,
                        endDate
                    );

                    return is.data.map((i) => {
                        i.buoyName = buoy.name;
                        i.date = getImageDate(i);
                        i.cameraName = getCameraName(i);
                        i.cameraKey = `${i.buoyName} ${i.cameraName}`;
                        return i;
                    });
                })
            );

            imageList = imageList.flat();
            imageList = imageList.sort((a, b) => b.date.diff(a.date));

            //Set up camera names to be filtered by
            const camData = imageList.map((i) => {
                return {
                    name: i.cameraKey,
                    buoyName: i.buoyName,
                    cameraName: i.cameraName,
                    selected: true,
                };
            });
            const cams = [
                ...new Map(camData.map((item) => [item.name, item])).values(),
            ].sort((a, b) => a.name.localeCompare(b.name));
            setCameras(cams);
            setImages(imageList);
        };

        fetchImages();
    }, [buoys, startDate, endDate]);

    const getCameraName = (image) => {
        const name = image.url.match(/CAM_\d*/);
        if (name.length > 0) {
            return name[0];
        }
        return "";
    };

    const getImageDate = (image) => {
        if (image.resultTime) {
            return moment.utc(image.resultTime, "YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
        const timestamp = image.url.match(
            /\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}/
        );

        if (timestamp === null) {
            return null;
        }

        return moment.utc(timestamp[0], "yyyy-MM-dd_HH-mm-ss");
    };

    const getImageNameSize = (image) => {
        return `${image.cameraKey} - [ ${image.size} bytes]`;
    };

    const handleCameraButtonChanged = (_, newAlignment) => {
        setCameras(
            cameras.map((camera) => {
                if (newAlignment.includes(camera.name)) {
                    return { ...camera, selected: !camera.selected };
                } else {
                    return camera;
                }
            })
        );
    };

    const selectedCameraNames = cameras
        .filter((camera) => camera.selected)
        .map((camera) => camera.name);

    return (
        <Stack>
            <Box>
                <ToggleButtonGroup
                    color="primary"
                    value={cameras
                        .filter((camera) => camera.selected)
                        .map((camera) => camera.name)}
                    exclusive
                    onChange={handleCameraButtonChanged}
                    aria-label="Camera Selection"
                >
                    {cameras.map((camera) => {
                        return (
                            <ToggleButton value={camera.name} key={camera.name}>
                                {camera.name}
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
            </Box>
            <Box
                sx={{
                    height: 500,
                    overflowY: "scroll",
                }}
            >
                <ImageList cols={5} gap={8}>
                    {images.map((image, index) => {
                        if (!selectedCameraNames.includes(image.cameraKey)) {
                            return "";
                        }
                        return (
                            <ImageListItem key={`${image.url}-${index}`}>
                                <img
                                    src={image.url}
                                    alt={"Kelp Buoy"}
                                    loading="lazy"
                                    style={{ objectFit: "contain" }}
                                />
                                <ImageListItemBar
                                    title={
                                        <div>
                                            <div>
                                                {moment
                                                    .utc(image.date)
                                                    .format(
                                                        "YYYY-MM-DD HH:mm:ss z"
                                                    )}
                                            </div>
                                            <div>
                                                {moment
                                                    .tz(
                                                        image.date,
                                                        moment.tz.guess()
                                                    )
                                                    .format(
                                                        "YYYY-MM-DD HH:mm:ss z"
                                                    )}
                                            </div>
                                        </div>
                                    }
                                    subtitle={getImageNameSize(image)}
                                />
                            </ImageListItem>
                        );
                    })}
                </ImageList>
            </Box>
        </Stack>
    );
}

export default ImageExplorer;
