import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import APIClient from "../models/APIClient";
import FullKelpMap from "./components/FullKelpMapComponent";
import VerificationBuoyStatusTable from "./components/VerificationBuoyStatusTable";

const voltageObservedProperty = "Voltage";

class KelpBuoys extends Component {
    constructor(props) {
        super(props);
        this.thingSelector = props.thingSelector;
        this.state = { buoys: null, buoysPowerStatus: null };
    }

    async componentDidMount() {
        const apiClient = new APIClient();
        const [buoys, buoysPowerStatus, firmwareLatestPower] =
            await Promise.all([
                apiClient.getThingAll(this.thingSelector, 1, null, null, null, [
                    "GPS",
                    "latitude",
                    "Buoy Update",
                    "Image",
                ]),
                apiClient.getBuoysPowerStatus(this.thingSelector),
                apiClient.getLatestPropertyObservationForThing(
                    this.thingSelector,
                    voltageObservedProperty
                ),
            ]);
        for (const obs of firmwareLatestPower) {
            obs.id in buoysPowerStatus ||
                (buoysPowerStatus[obs.id] = {
                    batteryVolts: obs.result_numeric,
                });
        }
        this.setState({ buoys, buoysPowerStatus });
    }

    render() {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FullKelpMap buoys={this.state.buoys} />
                </Grid>
                <Grid item xs={12}>
                    <VerificationBuoyStatusTable
                        buoys={this.state.buoys}
                        buoysPowerStatus={this.state.buoysPowerStatus}
                    ></VerificationBuoyStatusTable>
                </Grid>
            </Grid>
        );
    }
}

export default KelpBuoys;
