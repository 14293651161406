/* eslint-disable */

module.exports = {
    serverPath: process.env.REACT_APP_SERVER_URL,
    carbonAccountingServerPath:
        process.env.REACT_APP_CARBON_ACCOUNTING_SERVER_URL,
    mapBoxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    env: process.env.REACT_APP_ENV,
    datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    datadogApplicationID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    appVersion: process.env.REACT_APP_VERSION,
    datadogSampleRate: parseInt(process.env.REACT_APP_DATADOG_SAMPLE_RATE || 0),
};
