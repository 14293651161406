import React, { Component } from "react";
import {
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
} from "@mui/material";
import { ExpandMore, Sync, CloudDownload } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import moment from "moment-timezone";
import BluetoothRtcSyncButton from "./BluetoothRtcSyncButton";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    tabel_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
});

class BluetoothSensorCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            requestOnConnect: props.requestOnConnect,
            typeHandler: props.typeHandler,
            csvHeader: props.csvHeader,
            values: [],
            loading: false,
            disabled: false,
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.readValueCallback = this.readValueCallback.bind(this);
        this.downloadCallback = this.downloadCallback.bind(this);
        this.enable_notify_ble_characteristic();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.values = [];
    }
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    handleNotifications(event) {
        let value = event.target.value;
        //ignore 0 byte messages
        if (value.byteLength) {
            this.values.push(value);
            this.setState({
                values: [...this.state.values, this.state.typeHandler(value)],
                loading: false,
                disabled: false,
            });
        }
    }
    downloadCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        let csvContent = `data:text/csv;charset=utf-8,${this.state.csvHeader
            .join(",")
            .trim()}\n${this.state.values
            .map((e) => e.csv.join(","))
            .join("\n")
            .trim()}`;
        //TODO Philipp add buoy name in file download
        let filename = `BUOY_NAME_${this.state.name
            .toLowerCase()
            .replace(" ", "_")}_${moment
            .utc()
            .format("YYYY-MM-DD_HH:mm:ss_z")}.csv`;
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }
    async readValueCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        if (
            this.state.name === "UVC Test" &&
            !window.confirm(
                "WARNING! This will turn on UVC LEDs for 1 second. Do not look into the UVC LEDs, and make sure you have adequate UVC eye protection. If you need to cancel this UVC test, please click the cancel button."
            )
        ) {
            return;
        }
        this.setState({ loading: true, disabled: true });
        await this.state.characteristic.readValue();
        console.log(`read ${this.state.name}'s readings`);
    }
    async enable_notify_ble_characteristic() {
        try {
            await this.state.characteristic.startNotifications();
            this.state.characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotifications
            );
            if (this.state.requestOnConnect) {
                await this.state.characteristic.readValue();
            }
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Accordion
                        className={classes.accordion}
                        expanded={this.state.expanded}
                        onChange={this.accordianToggle}
                    >
                        <AccordionSummary
                            className={classes.space_between}
                            expandIcon={<ExpandMore />}
                            id={this.state.uuid}
                        >
                            <div className={classes.flex_div100}>
                                <Typography
                                    sx={{ width: "30%", flexShrink: 0 }}
                                >
                                    {this.state.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        width: "50%",
                                        color: "text.secondary",
                                    }}
                                >
                                    {
                                        this.state.values?.[
                                            this.state.values?.length - 1
                                        ]?.value
                                    }
                                </Typography>
                                <div className={classes.flex_div}>
                                    {this.state.name === "RTC" ? (
                                        <BluetoothRtcSyncButton
                                            uuid={this.state.uuid}
                                            name={this.state.name}
                                            characteristic={
                                                this.state.characteristic
                                            }
                                            connected={this.props.connected}
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                    {this.state.loading && (
                                        <CircularProgress
                                            color="secondary"
                                            size={30}
                                        />
                                    )}
                                    <Chip
                                        icon={<Sync />}
                                        label="Refresh"
                                        onClick={this.readValueCallback}
                                        disabled={
                                            !this.props.connected ||
                                            this.state.disabled
                                        }
                                        color="success"
                                    />
                                    <Chip
                                        icon={<CloudDownload />}
                                        label="Download"
                                        onClick={this.downloadCallback}
                                        color="success"
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow className={classes.tabel_row}>
                                            {this.state.csvHeader.map((v) => (
                                                <TableCell key={this.getKey()}>
                                                    {v}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.values.map((v, i) => (
                                            <TableRow
                                                className={
                                                    i ===
                                                    this.state.values.length - 1
                                                        ? ""
                                                        : classes.tabel_row
                                                }
                                                key={this.getKey()}
                                            >
                                                {v.csv.map((cell) => (
                                                    <TableCell
                                                        key={this.getKey()}
                                                    >
                                                        {cell}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Paper>
        );
    }
}

export { BluetoothSensorCharacteristic as BluetoothSensorCharacteristicWithoutStyles };
export { styles };

export default withStyles(styles)(BluetoothSensorCharacteristic);
