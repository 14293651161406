import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import App from "./components/App";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import Theme from "./config/theme";
import DataDog from "./config/datadog";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
const mode = "dark"; // Todo implement this as a system preference read?
const theme = Theme(mode);

const dd = new DataDog();
dd.start();

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
