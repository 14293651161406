import moment from "moment";
export function formatDateTime(dateTime: string) {
    return moment
        .utc(dateTime, "YYYY-MM-DDTHH:mm:ss.SSSZ")
        .format("YYYY-MM-DD HH:mm:ss z");
}

export function formatShortDate(dateTime: string) {
    return moment
        .utc(dateTime, "YYYY-MM-DDTHH:mm:ss.SSSZ")
        .format("YYYY-MM-DD");
}
