import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Box, Typography } from "@mui/material";
import RTable from "@material-table/core";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const DropDown = ({ value, onChange }) => (
    <select onChange={onChange} value={value}>
        <option value="schedule">schedule</option>
        <option value="sensors">sensors</option>
        <option value="logs">logs</option>
        <option value="buoy">buoy</option>
        <option value="firmware">firmware</option>
    </select>
);

class ConfigTemplateManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configTemplates: [],
            isLoading: true,
        };
        this.getConfigTemplates = this.getConfigTemplates.bind(this);
    }

    componentDidMount() {
        this.getConfigTemplates();
    }
    async getConfigTemplates() {
        const apiClient = new APIClient();
        try {
            let configTemplates = await apiClient.fetchConfigTemplates();
            configTemplates = configTemplates.map((template) => {
                // beautify contents
                template.contents = JSON.stringify(
                    template.contents,
                    undefined,
                    2
                );
                return template;
            });
            this.setState({ configTemplates, isLoading: false });
        } catch (e) {
            console.error(e);
        }
    }

    editComponent = (props) => (
        <DropDown
            value={props.value}
            onChange={(e) => {
                props.onChange(e.target.value);
            }}
        />
    );
    onRowUpdate = (newData) =>
        new Promise((resolve, reject) => {
            const newDataUpdate = { ...newData };
            try {
                if (newDataUpdate.contents)
                    newDataUpdate.contents = JSON.parse(newDataUpdate.contents);
            } catch (e) {
                this.setState(errorMessage("Contents - expecting valid JSON"));
                reject();
                return;
            }
            function isTemplate(template) {
                return template.id === newData.id;
            }
            new APIClient()
                .updateConfigTemplate(newDataUpdate)
                .then(async (res) => {
                    if (res.status !== 200) {
                        res = await res.json();
                        this.setState(errorMessage(res));
                        reject();
                        return;
                    }
                    //Update State
                    const configTemplates = [...this.state.configTemplates],
                        i = configTemplates.findIndex(isTemplate);
                    configTemplates[i] = newData;
                    this.setState({ configTemplates });
                    this.setState(successMessage("Successfully updated"));
                    resolve();
                })
                .catch((e) => {
                    this.setState(errorMessage(e));
                    reject();
                });
        });

    onRowAdd = (newData) =>
        new Promise((resolve, reject) => {
            const newDataUpdate = { ...newData };
            try {
                if (newDataUpdate.contents)
                    newDataUpdate.contents = JSON.parse(newDataUpdate.contents);
            } catch (e) {
                this.setState(errorMessage("Contents - expecting valid JSON"));
                reject();
                return;
            }
            new APIClient()
                .createConfigTemplate(newDataUpdate)
                .then(async (res) => {
                    if (res.status !== 200) {
                        res = await res.json();
                        this.setState(errorMessage(res));
                        reject();
                        return;
                    }
                    //Update State
                    res = await res.json();
                    newData.id = res.id;
                    const configTemplates = [...this.state.configTemplates];
                    configTemplates.unshift(newData);
                    this.setState({
                        configTemplates,
                    });
                    this.setState(successMessage("Successfully added"));
                    resolve();
                })
                .catch((e) => {
                    this.setState(errorMessage(e));
                    reject();
                });
        });

    onRowDelete = (oldData) =>
        new Promise((resolve) => {
            new APIClient()
                .deleteConfigTemplate(oldData)
                .then(async (res) => {
                    if (res.status !== 200) {
                        res = await res.json();
                        this.setState(errorMessage(res));
                        resolve();
                        return;
                    }
                    //Update State
                    res = await res.json();
                    let configTemplates = [...this.state.configTemplates];
                    configTemplates = configTemplates.filter(
                        (template) => template.id !== oldData.id
                    );
                    this.setState({
                        configTemplates,
                    });
                    this.setState(successMessage("Successfully deleted"));
                    resolve();
                })
                .catch((e) => {
                    this.setState(errorMessage(e));
                    resolve();
                });
        });

    render() {
        const { classes } = this.props;

        return (
            <Grid>
                <Grid item lg={1} xs={12}>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Grid>

                <Grid item lg={12} xs={12}>
                    <Box>
                        <RTable
                            className={classes.table}
                            title={
                                <Typography variant="h3">
                                    Config Templates Manager
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Name",
                                    field: "name",
                                },
                                {
                                    title: "Config Type",
                                    field: "config_type",
                                    initialEditValue: "schedule",
                                    editComponent: this.editComponent,
                                },
                                {
                                    title: "Contents (JSON)",
                                    field: "contents",
                                },
                            ]}
                            data={this.state.configTemplates}
                            editable={{
                                isEditable: () => true,
                                onRowAddCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowUpdateCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowAdd: (newData) => this.onRowAdd(newData),
                                onRowUpdate: (newData, oldData) =>
                                    this.onRowUpdate(newData, oldData),
                                onRowDelete: (oldData) =>
                                    this.onRowDelete(oldData),
                            }}
                            isLoading={this.state.isLoading}
                            options={{
                                search: true,
                                maxColumnSort: 1,
                                filtering: true,
                                paging: true,
                                // always display the newly-added row at the beginning of the table
                                addRowPosition: "first",
                                idSynonym: "name",
                            }}
                        ></RTable>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ConfigTemplateManager);
