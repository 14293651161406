import { Button } from "@mui/material";
import {
    Action,
    AUTH_ADMINISTRATION,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import { RequirePermissions } from "../components/RequirePermissions";

export default function Administration() {
    const permissions = [
        `${AUTH_ADMINISTRATION}:${Action.CREATE}`,
        `${AUTH_ADMINISTRATION}:${Action.READ}`,
        `${AUTH_ADMINISTRATION}:${Action.DELETE}`,
        `${AUTH_ADMINISTRATION}:${Action.UPDATE}`,
    ];

    return (
        <RequirePermissions permissions={permissions} showFallback>
            <Button
                variant="secondary"
                type="submit"
                href="/administration/users"
            >
                Users
            </Button>
            <Button
                variant="secondary"
                type="submit"
                href="/administration/groups"
            >
                Groups
            </Button>
        </RequirePermissions>
    );
}
