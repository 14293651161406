import React from "react";
import SensorsIcon from "@mui/icons-material/Sensors";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { Chip, Tooltip } from "@mui/material";
import moment from "moment-timezone";
import { PortableWifiOff } from "@mui/icons-material";

const BuoyStatusEnum = {
    GOOD: 0,
    WARNING: 1,
    ERROR: 2,
    DEACTIVATED: 3,
};

function BuoyStatus(props) {
    const {
        lastContact,
        batteryReading,
        ignoreBatteryHealth,
        batteryThresholdWarn,
        batteryThresholdError,
        deactivated,
    } = props;
    let status = BuoyStatusEnum.ERROR;
    let contactTooltip = "Last contact was more than a week ago";
    if (lastContact) {
        if (moment(lastContact).isAfter(moment().subtract(1, "days"))) {
            status = BuoyStatusEnum.GOOD;
            contactTooltip = "";
        } else if (
            moment(lastContact).isBefore(moment().subtract(1, "days")) &&
            moment(lastContact).isAfter(moment().subtract(7, "days"))
        ) {
            status = BuoyStatusEnum.WARNING;
            contactTooltip = "Last contact was more than a 24 hours ago";
        }
    }

    let batteryTooltip = !ignoreBatteryHealth;
    if (!ignoreBatteryHealth) {
        if (batteryReading) {
            if (batteryReading < batteryThresholdError) {
                batteryTooltip = `Last reported battery status is below ${batteryThresholdError}V`;
                status = BuoyStatusEnum.ERROR;
            } else if (batteryReading < batteryThresholdWarn) {
                batteryTooltip = `Last reported battery status is below ${batteryThresholdWarn}V`;
                status = BuoyStatusEnum.WARNING;
            }
        } else {
            batteryTooltip = "Battery health unreported";
        }
    }

    let tooltipText;
    if (batteryTooltip && contactTooltip) {
        tooltipText = (
            <div>
                {batteryTooltip} <br /> {contactTooltip}
            </div>
        );
    } else {
        tooltipText = batteryTooltip || contactTooltip;
    }

    status = deactivated == true ? BuoyStatusEnum.DEACTIVATED : status;

    if (status === BuoyStatusEnum.GOOD) {
        return <Chip icon={<SensorsIcon />} label="OK" color="success" />;
    } else if (status === BuoyStatusEnum.WARNING) {
        return (
            <Tooltip title={tooltipText} placement="right">
                <Chip
                    icon={<EventRepeatIcon />}
                    label="Warning"
                    color="warning"
                />
            </Tooltip>
        );
    } else if (status === BuoyStatusEnum.DEACTIVATED) {
        return (
            <Chip
                icon={<PortableWifiOff />}
                label="Deactivated"
                color="default"
            />
        );
    } else {
        return (
            <Tooltip title={tooltipText} placement="right">
                <Chip
                    icon={<RunningWithErrorsIcon />}
                    label="Error"
                    color="error"
                />
            </Tooltip>
        );
    }
}

export default BuoyStatus;
