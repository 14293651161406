import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { Box, Grid, Typography } from "@mui/material";
import RTable from "../components/RTable";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import SelectorThingsLabelManager from "../components/SensorThingsLabelManager";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

class ThingManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sensors: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadState();
    }
    async loadState() {
        let result = await new APIClient(this.props.authState).getAllSensors(),
            sensors = await result.json();

        sensors = sensors.map((sensor) => {
            if (sensor.metadata)
                sensor.metadata = JSON.stringify(sensor.metadata);
            return sensor;
        });
        this.setState({ sensors: sensors });
        this.setState({ isLoading: false });
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item lg={1} xs={12}>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Grid>
                <Grid item lg={12} xs={12} className={classes.description}>
                    <div className={classes.description}>
                        <Typography className={classes.title} variant="h6">
                            Sensor
                        </Typography>
                        <Typography
                            className={classes.paragraph}
                            variant="body1"
                        >
                            A Sensor in OSG Sensor Things represents a device or
                            equipment that can detect or measure a physical
                            property and convert it into a signal which can be
                            recorded, transmitted or processed. It is one of the
                            core entities in the OSG Sensor Things API, and
                            it&apos;s used to represent the physical sensors
                            that are deployed in the field. It&apos;s used in
                            this dashboard to create a Datastream, which is a
                            sequence of observations made by a sensor at a
                            certain time and location. By creating a Datastream,
                            you can track and store data from the sensor over
                            time.
                        </Typography>
                    </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Box>
                        <RTable
                            className={classes.table}
                            title={
                                <Typography variant="h3">
                                    Sensor Manager
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Name *",
                                    field: "name",
                                },
                                {
                                    title: "Description *",
                                    field: "description",
                                },
                                {
                                    title: "selectorLabel",
                                    field: "selectorlabel",
                                    render: (rowData) => (
                                        <SelectorThingsLabelManager
                                            value={rowData.selectorlabel}
                                            edit={false}
                                        ></SelectorThingsLabelManager>
                                    ),
                                    editComponent: (rowData) => {
                                        return (
                                            <SelectorThingsLabelManager
                                                value={rowData.value}
                                                onChange={rowData.onChange}
                                                edit={true}
                                            ></SelectorThingsLabelManager>
                                        );
                                    },
                                },
                                {
                                    title: "metadata (JSON) *",
                                    field: "metadata",
                                },
                            ]}
                            data={this.state.sensors}
                            editable={{
                                isEditable: () => true,
                                onRowAddCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowUpdateCancelled: () => {
                                    this.setState(clearMessage());
                                },
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //add sensor
                                        let newDataUpdate = { ...newData };
                                        try {
                                            if (newDataUpdate.metadata)
                                                newDataUpdate.metadata =
                                                    JSON.parse(
                                                        newDataUpdate.metadata
                                                    );
                                        } catch (e) {
                                            this.setState(
                                                errorMessage(
                                                    "Metadata - expecting valid JSON"
                                                )
                                            );
                                            reject();
                                            return;
                                        }
                                        //Trim out new lines and white spaces from beginning and end of labels
                                        newDataUpdate.selectorLabel =
                                            newDataUpdate.selectorLabel.map(
                                                (label) => label.trim()
                                            );
                                        new APIClient()
                                            .createSensor(newDataUpdate)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                res = await res.json();
                                                newData.id = res.id;
                                                let sensors = [
                                                    ...this.state.sensors,
                                                ];
                                                sensors.unshift(newData);
                                                this.setState({ sensors });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully added"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowUpdate: (newData) =>
                                    new Promise((resolve, reject) => {
                                        //Update everything
                                        let newDataUpdate = { ...newData };
                                        try {
                                            if (newDataUpdate.metadata)
                                                newDataUpdate.metadata =
                                                    JSON.parse(
                                                        newDataUpdate.metadata
                                                    );
                                        } catch (e) {
                                            this.setState(
                                                errorMessage(
                                                    "Metadata - expecting valid JSON"
                                                )
                                            );
                                            reject();
                                            return;
                                        }
                                        //Trim out new lines and white spaces from beginning and end of labels
                                        newDataUpdate.selectorLabel =
                                            newDataUpdate.selectorLabel.map(
                                                (label) => label.trim()
                                            );
                                        function isSensor(sensor) {
                                            return sensor.id === newData.id;
                                        }
                                        new APIClient()
                                            .updateSensor(newDataUpdate)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    reject();
                                                    return;
                                                }
                                                //Update State
                                                let sensors = [
                                                        ...this.state.sensors,
                                                    ],
                                                    i =
                                                        sensors.findIndex(
                                                            isSensor
                                                        );
                                                sensors[i] = newData;
                                                this.setState({ sensors });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully updated"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                reject();
                                            });
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        new APIClient()
                                            .removeSensor(oldData)
                                            .then(async (res) => {
                                                if (res.status !== 200) {
                                                    res = await res.json();
                                                    this.setState(
                                                        errorMessage(res)
                                                    );
                                                    resolve();
                                                    return;
                                                }
                                                //Update State
                                                let sensors = [
                                                    ...this.state.sensors,
                                                ];
                                                sensors = sensors.filter(
                                                    (sensor) =>
                                                        sensor.id !== oldData.id
                                                );
                                                this.setState({ sensors });
                                                this.setState(
                                                    successMessage(
                                                        "Successfully deleted"
                                                    )
                                                );
                                                resolve();
                                            })
                                            .catch((e) => {
                                                this.setState(errorMessage(e));
                                                resolve();
                                            });
                                    }),
                            }}
                            isLoading={this.state.isLoading}
                            options={{
                                search: true,
                                maxColumnSort: 1,
                                filtering: true,
                                paging: true,
                                addRowPosition: "first",
                            }}
                        ></RTable>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ThingManager);
