import React, { Component } from "react";
import {
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    TextField,
} from "@mui/material";
import { ExpandMore, Sync, CloudDownload } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import moment from "moment-timezone";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    tabel_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
    canvas: {
        // height: "auto",
        // width: "100%",
    },
});

const DEFAULT_CALIBRATION_OFFSET = 0.0;
const DEFAULT_CALIBRATION_COEFFICIENT = 1.1;
const MICRO_FACTOR = 1000000;
/* Picture parameters are arranged in an array before sending. */
/* Calibration parameters array size. */
const CALIBRATION_PARAMETERS_ARRAY_SIZE = 8;
/* Calibration offset offset in the parameters array. */
const OFFSET_PARAMETERS_ARRAY_OFFSET = 0;
/* Calibration coefficient offset in the parameters array. */
const COEFFICIENT_PARAMETERS_ARRAY_OFFSET = 4;

class BluetoothFluorometer2Characteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            requestOnConnect: props.requestOnConnect,
            typeHandler: props.typeHandler,
            csvHeader: props.csvHeader,
            values: [],
            loading: false,
            disabled: false,
            calibrationOffset: DEFAULT_CALIBRATION_OFFSET,
            calibrationCoefficient: DEFAULT_CALIBRATION_COEFFICIENT,
            errors: {},
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.readValueCallback = this.readValueCallback.bind(this);
        this.downloadCallback = this.downloadCallback.bind(this);
        this.offsetUpdate = this.offsetUpdate.bind(this);
        this.coefficientUpdate = this.coefficientUpdate.bind(this);
        this.enable_notify_ble_characteristic();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.values = [];
    }
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    handleNotifications(event) {
        let value = event.target.value;
        //ignore 0 byte messages
        if (value.byteLength) {
            this.values.push(value);
            this.setState({
                values: [...this.state.values, this.state.typeHandler(value)],
                loading: false,
                disabled: false,
            });
        }
    }
    downloadCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        let csvContent = `data:text/csv;charset=utf-8,${this.state.csvHeader
            .join(",")
            .trim()}\n${this.state.values
            .map((e) => e.csv.join(","))
            .join("\n")
            .trim()}`;
        //TODO Philipp add buoy name in file download
        let filename = `BUOY_NAME_${this.state.name
            .toLowerCase()
            .replace(" ", "_")}_${moment
            .utc()
            .format("YYYY-MM-DD_HH:mm:ss_z")}.csv`;
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }

    async readValueCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        /* Get the picture resolution and quality parameters. */
        let offset = this.state.calibrationOffset * MICRO_FACTOR;
        let coefficient = this.state.calibrationCoefficient * MICRO_FACTOR;
        /* If all is good lets set the control to loading and clear any error messages. */
        this.setState({
            loading: true,
            disabled: true,
            errors: {
                ...this.state.errors,
                calibrationOffset: "",
                calibrationCoefficient: "",
            },
        });
        /* Clear old values. */
        this.values = [];
        /* Build a parameters array. */
        let buffer = new ArrayBuffer(CALIBRATION_PARAMETERS_ARRAY_SIZE);
        let dataview = new DataView(buffer, 0);
        dataview.setInt32(OFFSET_PARAMETERS_ARRAY_OFFSET, offset, true);
        dataview.setInt32(
            COEFFICIENT_PARAMETERS_ARRAY_OFFSET,
            coefficient,
            true
        );
        /* Finally, request the picture with these values. */
        await this.state.characteristic.writeValueWithResponse(buffer);
        console.log(`read ${this.state.name}'s readings`);
    }
    async enable_notify_ble_characteristic() {
        try {
            await this.state.characteristic.startNotifications();
            this.state.characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotifications
            );
            if (this.state.requestOnConnect) {
                await this.state.characteristic.readValue();
            }
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }

    offsetUpdate(event) {
        this.setState({
            calibrationOffset: parseFloat(event.target.value),
        });
    }

    coefficientUpdate(event) {
        this.setState({
            calibrationCoefficient: parseFloat(event.target.value),
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Accordion
                        className={classes.accordion}
                        expanded={this.state.expanded}
                        onChange={this.accordianToggle}
                    >
                        <AccordionSummary
                            className={classes.space_between}
                            expandIcon={<ExpandMore />}
                            id={this.state.uuid}
                        >
                            <div className={classes.flex_div100}>
                                <Typography
                                    sx={{ width: "30%", flexShrink: 0 }}
                                >
                                    {this.state.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        width: "50%",
                                        color: "text.secondary",
                                    }}
                                >
                                    {
                                        this.state.values?.[
                                            this.state.values?.length - 1
                                        ]?.value
                                    }
                                </Typography>
                                <div className={classes.flex_div}>
                                    <TextField
                                        required
                                        id="calibration-offset"
                                        value={this.state.calibrationOffset}
                                        label="Offset (V)"
                                        variant="filled"
                                        type="number"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "0.0001",
                                        }}
                                        onChange={this.offsetUpdate}
                                        helperText={
                                            this.state.errors
                                                .calibrationOffset &&
                                            this.state.errors.calibrationOffset
                                        }
                                        error={
                                            !!this.state.errors
                                                .calibrationOffset
                                        }
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    <TextField
                                        required
                                        id="calibration-coefficient"
                                        value={
                                            this.state.calibrationCoefficient
                                        }
                                        label="Coefficient (ug/L)"
                                        variant="filled"
                                        type="number"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "0.0001",
                                        }}
                                        onChange={this.coefficientUpdate}
                                        helperText={
                                            this.state.errors
                                                .calibrationCoefficient &&
                                            this.state.errors
                                                .calibrationCoefficient
                                        }
                                        error={
                                            !!this.state.errors
                                                .calibrationCoefficient
                                        }
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    {this.state.loading && (
                                        <CircularProgress
                                            color="secondary"
                                            size={30}
                                        />
                                    )}
                                    <Chip
                                        icon={<Sync />}
                                        label="Refresh"
                                        onClick={this.readValueCallback}
                                        disabled={
                                            !this.props.connected ||
                                            this.state.disabled
                                        }
                                        color="success"
                                    />
                                    <Chip
                                        icon={<CloudDownload />}
                                        label="Download"
                                        onClick={this.downloadCallback}
                                        color="success"
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow className={classes.tabel_row}>
                                            {this.state.csvHeader.map((v) => (
                                                <TableCell key={this.getKey()}>
                                                    {v}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.values.map((v, i) => (
                                            <TableRow
                                                className={
                                                    i ===
                                                    this.state.values.length - 1
                                                        ? ""
                                                        : classes.tabel_row
                                                }
                                                key={this.getKey()}
                                            >
                                                {v.csv.map((cell) => (
                                                    <TableCell
                                                        key={this.getKey()}
                                                    >
                                                        {cell}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(BluetoothFluorometer2Characteristic);
