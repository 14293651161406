const decimalCoordinatePattern = /^-?[\d]*.[\d]*$/gm;
const latDMSRegex =
    /^\b([0-9]|[1-8][0-9]|90)\b°\b([0-9]|[1-5][0-9])\b'\b([0-9]|[1-5][0-9])\b" [NS]$/;
const longDMSRegex =
    /^\b([0-9]|[1-9][0-9]|1[0-7][0-9]|180)\b°\b([0-9]|[1-5][0-9])\b'\b([0-9]|[1-5][0-9])\b" [EW]$/;
const latLongDMSRegex =
    /^\b([0-9]|[1-9][0-9]|1[0-7][0-9]|180)\b°\b([0-9]|[1-5][0-9])\b'\b([0-9]|[1-5][0-9])\b" [NEWS]$/;

// Regex reference for decimal values in a range: https://www.regextutorial.org/regex-for-numbers-and-ranges.php
// Reference for values range : https://astro.unl.edu/naap/motion1/tc_units.html | https://www.fcc.gov/media/radio/dms-decimal

export const latPlaceholder = `36°57'9" N`;
export const longPlaceholder = `110°4'59" W`;

/**
 *
 * @param {Number} degrees
 * @param {Number} minutes
 * @param {Number} seconds
 * @param {String} direction "N", "S", "E", "W
 * @returns
 */
export function convertDMSToDD(degrees, minutes, seconds, direction) {
    if (
        direction !== "N" &&
        direction !== "E" &&
        direction !== "S" &&
        direction !== "W"
    ) {
        console.error("Invalid direction");
    }

    if (isNaN(seconds)) {
        seconds = 0;
    }

    if (degrees < 0 || degrees > 180) {
        console.error("Invalid degrees");
    } else if (minutes > 60 || minutes < 0) {
        console.error("Invalid minutes");
    } else if (seconds > 60 || seconds < 0) {
        console.error("Invalid seconds");
    }

    var dd = degrees + minutes / 60 + seconds / (60 * 60);

    if (direction === "S" || direction === "W") {
        dd = dd * -1;
    } // Don't do anything for N or E
    return dd;
}

export function parseDMS(input) {
    // Do not parse if input is already a number
    if (!isNaN(input)) {
        return input;
    }

    // Do not parse if input is empty or invalid, return 0
    if (
        input === undefined ||
        input === null ||
        input === "" ||
        !isValidLatLongDMSCoordinate(input)
    ) {
        return 0;
    }

    //Remove white space
    input = input.trim();

    let { degrees, input: minInput } = degreeSplit(input);
    let { minutes, input: secInput } = minutesSplit(minInput);
    let { seconds, input: dirInput } = secondsSplit(secInput);
    let direction = directionSplit(dirInput);
    return convertDMSToDD(
        parseFloat(degrees.trim()),
        parseFloat(minutes.trim()),
        parseFloat(seconds.trim()),
        direction.trim()
    );
}

function degreeSplit(input) {
    if (input.includes("°")) {
        return { degrees: input.split("°")[0], input: input.split("°")[1] };
    } else {
        return {
            degrees: input.split(" ")[0],
            input: input.substring(input.indexOf(" ") + 1),
        };
    }
}

function minutesSplit(input) {
    if (input.includes("'")) {
        return { minutes: input.split("'")[0], input: input.split("'")[1] };
    } else {
        if (!hasNumber(input)) {
            return { minutes: "0", input: input };
        }
        return {
            minutes: input.split(" ")[0],
            input: input.substring(input.indexOf(" ") + 1),
        };
    }
}

function secondsSplit(input) {
    if (input.includes('"')) {
        return { seconds: input.split('"')[0], input: input.split('"')[1] };
    } else {
        if (!hasNumber(input)) {
            return { seconds: "0", input: input };
        }
        return {
            seconds: input.split(" ")[0],
            input: input.substring(input.indexOf(" ") + 1),
        };
    }
}

function hasNumber(myString) {
    return /\d/.test(myString);
}

function directionSplit(input) {
    return input.trim();
}

/** Functions to convert lat and long values from decimal to DMS format */

/* source  : https://stackoverflow.com/questions/37893131/how-to-convert-lat-long-from-decimal-degrees-to-dms-format */
/** Validated using https://www.latlong.net/lat-long-dms.html 
// Formula adapted from 
// https://www.rapidtables.com/convert/number/degrees-to-degrees-minutes-seconds.html
/** Input  36.9525 */
/** Output 36°57'9" */
export function toDegreesMinutesAndSeconds(coordinate) {
    if (isNaN(coordinate) || coordinate === "") {
        console.error("Given coordinate value is not a number: " + coordinate);
        console.error(`Returning xx°xx'x"`);
        return `xx°xx'x"`;
    }

    if (typeof coordinate === "string") {
        coordinate = parseFloat(coordinate);
    }

    const absDecimal = Math.abs(coordinate);
    const degrees = Math.floor(absDecimal);
    const decimalPart = absDecimal - degrees;
    let minutes = Math.floor(decimalPart * 60);
    let seconds = Math.round((decimalPart * 60 - minutes) * 60);
    if (seconds === 60) {
        minutes++;
        seconds = 0;
    }

    return degrees + "°" + minutes + "'" + seconds + '"';
}

/**
 * Converts a decimal degree value to degrees and decimal minutes.
 *
 * @param {number} decimalDegrees - The decimal degree value to convert.
 * @param {boolean} isLat - Flag that indicates if the decimal degree is latitude or longitude
 * @returns {string} The converted value in degrees and decimal minutes format.
 *
 * @example
 * var decimalDegrees = 43.7255;
 * var degreesMinutes = convertToDegreesMinutes(decimalDegrees);
 * console.log(degreesMinutes); // Output: "43° 43.53' N"
 */
export function convertToDegreesAndDecimalMinutes(decimalDegrees, isLat) {
    var isNegative = decimalDegrees < 0;
    var degrees = Math.floor(Math.abs(decimalDegrees));
    var decimalMinutes = (Math.abs(decimalDegrees) - degrees) * 60;
    var minutes = decimalMinutes.toFixed(2);

    var formattedDegrees = degrees + "° " + minutes + "'";

    if (isLat) {
        formattedDegrees += isNegative ? " S" : " N";
    } else {
        formattedDegrees += isNegative ? " W" : " E";
    }

    return formattedDegrees;
}

/**
 *
 * @param {number} lat
 * @param {number} lng
 * @returns  string [62°59'57" N, 12°0'4" W]
 */
export function convertDMS(lat, lng) {
    let latitude = toDegreesMinutesAndSeconds(lat);
    let latitudeCardinal = lat >= 0 ? "N" : "S";

    let longitude = toDegreesMinutesAndSeconds(lng);
    let longitudeCardinal = lng >= 0 ? "E" : "W";

    return [
        latitude + " " + latitudeCardinal,
        longitude + " " + longitudeCardinal,
    ];
}

/**
 * This function takes in a decimal value for latitude and returns a dms string
 * @param {number} lat
 * @returns string 62°59'57" N
 */
export function convertToDMSLat(lat) {
    let latitude = toDegreesMinutesAndSeconds(lat);
    let latitudeCardinal = lat >= 0 ? "N" : "S";
    return latitude + " " + latitudeCardinal;
}

/**
 * This function takes in a decimal value for longitude and returns a dms string
 * @param {number} lng
 * @returns string 12°0'4" W
 */
export function convertToDMSLong(lng) {
    let longitude = toDegreesMinutesAndSeconds(lng);
    let longitudeCardinal = lng >= 0 ? "E" : "W";
    return longitude + " " + longitudeCardinal;
}

export function convertToDDString(lat, long) {
    return lat.toFixed(2) + ", " + long.toFixed(2);
}

export function convertToDMSString(lat, long) {
    return "[" + lat + ", " + long + "]";
}
export function getFormattedCoords(lat, long) {
    // Convert to DMS format if lat and lng are in decimal format
    if (isValidDecimalCoordinate(lat, true)) {
        lat = convertToDMSLat(lat);
    }

    if (isValidDecimalCoordinate(long, false)) {
        long = convertToDMSLong(long);
    }
    return { lat, long };
}

export function isValidDecimalCoordinate(coordinate) {
    if (isNaN(coordinate)) {
        return (
            coordinate && coordinate.match(decimalCoordinatePattern).length > 0
        );
    } else return true;
}

export function isValidDMSCoordinate(coordinate, lat) {
    if (!coordinate) {
        return false;
    }

    // return true if coordinate is not a string
    if (typeof coordinate !== "string") {
        return true;
    }

    if (lat) {
        if (coordinate.match(latDMSRegex)) {
            return true;
        }
    } else {
        if (coordinate.match(longDMSRegex)) {
            return true;
        }
    }
    return false;
}

export function isDecimalCoordinateWithinRange(decimalCoordianate, lat) {
    decimalCoordianate = parseFloat(decimalCoordianate);

    // if the input is not a number return false
    if (isNaN(decimalCoordianate)) return false;

    if (lat) return -90 <= decimalCoordianate && decimalCoordianate <= 90;

    return -180 <= decimalCoordianate && decimalCoordianate <= 180;
}

function isValidLatLongDMSCoordinate(coordinate) {
    if (!coordinate) {
        return false;
    }

    // return false if coordinate is not a string
    if (typeof coordinate !== "string") {
        return false;
    }

    if (coordinate.match(latLongDMSRegex)) {
        return true;
    }
    return false;
}
