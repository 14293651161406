import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import MapComponent from "./MapComponent";
import formatcoords from "formatcoords";
import randomColor from "randomcolor";

class FullKelpMap extends Component {
    processGeoGraphData(buoys) {
        if (buoys == null) {
            return null;
        }
        return buoys
            .map((thing) =>
                thing.datastreams
                    .filter(
                        (datastream) =>
                            datastream.name === "GPS" ||
                            datastream.name === "latitude"
                    )
                    .map((datastream) =>
                        datastream.sensorObservations.map((observation) => {
                            const [lng, lat] = observation.resultLocation;

                            const description = `${
                                thing.name
                            } <br> ${formatcoords(lat, lng).format()}<br>${[
                                lng,
                                lat,
                            ]} <br>${observation.resultTime} (UTC)`;

                            return {
                                ds_name: "latitude",
                                coordinates: [[lng, lat]],
                                ds_id: datastream.id,
                                foi_desc: thing.name,
                                date_time: [observation.phenomenonTime],
                                foi_name: "Kelp Buoys",
                                unit: "GPS Point",
                                op_name: "buoy_location",
                                thing_name: thing.name,
                                subType: "default",
                                color: randomColor({
                                    luminosity: "dark",
                                    format: "hex",
                                    seed: datastream.id + "buoy_location",
                                }),
                                geoJson: {
                                    type: "geojson",
                                    data: {
                                        type: "FeatureCollection",
                                        features: [
                                            {
                                                type: "Feature",
                                                properties: {
                                                    description,
                                                },
                                                geometry: {
                                                    type: "Point",
                                                    coordinates: [lng, lat],
                                                },
                                            },
                                        ],
                                    },
                                },
                                earliestStartDate: observation.resultTime,
                            };
                        })
                    )
            )
            .flat(2);
    }

    render() {
        const datasets = this.processGeoGraphData(this.props.buoys);
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {datasets ? (
                        <MapComponent
                            picker={false}
                            addPoints={true}
                            datasets={datasets}
                            // TODO(hannah): update is only called if picker is
                            // true. Make this relationship explicit.
                            update={() => {}}
                            showLegend={false}
                        />
                    ) : (
                        <h1>Loading </h1>
                    )}
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        );
    }
}

export default FullKelpMap;
