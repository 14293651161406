import React, { Component } from "react";
import { Container, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
});

class BluetoothSensorCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            requestOnConnect: props.requestOnConnect,
            typeHandler: props.typeHandler,
            csvHeader: props.csvHeader,
            values: [],
            loading: false,
            disabled: false,
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.enable_notify_ble_characteristic();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.values = [];
    }
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    handleNotifications(event) {
        let value = event.target.value;
        //ignore 0 byte messages
        if (value.byteLength) {
            console.log(`updated value: ${value}`);
            this.values.push(value);
            this.setState({
                values: [...this.state.values, this.state.typeHandler(value)],
                loading: false,
                disabled: false,
            });
            this.props.delegate(this.state.typeHandler(value));
        }
    }
    async enable_notify_ble_characteristic() {
        try {
            await this.state.characteristic.startNotifications();
            this.state.characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotifications
            );
            if (this.state.requestOnConnect) {
                await this.state.characteristic.readValue();
            }
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Container>
                        <Typography>
                            {`${this.state.name} ${
                                typeof this.state.values?.[
                                    this.state.values?.length - 1
                                ]?.value == "boolean"
                                    ? this.state.values?.[
                                          this.state.values?.length - 1
                                      ]?.value
                                        ? "Enabled"
                                        : "Disabled"
                                    : this.state.values?.[
                                          this.state.values?.length - 1
                                      ]?.value
                            }`}
                        </Typography>
                    </Container>
                </div>
            </Paper>
        );
    }
}

export { BluetoothSensorCharacteristic as BluetoothSensorCharacteristicWithoutStyles };
export { styles };

export default withStyles(styles)(BluetoothSensorCharacteristic);
