// src/App.jsx

import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppWithRouterAccess from "../AppWithRouterAccess";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

class App extends Component {
    render() {
        const queryClient = new QueryClient();
        return (
            <QueryClientProvider client={queryClient}>
                <Router>
                    <AppWithRouterAccess />
                </Router>
            </QueryClientProvider>
        );
    }
}

export default App;
