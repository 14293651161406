import React, { Component } from "react";
import PropTypes from "prop-types";
import APIClient from "../../../models/APIClient";
import moment from "moment-timezone";
import GraphComponentWithoutData from "./GraphComponentWithoutData";

class GraphComponentWithData extends Component {
    constructor(props) {
        super(props);
        this.foi_id =
            props.foi_id ||
            (props.match ? props.match.params.foi_id : undefined);
        this.state = {
            graphName: "Loading...",
            graphDesc: "Loading...",
            latestNumeric: [],
            latestGeo: [],
            selectedTimezone: this.props.selectedTimezone
                ? this.props.selectedTimezone
                : "utc",
        };
        this.loadNewDataset = this.loadNewDataset.bind(this);
    }

    async componentDidMount() {
        await this.loadNewDataset(
            moment().subtract(1, "weeks"),
            moment(),
            null,
            true
        );
    }

    async loadNewDataset(from, to, unit, initialLoad = false) {
        let result = null;
        let graphName = "";
        let graphDesc = "";
        // The set of data which is passed to render numeric graphs
        let latestNumeric = this.state.latestNumeric.slice();

        if (this.props.thingSelector !== undefined) {
            const apiClient = new APIClient();
            const things = await apiClient.getThingAll(
                this.props.thingSelector,
                null,
                from,
                to
            );
            const configs = await apiClient.getBuoyInstalledConfigs(
                this.props.thingSelector
            );

            const start = moment(from);
            const end = moment(to);
            let fullData = [];
            let datastreamCount = 0;
            things.forEach((thing) => {
                thing.datastreams.forEach((datastream) => {
                    const numericData = datastream.sensorObservations
                        .filter(
                            (data) =>
                                moment(data.phenomenonTime) >= start &&
                                moment(data.phenomenonTime) <= end
                        )
                        .map((data) => ({
                            dateTime: moment(data.phenomenonTime),
                            resultNumeric: data.resultNumeric,
                        }));

                    numericData.sort((a, b) => a.dateTime - b.dateTime);

                    // Add datapoints for when the results came in, creating a
                    // timeline.
                    if (
                        !this.props.hideTimeline &&
                        (unit == null || unit === "time")
                    ) {
                        fullData.push({
                            date_time: numericData.map((d) => d.dateTime),
                            ds_id: thing.id,
                            ds_name: datastream.name,
                            op_name: datastream.name,
                            op_id: datastream.id,
                            op_unit: "time",
                            result_numeric: numericData.map(
                                () => datastreamCount
                            ),
                            thing_name: "Line at " + datastreamCount,
                            labels: "time",
                        });
                        datastreamCount += 1;
                    }

                    // Add datapoints for the sensor reading themselves.
                    if (unit == null || unit === datastream.unitOfMeasurement) {
                        fullData.push({
                            date_time:
                                this.state.selectedTimezone == "utc"
                                    ? numericData.map((d) =>
                                          d.dateTime
                                              .utc()
                                              .format("YYYY-MM-DD HH:mm:ss")
                                      )
                                    : numericData.map((d) =>
                                          moment
                                              .tz(d.dateTime, moment.tz.guess())
                                              .format("YYYY-MM-DD HH:mm:ss")
                                      ),
                            ds_id: thing.id,
                            ds_name: datastream.name,
                            op_name: datastream.name,
                            op_id: datastream.id,
                            op_unit: datastream.unitOfMeasurement,
                            result_numeric: numericData.map(
                                (d) => d.resultNumeric
                            ),
                            thing_name: thing.name,
                        });
                    }
                });
            });

            // Add timeline datapoints for config updates.
            if ((unit == null || unit === "time") && configs.length > 0) {
                const dateTimes = configs
                    .map((d) => moment(d.dateInstalled))
                    .filter((date) => date >= start && date <= end);
                fullData.push({
                    date_time: dateTimes,
                    ds_id: this.props.thingSelector,
                    ds_name: "Config Update",
                    op_name: "Config Update",
                    op_id: configs[0].id,
                    op_unit: "time",
                    // Always display config updates as the top line.
                    result_numeric: dateTimes.map(() => datastreamCount),
                    thing_name: "Line at " + datastreamCount,
                    labels: "time",
                });
            }

            // If the data is for a single buoy, use its name in the
            // description. Otherwise, use the thingSelector.
            const buoyDescriptor =
                things.length === 1 ? things[0].name : this.props.thingSelector;

            result = fullData;
            graphName = ``;
            graphDesc = this.props.hideTimeline
                ? `Sensor data for ${buoyDescriptor}`
                : ``;

            // Updates the numeric data to display, replacing it in place (to preserve graph order)
            // if the new result provides changed data for an existing datastream
            // or simply apppending if the datastream doesn't exist yet (should only be the case
            // on the initial load).
            // Filtering out GPS, image, & buoy update
            result
                .filter(
                    (val) =>
                        val.op_unit !== "GPS Point" &&
                        val.op_unit !== "webp" &&
                        val.op_unit !== "generic" &&
                        val.op_unit !== "bool"
                )
                .forEach((data) => {
                    const index = this.state.latestNumeric.findIndex(
                        (elem) =>
                            elem.op_name === data.op_name &&
                            elem.op_unit === data.op_unit &&
                            elem.ds_id === data.ds_id
                    );
                    index >= 0
                        ? (latestNumeric[index] = data)
                        : latestNumeric.push(data);
                });
        } else {
            const requestObj = {
                foi_id: this.foi_id,
                from: from,
                to: to,
                limit: 30,
            };

            if (unit) {
                requestObj.unit = unit;
            }

            result = await new APIClient().getFOIData(requestObj);

            if (result != null && result.length > 0) {
                graphName = `Data for feature of interest: ${result[0].foi_name}`;
                graphDesc = result[0].foi_desc;
            }
            // Replace or add to latestNumeric
            result.forEach((dsResult) => {
                const dsIndex = latestNumeric.findIndex(
                    (ln) => ln.ds_id === dsResult.ds_id
                );
                dsIndex >= 0
                    ? (latestNumeric[dsIndex] = dsResult)
                    : latestNumeric.push(dsResult);
            });
        }
        const latestGeo = unit
            ? this.state.latestGeo.slice()
            : result.filter(
                  (g) =>
                      g.result_location &&
                      g.result_location.length &&
                      g.result_location[0] !== null
              );
        initialLoad &&
            this.setState({
                alwaysDisplayMap: latestGeo.length,
            });
        this.setState({
            graphName,
            graphDesc,
            latestNumeric,
            latestGeo,
        });
    }

    render() {
        return (
            // Parse all props for specific settings like controling timeline
            // display order,legend display and such
            <GraphComponentWithoutData
                legendPos={this.props.legendPos}
                addToggleToLegend={this.props.addToggleToLegend}
                timelineAtBottom={this.props.timelineAtBottom}
                cappedGraphHeight={this.props.cappedGraphHeight}
                name={this.state.graphName}
                description={this.state.graphDesc}
                geoGraphData={this.state.latestGeo}
                numericGraphData={this.state.latestNumeric}
                loadNewDataset={this.loadNewDataset}
                splitByUnit={this.props.splitByUnit}
                size={this.props.size}
                picker={this.props.picker}
                alwaysDisplayMap={this.state.alwaysDisplayMap}
            />
        );
    }
}

GraphComponentWithData.propTypes = {
    foi_id: PropTypes.string,
    thingSelector: PropTypes.string,
    hideTimeline: PropTypes.bool,
    splitByUnit: PropTypes.bool,
    size: PropTypes.string,
    picker: PropTypes.bool,
    timelineAtBottom: PropTypes.bool,
    legendPos: PropTypes.string,
    addToggleToLegend: PropTypes.bool,
    cappedGraphHeight: PropTypes.bool,
};

export default GraphComponentWithData;
