import React, { useState, useEffect } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import RTable from "./RTable";
import APIClient from "../../models/APIClient";

import { FirmwareBuoyProtocols } from "@running-tide/firmware-buoy-protocols";
import moment from "moment-timezone";

function BuoyTestSuiteResults(props) {
    const { thingId } = props;

    const [buoyTests, setBuoyTests] = useState([]);
    const [suiteRunTimestamp, setSuiteRunTimestamp] = useState("");
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const fetchBuoyTestResults = async () => {
            const apiClient = new APIClient();
            const buoySuiteRun =
                await apiClient.getLatestTestSuiteResultsForBuoy(thingId);
            if (buoySuiteRun?.buoyTestRuns?.length && buoySuiteRun.run_time) {
                setDisplay(true);
                setSuiteRunTimestamp(
                    moment(buoySuiteRun.run_time).format("YYYY-MM-DD HH:mm:ss")
                );
                setBuoyTests(buoySuiteRun.buoyTestRuns);
            }
        };
        fetchBuoyTestResults();
    }, [thingId]);

    function getTestTypeStringFromID(testTypeId) {
        return Object.keys(FirmwareBuoyProtocols.TestType).find(
            (key) => FirmwareBuoyProtocols.TestType[key] === testTypeId
        );
    }

    return display ? (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RTable
                    title={
                        <div>
                            <Typography variant="h3">Buoy Tests</Typography>
                            <Typography variant="h4">
                                Results from latest run: {suiteRunTimestamp}
                            </Typography>
                        </div>
                    }
                    columns={[
                        {
                            title: "Test",
                            field: "buoyTestTypeId",
                            render: (rowData) =>
                                getTestTypeStringFromID(rowData.buoyTestTypeId),
                        },
                        {
                            title: "Status",
                            field: "success",
                            render: (rowData) => {
                                return rowData.success ? (
                                    <Tooltip
                                        title={"Successful Test"}
                                        placement="right"
                                    >
                                        <CheckCircleIcon color="primary" />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title={"Failed Test"}
                                        placement="right"
                                    >
                                        <ErrorIcon color="error" />
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            title: "Message",
                            field: "message",
                            render: (rowData) =>
                                `${rowData.message} - ${rowData.metadata}`,
                        },
                    ]}
                    data={buoyTests}
                    options={{
                        search: true,
                        filtering: false,
                        paging: false,
                        idSynonym: "test",
                    }}
                ></RTable>
            </Grid>
        </Grid>
    ) : null;
}
export default BuoyTestSuiteResults;
