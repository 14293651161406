import React, { Component } from "react";
import {
    Tabs,
    Tab,
    AppBar,
    Grid,
    Chip,
    Stack,
    Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";

import History from "./KelpHistory";
import Control from "./components/BuoyControlComponent";
import BuoyDataRequest from "./components/BuoyDataRequest";
import APIClient from "../models/APIClient";
import { hasValidPermissions } from "./components/RequirePermissions";
import {
    VERIFICATION_BUOYS,
    Action,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import { useCurrentUser } from "../hooks/useCurrentUser";

class BuoysHistoryAndControlManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "detail",
            selectorLabels: [],
        };
    }

    async componentDidMount() {
        const apiClient = new APIClient();
        const rawBuoyData = await apiClient.getThingAllNoObservations(
            this.props.params.thing_id
        );
        const buoyDescriptor =
            rawBuoyData.length === 1
                ? rawBuoyData[0].name
                : this.props.thingSelector;
        try {
            this.setState({
                selectorLabels: rawBuoyData[0].selectorLabels,
                buoyName: buoyDescriptor,
            });
        } catch (error) {
            console.error("Failed to fetch selectorLabels: ", error);
        }
    }
    render() {
        // Logic og what tabs to show based on what tags and permissions
        let tabs = [
            {
                value: "detail",
                key: "detail",
                label: "Detail",
            },
        ];
        if (
            hasValidPermissions(this.props.currentUser, [
                `${VERIFICATION_BUOYS}:${Action.CREATE}`,
                `${VERIFICATION_BUOYS}:${Action.UPDATE}`,
            ]) &&
            this.state.selectorLabels.includes("rt-buoy") &&
            !this.state.selectorLabels.includes("ble-buoy")
        ) {
            tabs = tabs.concat([
                {
                    value: "control",
                    key: "control",
                    label: "Control",
                },
                {
                    value: "data-request",
                    key: "data-request",
                    label: "Data Request",
                },
            ]);
        }
        if (
            hasValidPermissions(this.props.currentUser, [
                `${VERIFICATION_BUOYS}:${Action.CREATE}`,
                `${VERIFICATION_BUOYS}:${Action.UPDATE}`,
            ]) &&
            this.state.selectorLabels.includes("ble-buoy")
        ) {
            tabs = tabs.concat([
                {
                    value: "control",
                    key: "control",
                    label: "Control",
                },
            ]);
        }

        //Add tab designs
        if (tabs.length === 1) {
            tabs[0].variant = "single";
        } else {
            tabs[0].variant = "leftmost";
            tabs[tabs.length - 1].variant = "rightmost";
        }
        tabs = tabs.map((attr) => <Tab {...attr} key={attr.key} />);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2">
                                {this.state.buoyName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="h6">Selectors:</Typography>
                                {this.state.selectorLabels.map((label) => (
                                    <Chip
                                        label={label}
                                        key={label}
                                        color="success"
                                    />
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AppBar position="static">
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={(event, newValue) =>
                                this.setState({ selectedTab: newValue })
                            }
                            aria-label="Buoy History and Config Tabs"
                        >
                            {tabs}
                        </Tabs>
                    </AppBar>
                </Grid>
                {this.state.selectedTab === "detail" && (
                    <Grid item xs={12}>
                        <History {...this.props} />
                    </Grid>
                )}
                {this.state.selectedTab === "control" && (
                    <Grid item xs={12}>
                        <Control
                            selectorLabels={this.state.selectorLabels}
                            {...this.props}
                        />
                    </Grid>
                )}
                {this.state.selectedTab === "data-request" && (
                    <Grid item xs={12}>
                        <BuoyDataRequest {...this.props} />
                    </Grid>
                )}
            </Grid>
        );
    }
}

function BuoysHistoryAndControlManagerPage(props) {
    const { data: user } = useCurrentUser();
    return (
        <BuoysHistoryAndControlManager
            {...props}
            params={useParams()}
            currentUser={user}
        />
    );
}

export default BuoysHistoryAndControlManagerPage;
