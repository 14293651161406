import { MAP_COLORS } from "../../../config/theme";
import MAP_ELEMENT_ENUM from "../../../models/enums/MapElementsEnum";

export const recenterButtonBackgroundImage = `url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPgo8c3ZnIHdpZHRoPSIyOSIgaGVpZ2h0PSIyOSIgdmlld0JveD0iMCAwIDMyIDMyIiBpZD0iaWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzAwMDAwMDt9LmNscy0ye2ZpbGw6bm9uZTt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmNlbnRlci0tY2lyY2xlPC90aXRsZT48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0zMCwxNUgyNy45NDkyQTEyLjAwNzEsMTIuMDA3MSwwLDAsMCwxNyw0LjA1MDhWMkgxNVY0LjA1MDhBMTIuMDA3MSwxMi4wMDcxLDAsMCwwLDQuMDUwOCwxNUgydjJINC4wNTA4QTEyLjAwNzEsMTIuMDA3MSwwLDAsMCwxNSwyNy45NDkyVjMwaDJWMjcuOTQ5MkExMi4wMDcxLDEyLjAwNzEsMCwwLDAsMjcuOTQ5MiwxN0gzMFpNMTcsMjUuOTQ5MlYyMkgxNXYzLjk0OTJBMTAuMDE2NiwxMC4wMTY2LDAsMCwxLDYuMDUwOCwxN0gxMFYxNUg2LjA1MDhBMTAuMDE2NiwxMC4wMTY2LDAsMCwxLDE1LDYuMDUwOFYxMGgyVjYuMDUwOEExMC4wMTY2LDEwLjAxNjYsMCwwLDEsMjUuOTQ5MiwxNUgyMnYyaDMuOTQ5MkExMC4wMTY2LDEwLjAxNjYsMCwwLDEsMTcsMjUuOTQ5MloiLz48cmVjdCBpZD0iX1RyYW5zcGFyZW50X1JlY3RhbmdsZV8iIGRhdGEtbmFtZT0iJmx0O1RyYW5zcGFyZW50IFJlY3RhbmdsZSZndDsiIGNsYXNzPSJjbHMtMiIgd2lkdGg9IjI5IiBoZWlnaHQ9IjI5Ii8+PC9zdmc+)`;
export const downloadButtonBackgroundImage = `url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMCAxNVYxOEMyMCAxOS4xMDQ2IDE5LjEwNDYgMjAgMTggMjBINkM0Ljg5NTQzIDIwIDQgMTkuMTA0NiA0IDE4TDQgMTVNOCAxMUwxMiAxNU0xMiAxNUwxNiAxMU0xMiAxNVYzIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMS41IiANCnN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+)`;
const legendControlClass = "mapbox-gl-legend-control";
const legendControlContrainerClass = "mapboxgl-ctrl-top-left";

export function downloadImage() {
    var img = this.map.getCanvas().toDataURL("image/png");
    var a = document.createElement("a");
    a.href = img;
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

// This method is used to find the mean lat and mean long of a set of points
export function findMeanOfPoints(pointsArray) {
    let sumLat = 0;
    let sumLon = 0;

    for (const point of pointsArray) {
        sumLat += parseFloat(point[0] || point.lat);
        sumLon += parseFloat(point[1] || point.lon);
    }

    const meanLat = sumLat / pointsArray.length;
    const meanLon = sumLon / pointsArray.length;

    return [meanLat, meanLon];
}

// This method is used to sort the points into quadrants
// The quadrants are sorted as follows:
// 0 | 1
// -----
// 3 | 2
// This is done to ensure that the points are sorted in a clockwise fashion
// This is important for the convex hull algorithm - and to ensure that the
// Polygon that is rendered is not self-intersecting
export function sortLocationDecisionPoints(pointsArray) {
    const meanLatLon = findMeanOfPoints(pointsArray);

    const meanLat = meanLatLon[0];
    const meanLon = meanLatLon[1];

    // Find points in each quadrant with respect to the mean point
    const quadrants = [[], [], [], []];

    for (const point of pointsArray) {
        if (point[0] >= meanLat && point[1] >= meanLon) {
            quadrants[1].push(point);
        } else if (point[0] >= meanLat && point[1] < meanLon) {
            quadrants[0].push(point);
        } else if (point[0] < meanLat && point[1] < meanLon) {
            quadrants[3].push(point);
        } else if (point[0] < meanLat && point[1] >= meanLon) {
            quadrants[2].push(point);
        }
    }

    // In case more than one point lies within each quadrant, we need to sort them
    // in a clockwise fashion - To be verified
    for (const quadrant of quadrants) {
        quadrant.sort((a, b) => {
            // Handle edge case where points lie on the same latitude or longitude as the mean point
            if (a.latitude === b.latitude) {
                return a.longitude - b.longitude;
            } else {
                return a.latitude - b.latitude;
            }
        });
    }

    const sortedPoints = [];
    for (const quadrant of quadrants) {
        sortedPoints.push(...quadrant);
    }

    sortedPoints.push(sortedPoints[0]);

    return sortedPoints;
}

/* 
    This class lets us implement custom controls on the map
    Currently used for [mapRecenter]
 *  Idea from Stack Overflow https://stackoverflow.com/a/51683226
 *  and code pen : https://codepen.io/roblabs/pen/zJjPzX
 */
export class MapboxGLButtonControl {
    constructor({ className = "", title = "", eventHandler, backgroundImage }) {
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
        this._backgroundImage = backgroundImage;
    }

    onAdd() {
        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon " + this._className;
        this._btn.type = "button";
        this._btn.title = this._title;
        this._btn.onclick = this._eventHandler;

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.style.backgroundImage = this._backgroundImage;
        // no repeat
        this._container.style.backgroundRepeat = "no-repeat";
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

// This method is used to handle the scroll zoom blocker
export function handleScrollZoom() {
    const _this = this;

    const panBlockerMessage = document.querySelector(
        ".mapboxgl-touch-pan-blocker"
    );
    panBlockerMessage.style.display = "none";

    const zoomBlockMessage = document.querySelector(
        ".mapboxgl-scroll-zoom-blocker"
    );

    const newStyles = {
        position: "relative",
        zIndex: "2",
        textAlign: "center",
        top: "48%",
        opacity: "0",
        userSelect: "none",
        transition: "opacity 0.8s ease-in-out",
    };

    for (const styleProp of Object.entries(newStyles)) {
        zoomBlockMessage.style[styleProp[0]] = styleProp[1];
    }

    this.map.on("wheel", () => {
        if (_this.isZooming) {
            toggleScrollMessage(false);
        } else {
            toggleScrollMessage(true);
        }
    });

    this.map.on("mousemove", () => {
        toggleScrollMessage(false);
    });

    this.map.on("mouseleave", () => {
        toggleScrollMessage(false);
    });

    this.map.on("zoomstart", () => {
        _this.isZooming = true;
    });

    this.map.on("zoomend", () => {
        _this.isZooming = false;
    });
}

function toggleScrollMessage(show) {
    const zoomBlockMessage = document.querySelector(
        ".mapboxgl-scroll-zoom-blocker"
    );

    if (show) {
        zoomBlockMessage.style.display = "block";
        setTimeout(() => {
            zoomBlockMessage.style.opacity = "1";
        }, 500);
    } else {
        zoomBlockMessage.style.opacity = "0";
        setTimeout(() => {
            zoomBlockMessage.style.display = "none";
        }, 600);
    }
}

export function getColorForMapPointType(type) {
    switch (type) {
        case MAP_ELEMENT_ENUM.TRAJECTORY_BUOY:
            return MAP_COLORS.thingIndicator.trajectoryBuoy;
        case MAP_ELEMENT_ENUM.VERIFICATION_BUOY:
            return MAP_COLORS.thingIndicator.verificationBuoy;
        case MAP_ELEMENT_ENUM.VESSEL_GPS_TRACKER:
            return MAP_COLORS.thingIndicator.vessel;
        default:
            return MAP_COLORS.thingIndicator.verificationBuoy;
    }
}

export function getPulsingDotForColor(hexColor) {
    const size = 50;

    const _this = this;

    const rgbHex = hexColor.slice(1).match(/.{1,2}/g);
    const rgb = [
        parseInt(rgbHex[0], 16),
        parseInt(rgbHex[1], 16),
        parseInt(rgbHex[2], 16),
    ];

    return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
            const canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext("2d", {
                willReadFrequently: true,
            });
        },

        // called once before every frame where the icon will be used
        render: function () {
            var duration = 1000;
            var t = (performance.now() % duration) / duration;

            var radius = (size / 2) * 0.3;
            var outerRadius = (size / 2) * 0.7 * t + radius;
            var context = this.context;

            // draw outer circle
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                outerRadius,
                0,
                Math.PI * 2
            );
            context.fillStyle = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${
                1 - t
            })`;
            context.fill();

            // draw inner circle
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                radius,
                0,
                Math.PI * 2
            );

            // console.log(`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`);
            context.fillStyle = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
            context.strokeStyle = "rgba(100, 100, 100, 0)";
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            // update this image's data with data from the canvas
            this.data = context.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;

            // continuously repaint the map, resulting in the smooth animation of the dot

            _this.map.triggerRepaint();
            // return `true` to let the map know that the image was updated
            return true;
        },
    };
}

class MapLegendControl {
    constructor({ className = "", title = "" }) {
        this._className = className;
        this._title = title;
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement("div");
        this._container.className = this._className;
        const legendDiv = document.createElement("div");
        this._container.append(legendDiv);

        // Populate the lengend here
        // one box followed by one string
        const legendHtml = `
        <div>
            <div style="display: inline-block; background-color: ${MAP_COLORS.thingIndicator.vessel}; width: 10px; height: 10px;"></div>
            <span style="margin-left: 0.5rem;">Vessel GPS Tracker</span>
        </div>
        <div>
            <div style="display: inline-block; background-color: ${MAP_COLORS.thingIndicator.trajectoryBuoy}; width: 10px; height: 10px;"></div>
            <span style="margin-left: 0.5rem;">Trajectory Buoy</span>
        </div>
        <div>
            <div style="display: inline-block; background-color: ${MAP_COLORS.thingIndicator.verificationBuoy}; width: 10px; height: 10px;"></div>
            <span style="margin-left: 0.5rem;">Verification Buoy</span>
        </div>
        `;

        legendDiv.innerHTML = legendHtml;
        legendDiv.style.display = "flex";
        legendDiv.style.flexDirection = "column";
        legendDiv.style.gap = "0.5rem";

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

export function setUpLegendControl() {
    const _this = this;

    _this.map.addControl(
        new MapLegendControl({
            className: legendControlClass,
            title: "Map Legend",
        }),
        "top-left"
    );

    const legendControlContainer = document.getElementsByClassName(
        legendControlContrainerClass
    )[0];

    if (legendControlContainer) {
        legendControlContainer.style.padding = "1rem";
    }
}
