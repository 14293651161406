import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import RTable from "../components/RTable";
import moment from "moment-timezone";
import Link from "@mui/material/Link";
import BuoyStatus from "../helper/BuoyStatus";

const strToMoment = (time) =>
    moment(time, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz(moment.tz.guess());

const formatTime = (time) => strToMoment(time).format("YYYY-MM-DD HH:mm:ss z");
const ignoreBatteryLabels = ["trajectory-buoy", "gf-buoy"];
const firmwareBatteryLabels = ["accel-buoy"];
const standardWarnBatteryThreshold = 12.8;
const firmwareWarnBatteryThreshold = 3.7;
const standardErrBatteryThreshold = 12.5;
const firmwareErrBatteryThreshold = 3.4;

function VerificationBuoyStatusTable(props) {
    const [buoyData, setBuoyData] = useState([]);

    // On page load get all deployments and set the selected deployments to the one in the URL
    useEffect(() => {
        setBuoyData(latestData(props.buoys, props.buoysPowerStatus));
    }, [props.buoys, props.buoysPowerStatus]);

    const latestData = (buoys, buoysPowerStatus) => {
        if (buoys == null) {
            return [];
        }
        const updatedBuoyData = buoys.map((buoy) => {
            const allSensorObservations = buoy.datastreams
                .map((datastream) => datastream.sensorObservations)
                .flat();
            const gpsObservations = buoy.datastreams
                .filter(
                    (datastream) =>
                        datastream.name === "GPS" ||
                        datastream.name === "latitude"
                )
                .map((datastream) => datastream.sensorObservations)
                .flat();
            const buoyUpdates = buoy.datastreams
                .filter((datastream) => datastream.name === "Buoy Update")
                .map((datastream) => datastream.sensorObservations)
                .flat();
            const images = buoy.datastreams
                .filter((datastream) => datastream.name === "Image")
                .map((datastream) => datastream.sensorObservations)
                .flat();

            let ignoreBatteryHealth = false;
            let batteryThresholdErr = standardErrBatteryThreshold;
            let batteryThresholdWarn = standardWarnBatteryThreshold;
            for (const label of buoy.selectorLabels) {
                if (
                    !ignoreBatteryHealth &&
                    ignoreBatteryLabels.includes(label)
                ) {
                    ignoreBatteryHealth = true;
                }
                if (firmwareBatteryLabels.includes(label)) {
                    batteryThresholdErr = firmwareErrBatteryThreshold;
                    batteryThresholdWarn = firmwareWarnBatteryThreshold;
                }
            }

            const deactivated = buoy.selectorLabels.includes("deactivated");

            ignoreBatteryHealth = false;
            let imageCount = 0;
            if (buoy.datastreams.filter((d) => d.name === "Image").length > 0) {
                // Filter to each datastream of images. Then map to the sensorObservations array. Then flatten the array of arrays. Finally get the length
                imageCount = buoy.datastreams
                    .filter((d) => d.name === "Image")
                    .map((datastream) => {
                        return datastream.sensorObservations;
                    })
                    .flat().length;
            }

            return {
                name: buoy.name,
                lastContact: allSensorObservations
                    .map((so) => strToMoment(so.resultTime))
                    .reduce(
                        (a, b) => moment.max(a, b),
                        moment().subtract(1, "years")
                    )
                    .format("YYYY-MM-DD HH:mm:ss z"),
                gpsDisplay: gpsObservations.length
                    ? `${JSON.stringify(
                          gpsObservations[0].resultLocation
                      )} ${formatTime(gpsObservations[0].resultTime)}`
                    : "N/A",
                lastUpdate: buoyUpdates.length
                    ? formatTime(buoyUpdates[0].resultTime)
                    : "N/A",
                imageTime: images.length
                    ? formatTime(images[0].resultTime)
                    : "N/A",
                thingID: buoy.id,
                batteryReading:
                    buoysPowerStatus != null
                        ? buoysPowerStatus[buoy.id]?.batteryVolts
                        : "unknown",
                ignoreBatteryHealth,
                batteryThresholdErr,
                batteryThresholdWarn,
                gpsPointCount:
                    buoy.datastreams.filter((d) => d.name === "GPS").length > 0
                        ? buoy.datastreams.filter((d) => d.name === "GPS")[0]
                              .sensorObservations.length
                        : 0,
                photoCount: imageCount,
                deactivated,
            };
        });

        // Sort buoys by last contact.
        updatedBuoyData.sort((a, b) => a.lastContact - b.lastContact);
        return updatedBuoyData;
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <RTable
                    columns={[
                        {
                            title: "Status",
                            field: "name",
                            render: (rowData) => (
                                <BuoyStatus
                                    lastContact={rowData.lastContact}
                                    batteryReading={rowData.batteryReading}
                                    ignoreBatteryHealth={
                                        rowData.ignoreBatteryHealth
                                    }
                                    batteryThresholdWarn={
                                        rowData.batteryThresholdWarn
                                    }
                                    batteryThresholdError={
                                        rowData.batteryThresholdErr
                                    }
                                    deactivated={rowData.deactivated}
                                />
                            ),
                        },
                        {
                            title: "Buoy ID",
                            field: "name",
                            render: (rowData) => (
                                <Link href={`/kelp_buoy/${rowData.thingID}`}>
                                    {rowData.name}
                                </Link>
                            ),
                        },
                        {
                            title: "Last Contact",
                            field: "lastContact",
                            render: (rowData) =>
                                `${moment(
                                    rowData.lastContact,
                                    "YYYY-MM-DD HH:mm:ss z"
                                ).fromNow()}`,
                        },
                        {
                            title: "Last Contact",
                            field: "lastContact",
                        },
                        {
                            title: "Last Location",
                            field: "gpsDisplay",
                        },
                        {
                            title: "Last Schedule Update",
                            field: "lastUpdate",
                        },
                        {
                            title: "Last Photo Sent",
                            field: "imageTime",
                        },
                        {
                            title: "Photos received",
                            field: "photoCount",
                        },
                        {
                            title: "GPS Points Received",
                            field: "gpsPointCount",
                        },
                    ]}
                    data={buoyData}
                    options={{
                        search: false,
                        filtering: true,
                        paging: false,
                        idSynonym: "name",
                        showTitle: false,
                        toolbar: false,
                    }}
                ></RTable>
            </Grid>
        </Grid>
    );
}

function VerificationBuoyStatusTablePage(props) {
    return <VerificationBuoyStatusTable {...props} params={useParams()} />;
}

export default VerificationBuoyStatusTablePage;
