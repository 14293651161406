import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import MessageHelper from "../helper/MessageHelper";
import TextField from "@mui/material/TextField";
import { errorMessage, successMessage } from "../helper/MessageMethodHelper";

import APIClient from "../../models/APIClient";
import BuoyFirmwareConfigForm from "../helper/BuoyFirmwareConfigForm";
import { Container } from "@mui/system";
import FirmwareVersionSelector from "../components/FirmwareVersionSelector";
import { Typography } from "@mui/material";

const styles = (theme) => ({
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    fullWidth: {
        maxWidth: "100%",
        padding: "0px",
    },
    selectTemplate: {
        margin: "10px 0",
    },
    spaced: {
        padding: "25px 0px",
    },
});

class FirmwareConfigSelector extends Component {
    constructor(props) {
        super(props);
        // TODO for future refacor so we take in buoy ID or null if buoy id we get current version else we show version selector
        this.state = {
            firmwareVersionSelectable:
                this.props.firmwareVersionSelectable || false,
            description: "",
            selectedFirmwareVersion: this.props.defaultFirmwareVersion || {},
            selectedSchedule: this.props.defaultSchedule || [], //Array of job objects. Job has cron, enum and flag keys, flag is array of flag object flag has enum and arg keys
            selectableJobs: {}, //Object, key is job ebum, value is array of flag enums
            configTemplates: [],
            selectedTemplate: {}, //object
            // for contained form
            requiresConfig: false,
            sendToBuoy: false,
            templatesLoaded: false,
        };

        this.selectFirmwareVersion = this.selectFirmwareVersion.bind(this);
        this.setSelectedSchedule = this.setSelectedSchedule.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.sendConfigUpdate = this.sendConfigUpdate.bind(this);
    }

    async componentDidMount() {
        await this.getConfigTemplates();
        if (this.props.loadDefaultFrimwareVersion) {
            await this.loadCurrentFirmwareVersion();
        }
        if (this.props.loadDefaultSchdule) {
            await this.loadCurrentlyInstalledSchedule();
        }
    }

    async componentDidUpdate() {
        if (!this.state.templatesLoaded && this.props.labels?.length) {
            await this.getConfigTemplates();
        }
        if (this.props.hasConfigUpdate) {
            this.props.appliedUpdate();

            const template = {
                name: this.props.template_name,
                contents: {
                    jobs: this.props.config.json || [],
                },
            };
            if (
                !this.state.configTemplates.filter(
                    (template) => template.name === this.props.template_name
                )?.length
            ) {
                const configTemplates = [...this.state.configTemplates];
                configTemplates.unshift(template);
                this.setState({
                    configTemplates,
                });
            }
            this.setState({
                selectedSchedule: this.props.config.json,
                selectedTemplate: template,
            });
        }
    }

    async loadCurrentFirmwareVersion() {
        const apiClient = new APIClient();
        const firmwareVersion = await apiClient.getBuoyFirmwareVersion(
            this.props.params.thing_id
        );
        if (!firmwareVersion) {
            //no firmware version has been set for buoy
            this.setState({ firmwareVersionSelectable: true });
            return;
        }
        //Convert string to JSON if it is not converted already
        if (typeof firmwareVersion.config !== "object") {
            firmwareVersion.config = JSON.parse(firmwareVersion.config);
        }
        if (firmwareVersion) {
            this.selectFirmwareVersion(firmwareVersion);
        }
    }

    async loadCurrentlyInstalledSchedule() {
        const apiClient = new APIClient();
        const installedConfig = (
            await apiClient.getBuoyInstalledConfigs(this.props.params.thing_id)
        )?.[0];
        if (installedConfig) {
            const template = {
                name: "Installed schedule",
                contents: {
                    jobs: installedConfig.config,
                },
            };
            const configTemplates = [...this.state.configTemplates];
            configTemplates.unshift(template);
            this.setState({ selectedTemplate: template, configTemplates });
        }
    }

    async getConfigTemplates() {
        const apiClient = new APIClient();
        try {
            let configTemplates = await apiClient.fetchConfigTemplates();
            //filter only to get firmware
            configTemplates = configTemplates.filter(
                (x) => x.config_type === "firmware"
            );
            //filter only to get configs that apply to the buoy type. we check the json "targets" property
            configTemplates = configTemplates.filter((x) =>
                x.contents.targets?.some((r) => this.props.labels.includes(r))
            );
            //Add a custom config which will store the local changes as part of the session
            let customEmptyTemplate = {
                name: "Custom",
                contents: {
                    jobs: [],
                },
            };
            configTemplates.unshift(customEmptyTemplate);
            this.setState({
                configTemplates,
                selectedTemplate: customEmptyTemplate,
            });
            if (this.props.labels?.length) {
                this.setState({ templatesLoaded: true });
            }
        } catch (e) {
            console.error(e);
        }
    }

    selectFirmwareVersion(version) {
        let selectableJobs = {};
        version.config?.jobs?.forEach((job) => {
            selectableJobs[job.enum] = job.flags.reduce((value, flag) => {
                //if the settings_config.json has arg:0 then the argument will be availanle
                //otherwise it will only be a flag
                value[flag.enum] = flag.arg === 0;
                return value;
            }, {});
        });
        const requiresConfig = Object.keys(selectableJobs).length;
        this.props.setRequiresConfig(requiresConfig);
        if (Object.keys(selectableJobs).length == 0) {
            this.setSelectedSchedule([]);
        }
        this.props.setFirmwareVersion(version);
        this.setState({
            selectedFirmwareVersion: version,
            selectableJobs,
            requiresConfig,
        });
    }
    setSelectedSchedule(jobs) {
        //Filter jobs
        jobs = jobs.filter((job) => this.state.selectableJobs[job.enum]);
        //filter the flags of jobs
        jobs = jobs.map((job) => {
            job.flags = job.flags.filter(
                (flag) =>
                    this.state.selectableJobs[job.enum][flag.enum] != undefined
            );
            return job;
        });
        this.props.setSelectedSchedule(jobs);
        this.setState({ selectedSchedule: jobs });
    }
    selectTemplate(event) {
        let template = this.state.configTemplates.filter(
            (template) => template.name == event.target.value
        )?.[0];
        let selectableJobs = this.state.selectableJobs;
        //Filter out unsupported jobs and flags from selected template
        if (template) {
            template.contents.jobs = template.contents.jobs.filter((job) =>
                Object.keys(selectableJobs).map(Number).includes(job.enum)
            );
            template.contents.jobs = template.contents.jobs.map((job) => {
                let allowedFlags = selectableJobs[job.enum];
                job.flags = job.flags.filter(
                    (flag) => allowedFlags[flag.enum] != undefined
                );
                return job;
            });
        } else {
            template = {
                name: "Custom",
                contents: {
                    jobs: [],
                },
            };
        }
        this.setState({
            selectedTemplate: template,
        });
    }

    async sendConfigUpdate() {
        const apiClient = new APIClient();
        if (!this.state.requiresConfig) {
            return;
        }

        try {
            await apiClient.createFirmwareConfigRequest(
                this.props.params.thing_id,
                this.state.selectedSchedule,
                this.state.sendToBuoy,
                this.state.description
            );
            this.setState(successMessage(`Config successfully pushed!`));
        } catch (e) {
            this.setState(errorMessage(e));
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Container className={classes.fullWidth}>
                <h1>Schedule Manager</h1>
                {this.state.firmwareVersionSelectable ? (
                    <FirmwareVersionSelector
                        {...this.props}
                        selectFirmwareVersion={this.selectFirmwareVersion}
                    />
                ) : (
                    <Typography className={classes.spaced}>
                        Firmware version:{" "}
                        {this.state.selectedFirmwareVersion?.version}{" "}
                        {this.state.selectedFirmwareVersion?.description}
                    </Typography>
                )}
                {JSON.stringify(this.state.selectedFirmwareVersion) != "{}" &&
                    JSON.stringify(this.state.selectableJobs) != "{}" && (
                        <FormControl fullWidth>
                            <InputLabel id="config-template-select-label">
                                Config Template
                            </InputLabel>
                            <Select
                                id="config-template-select-standard"
                                labelId="config-template-select-label"
                                label="Config Template"
                                defaultValue="Custom"
                                value={this.state.selectedTemplate.name}
                                onChange={this.selectTemplate}
                                className={classes.selectTemplate}
                            >
                                {this.state.configTemplates.map(({ name }) => {
                                    return (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            default={name == "Custom"}
                                        >
                                            {name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                {JSON.stringify(this.state.selectedFirmwareVersion) != "{}" &&
                    JSON.stringify(this.state.selectableJobs) != "{}" && (
                        <BuoyFirmwareConfigForm
                            ref={this.firmwareConfigForm}
                            selectableJobs={this.state.selectableJobs}
                            selectSchedule={this.setSelectedSchedule}
                            selectedTemplate={this.state.selectedTemplate}
                        />
                    )}

                {JSON.stringify(this.state.selectableJobs) != "{}" && (
                    <Container className={[classes.fullWidth, classes.spaced]}>
                        <TextField
                            id="Description"
                            label="Description"
                            placeholder="Write a description of what this config update is for."
                            required="true"
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={3}
                            onChange={(e) => {
                                this.setState({
                                    description: e.target.value,
                                });
                                this.props.descriptionUpdate &&
                                    this.props.descriptionUpdate(
                                        e.target.value
                                    );
                            }}
                        />
                    </Container>
                )}
                {JSON.stringify(this.state.selectableJobs) == "{}" && (
                    <Typography>
                        This version does not support configs.
                    </Typography>
                )}
                {this.props.showForm && (
                    <Container className={classes.fullWidth}>
                        <Container
                            className={[classes.fullWidth, classes.spaced]}
                        >
                            <input
                                className={classes.title}
                                type="checkbox"
                                onChange={(event) =>
                                    this.setState({
                                        sendToBuoy: event.target.checked,
                                    })
                                }
                                defaultChecked={this.state.sendToBuoy}
                            />
                            <span>
                                Send To Buoy - (by checking this the config
                                update will be sent over sat & stored on the
                                dashboard. Without checking this the configs are
                                only stored on the dashboard side)
                            </span>
                        </Container>

                        <Container
                            className={[classes.fullWidth, classes.spaced]}
                        >
                            <Button
                                disabled={
                                    !this.state.requiresConfig ||
                                    (this.state.requiresConfig &&
                                        !this.state.selectedSchedule.length) ||
                                    this.state.description.length === 0
                                }
                                variant="secondary"
                                onClick={this.sendConfigUpdate}
                            >
                                {this.state.sendToBuoy
                                    ? "Send to Buoy & Save"
                                    : "Save"}
                            </Button>
                        </Container>

                        {/* Show an error if the push request is not valid. */}
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Container>
                )}
            </Container>
        );
    }
}

export default withStyles(styles)(FirmwareConfigSelector);
