import React, { Component } from "react";
import AdministrationAPIClient from "../../../models/AdministrationAPIClient";
import { Grid, Stack } from "@mui/material";
import RTable from "../../components/RTable";
import {
    Action,
    AUTH_ADMINISTRATION,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import { PermissionPill } from "./PermissionPill";
import { EditPermissions } from "./EditPermissions";
import { RequirePermissions } from "../../components/RequirePermissions";

class Groups extends Component {
    state = { pagedUsers: [], pageNumber: 1 };
    constructor(props) {
        super(props);
        this.client = new AdministrationAPIClient();
    }

    async onRowUpdate(newData, oldData) {
        // Update name and default properties if either has changed
        const newName =
            newData.name !== oldData.name ? newData.name : undefined;
        const newDefault =
            newData.default !== oldData.default ? newData.default : undefined;

        if (newName !== undefined || !!newDefault !== undefined)
            await this.client.updateGroup(oldData.id, {
                name: newName,
                default: newDefault,
            });

        // Add and remove permissions where applicable.
        const addedPermissions = newData.permissions.filter(
            (newPermission) =>
                !oldData.permissions.some(
                    (oldPermission) => oldPermission.id === newPermission.id
                )
        );
        const removedPermissions = oldData.permissions.filter(
            (oldPermission) =>
                !newData.permissions.some(
                    (newPermission) => oldPermission.id === newPermission.id
                )
        );
        const addingPromises = addedPermissions.map((p) =>
            this.client.addPermissionToGroup(newData.id, p)
        );
        const removePromises = removedPermissions.map((p) =>
            this.client.removePermissionFromGroup(newData.id, p.id)
        );
        return Promise.all(addingPromises.concat(removePromises));
    }
    getGroupTableData(q) {
        const orderBy = q.orderBy != undefined ? q.orderBy.field : "name";
        const nameFilter = q.filters.find((f) => f.column.field === "name");
        const defaultFilter = q.filters.find(
            (f) => f.column.field === "default"
        );
        return new Promise((resolve) => {
            this.client
                .getGroups(
                    parseInt(q.page) + 1,
                    q.pageSize,
                    orderBy,
                    q.orderDirection,
                    nameFilter?.value,
                    defaultFilter?.value
                )
                .then((r) =>
                    resolve({
                        data: r.data,
                        page: r.pageNumber - 1,
                        totalCount: r.totalCount,
                    })
                );
        });
    }
    render() {
        const permissions = [
            `${AUTH_ADMINISTRATION}:${Action.CREATE}`,
            `${AUTH_ADMINISTRATION}:${Action.READ}`,
            `${AUTH_ADMINISTRATION}:${Action.DELETE}`,
            `${AUTH_ADMINISTRATION}:${Action.UPDATE}`,
        ];

        return (
            <RequirePermissions permissions={permissions} showFallback>
                <Stack>
                    <RTable
                        columns={[
                            {
                                title: "Name",
                                field: "name",
                                editable: "always",
                            },
                            {
                                title: "Is default",
                                field: "default",
                                editable: "always",
                                type: "boolean",
                            },
                            {
                                title: "Permissions",
                                field: "permissions",
                                editable: "always",
                                filtering: false,
                                sorting: false,
                                width: "75%",
                                render: (rowData) => (
                                    <Grid container spacing={1} xs={12}>
                                        {rowData.permissions.map(
                                            (permission) => (
                                                <PermissionPill
                                                    key={permission.id}
                                                    permission={permission}
                                                    client={this.client}
                                                />
                                            )
                                        )}
                                    </Grid>
                                ),
                                editComponent: (props) => {
                                    return (
                                        <EditPermissions
                                            client={this.client}
                                            permissions={props.value}
                                            onChange={props.onChange}
                                        ></EditPermissions>
                                    );
                                },
                            },
                        ]}
                        data={(q) => this.getGroupTableData(q)}
                        options={{
                            search: false,
                            filtering: true,
                            pageSize: 20,
                            pageSizeOptions: [],
                        }}
                        title="Users"
                        editable={{
                            isEditable: () => true,
                            onRowUpdate: (newData, oldData) =>
                                this.onRowUpdate(newData, oldData),
                        }}
                    ></RTable>
                </Stack>
            </RequirePermissions>
        );
    }
}

export default Groups;
