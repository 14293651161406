import { datadogRum } from "@datadog/browser-rum";
import config from "../config";

export default class DataDog {
    start() {
        if (config.datadogApplicationID && config.datadogClientToken) {
            datadogRum.init({
                applicationId: config.datadogApplicationID,
                clientToken: config.datadogClientToken,
                site: "datadoghq.com",
                service: "dashboard",
                env: config.env,
                version: config.appVersion,
                sessionSampleRate: config.datadogSampleRate,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: "mask-user-input",
            });

            datadogRum.startSessionReplayRecording();
        }
    }
}
