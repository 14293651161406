import mapboxgl from "mapbox-gl";
import {
    convertDMS,
    convertToDMSLat,
    convertToDMSLong,
    convertToDegreesAndDecimalMinutes,
} from "../../../pages/helper/gps/CoordinateConverter";
import { findMeanOfPoints, sortLocationDecisionPoints } from "./MapHelper";
import { MAP_COLORS } from "../../../config/theme";

const LOCATION_DECISION_ID = "location-decision";
const LOCATION_DECISION_LABEL = "Target Deployment Area";

const locationDecisionPopUp = new mapboxgl.Popup({
    closeButton: true,
    closeOnClick: false,
});

const locationDecisionStyles = {
    fill: {
        color: MAP_COLORS.locationDecision.primary,
        baseOpacity: 0,
        hoveredOpacity: 0.3,
    },
    outline: {
        color: MAP_COLORS.locationDecision.primary,
        width: 1.2,
        opacity: 1,
        dashSequence: [3, 2, 1],
    },
    vertices: {
        color: MAP_COLORS.locationDecision.primary,
        radius: 3,
        baseStrokeWidth: 0,
        hoveredStrokeWidth: 1,
        strokeColor: MAP_COLORS.locationDecision.primary,
        baseOpacity: 0,
        hoveredOpacity: 1,
    },
    label: {
        value: LOCATION_DECISION_LABEL,
        fontFamily: ["Open Sans Semibold", "Arial Unicode MS Bold"],
        fontSize: 11,
        textJustify: "center",
        textAnchor: "center",
        fontColor: MAP_COLORS.locationDecision.primaryFontColor,
    },
};

// This method is used to append the coordinates to the location decision polygon to the map
export function addLocationDecisionDataSource(locationDecisionObject) {
    // Add a data source containing GeoJSON data only if it does not exist
    !this.map.getSource(LOCATION_DECISION_ID) &&
        // Add a data source containing GeoJSON data.
        this.map.addSource(LOCATION_DECISION_ID, {
            type: "geojson",
            lineMetrics: true,
            data: {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    // These coordinates outline Maine.
                    coordinates: [
                        sortLocationDecisionPoints(
                            locationDecisionObject.coordinatesArray.map((c) => [
                                c.lon,
                                c.lat,
                            ])
                        ),
                    ],
                },
            },
        });
}

// This method is used to add the location decision polygon to the map
export function addLocationDecisionFillAndOutlineLayer() {
    // Add a new layer to visualize the location decision area.
    !this.map.getLayer(LOCATION_DECISION_ID + "-fill") &&
        this.map.addLayer({
            id: LOCATION_DECISION_ID + "-fill",
            type: "fill",
            source: LOCATION_DECISION_ID, // reference the data source
            layout: {},
            paint: {
                "fill-color": locationDecisionStyles.fill.color,
                "fill-opacity": locationDecisionStyles.fill.baseOpacity,
            },
        });

    !this.map.getLayer(LOCATION_DECISION_ID + "-outline") &&
        this.map.addLayer({
            id: LOCATION_DECISION_ID + "-outline",
            type: "line",
            source: LOCATION_DECISION_ID + "",
            layout: {},
            paint: {
                "line-color": locationDecisionStyles.outline.color,
                "line-width": locationDecisionStyles.outline.width,
                "line-opacity": locationDecisionStyles.outline.opacity,
                "line-dasharray": locationDecisionStyles.outline.dashSequence,
            },
        });
}

// This method is used to add the circles to the corners of the location decision polygon
export function addLocationDecisionVerticesCircleLayer(locationDecisionObject) {
    // Add the 4 coordinate points as circles
    !this.map.getSource(LOCATION_DECISION_ID + "-vertices") &&
        this.map.addSource(LOCATION_DECISION_ID + "-vertices", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: sortLocationDecisionPoints(
                    locationDecisionObject.coordinatesArray.map((c) => [
                        c.lon,
                        c.lat,
                    ])
                ).map((c) => ({
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Point",
                        coordinates: [c[0], c[1]],
                    },
                })),
            },
        });

    // Add a circle layer
    !this.map.getLayer(LOCATION_DECISION_ID + "-vertices-circle") &&
        this.map.addLayer({
            id: LOCATION_DECISION_ID + "-vertices-circle",
            type: "circle",
            source: LOCATION_DECISION_ID + "-vertices",
            paint: {
                "circle-color": locationDecisionStyles.vertices.color,
                "circle-radius": locationDecisionStyles.vertices.radius,
                "circle-stroke-width":
                    locationDecisionStyles.vertices.baseStrokeWidth,
                "circle-opacity": locationDecisionStyles.vertices.baseOpacity,
                "circle-stroke-color":
                    locationDecisionStyles.vertices.strokeColor,
            },
        });
}

// This method is used to add the label for the location decision area
export function addLocationDecisionEventListeners(locationDecisionObject) {
    // ADDING THE VARIOUS EVENT LISTENERS RELATED TO THE LOCATION DECISION AREA

    const _this = this;

    // Center the map on the center of the location decision area
    this.map.on("click", LOCATION_DECISION_ID + "-fill", (e) => {
        this.map.flyTo({
            center: findMeanOfPoints(e.features[0].geometry.coordinates[0]),
        });

        const clipboardCopyContent = {
            degreesMinutesSeconds: [],
            degreesAndDecimalMinutes: [],
            decimalDegrees: [],
        };

        const decimalDegreesCoordinates =
            locationDecisionObject.coordinatesArray;

        decimalDegreesCoordinates.forEach((coordinatePair) => {
            const { lat, lon } = coordinatePair;
            clipboardCopyContent.degreesMinutesSeconds.push([
                convertToDMSLat(lat),
                convertToDMSLong(lon),
            ]);
            clipboardCopyContent.degreesAndDecimalMinutes.push([
                convertToDegreesAndDecimalMinutes(lat, true),
                convertToDegreesAndDecimalMinutes(lon, false),
            ]);

            clipboardCopyContent.decimalDegrees.push([lat, lon]);
        });

        // Copying to clipboard
        navigator.clipboard.writeText(
            JSON.stringify(clipboardCopyContent, null, 2).replace(/[\\]/gi, "")
        );

        //  Show notification alert on the UI
        _this.setState({
            showCoordinatesCopiedAlert: true,
        });

        locationDecisionPopUp.addTo(this.map);
        this.activePopups["location-decision-popup"] = locationDecisionPopUp;

        setTimeout(() => {
            _this.setState({
                showCoordinatesCopiedAlert: false,
            });
        }, 4000);
    });

    // When the user moves their mouse over the location-decision-fill area,
    // we want to give it hover effects - update the opacity of the fill and show the circles
    // and the pop ups
    this.map.on("mouseover", LOCATION_DECISION_ID + "-fill", () => {
        showLocationDecisionAreaHoverEffects.bind(this)();
    });

    // When the mouse leaves the location-decision-fill area, update the opacity of the fill
    // and hide the circles and the pop ups
    this.map.on("mouseleave", LOCATION_DECISION_ID + "-fill", () => {
        hideLocationDecisionAreaHoverEffects.bind(this)();
    });
}

// Add the label "Location Decision Area" to the map
export function addLocationDecisionAreaLabel(locationDecisionObject) {
    // We want to show the label on the location decision area
    !this.map.getSource(LOCATION_DECISION_ID + "-label") &&
        // Add the label "Location Decision Area" to the map
        this.map.addSource(LOCATION_DECISION_ID + "-label", {
            type: "geojson",
            data: {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: findMeanOfPoints(
                        locationDecisionObject.coordinatesArray.map((c) => [
                            c.lon,
                            c.lat,
                        ])
                    ),
                },
            },
        });

    // Add the label to the map
    !this.map.getLayer(LOCATION_DECISION_ID + "-label") &&
        this.map.addLayer({
            id: LOCATION_DECISION_ID + "-label",
            type: "symbol",
            source: LOCATION_DECISION_ID + "-label",
            layout: {
                "text-field": locationDecisionStyles.label.value,
                "text-font": locationDecisionStyles.label.fontFamily,
                "text-size": locationDecisionStyles.label.fontSize,
                "text-justify": locationDecisionStyles.label.textJustify,
                "text-anchor": locationDecisionStyles.label.textAnchor,
            },
            paint: {
                "text-color": locationDecisionStyles.label.fontColor,
            },
        });
}

// Helper function to adjust the z-index of the layers
// related to the location decision area
export function adjustLayerZIndexLocationDecision() {
    this.map.moveLayer(LOCATION_DECISION_ID + "-vertices-circle");
    this.map.moveLayer(LOCATION_DECISION_ID + "-outline");
    this.map.moveLayer(LOCATION_DECISION_ID + "-fill");
    this.map.moveLayer(LOCATION_DECISION_ID + "-label");
}

export function initializePopUp(className, coordinates) {
    if (!coordinates || coordinates.length === 0) return;

    locationDecisionPopUp.addClassName(className);

    // fill it with content
    let locationDecisionPopUpMessage = `<b>${LOCATION_DECISION_LABEL}</b></br>`;

    coordinates?.forEach((coordinate, index) => {
        const out = convertDMS(coordinate["lat"], coordinate["lon"]);
        const description = `<b>Point ${
            index + 1
        }:</b> <span style="color:black;" >[${out[0]},   ${
            out[1]
        }]</span></br>`;
        locationDecisionPopUpMessage += description;
    });

    locationDecisionPopUp
        ?.setLngLat(coordinates[0])
        .setHTML(locationDecisionPopUpMessage);

    locationDecisionPopUp.on("close", () => {
        delete this.activePopups["location-decision-popup"];
    });
}

// Show the hover effects on the location decision area
function showLocationDecisionAreaHoverEffects() {
    this.map.getCanvas().style.cursor = "pointer";

    // update the opacity of the fill

    this.map.setPaintProperty(
        LOCATION_DECISION_ID + "-fill",
        "fill-opacity",
        locationDecisionStyles.fill.hoveredOpacity
    );

    // show the circles
    this.map.setPaintProperty(
        LOCATION_DECISION_ID + "-vertices-circle",
        "circle-opacity",
        locationDecisionStyles.vertices.hoveredOpacity
    );

    this.map.setPaintProperty(
        LOCATION_DECISION_ID + "-vertices-circle",
        "circle-stroke-width",
        locationDecisionStyles.vertices.hoveredStrokeWidth
    );
}

// Hide the hover effects on the location decision area
function hideLocationDecisionAreaHoverEffects() {
    this.map.getCanvas().style.cursor = "";

    this.map.setPaintProperty(
        LOCATION_DECISION_ID + "-fill",
        "fill-opacity",
        locationDecisionStyles.fill.baseOpacity
    );

    // hide the circles and the pop ups
    this.map.setPaintProperty(
        LOCATION_DECISION_ID + "-vertices-circle",
        "circle-stroke-width",
        locationDecisionStyles.vertices.baseStrokeWidth
    );

    this.map.setPaintProperty(
        LOCATION_DECISION_ID + "-vertices-circle",
        "circle-opacity",
        locationDecisionStyles.vertices.baseOpacity
    );
}
