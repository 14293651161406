import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import {
    Grid,
    Box,
    FormControl,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import APIClient from "../../models/APIClient";
import AutocompleteEditComponent from "../sections/AutocompleteEditComponent";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

class FirmwareVersionSelecor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firmwareVersions: [],
            latestProductionFirmwareVersion: null,
            isLoading: true,
            selectedFirmwareVersion: "choose firmware version",
            showAll: false,
            warnNotLatestProduction: false,
        };
        this.updateSelectedFirmwareVersion =
            this.updateSelectedFirmwareVersion.bind(this);
    }

    async componentDidMount() {
        let promises = [this.getFirmwareVersions()];
        await Promise.all(promises);
        this.setState({
            isLoading: false,
        });
    }

    async getFirmwareVersions() {
        const apiClient = new APIClient();
        try {
            const firmwareVersions =
                (await apiClient.getFirmwareVersions()) || [];
            //convert json to text
            const sanitizedFirmwareVersions = firmwareVersions.map(
                (firmwareVersion) => {
                    return {
                        ...firmwareVersion,
                        config: firmwareVersion.config,
                        metadata: firmwareVersion.metadata,
                        releaseVersion: firmwareVersion.version.includes("rv"),
                        version: firmwareVersion.version.replace(/(rv|v)/, ""),
                    };
                }
            );

            //Sort by version number
            const sortedAndSanitizedFirmwareVersions = sanitizedFirmwareVersions
                .map((a) => {
                    a.version = a.version
                        .split(".")
                        .map((n) => +n + 100000)
                        .join(".");
                    return a;
                })
                .sort((a, b) => a.version > b.version)
                .map((a, i) => {
                    a.version = a.version
                        .split(".")
                        .map((n) => +n - 100000)
                        .join(".");
                    a.sort_id = i;
                    return a;
                });
            //Set the latest production firmware version
            const prodFirmwareVersions =
                sortedAndSanitizedFirmwareVersions.filter((v) =>
                    v.metadata?.firmware_type?.includes("prd")
                );
            const latestProductionFirmwareVersion = prodFirmwareVersions.length
                ? prodFirmwareVersions[prodFirmwareVersions.length - 1]
                : null;
            this.setState({
                firmwareVersions: sortedAndSanitizedFirmwareVersions,
                latestProductionFirmwareVersion:
                    latestProductionFirmwareVersion,
                selectedFirmwareVersion: latestProductionFirmwareVersion,
            });
            //Select the latest production firmware version as default
            this.props.selectFirmwareVersion(latestProductionFirmwareVersion);
        } catch (e) {
            console.error(e);
        }
    }

    updateSelectedFirmwareVersion(sort_id) {
        const version = this.state.firmwareVersions.filter(
            (version) => version.sort_id == sort_id
        )?.[0];
        this.props.selectFirmwareVersion(version);
        this.setState({
            selectedFirmwareVersion: version,
            warnNotLatestProduction:
                version.id !== this.state.latestProductionFirmwareVersion?.id,
        });
    }

    render() {
        let binary_options = {};

        this.state.firmwareVersions.forEach((version) => {
            const type = version.metadata?.firmware_type;
            if (!this.state.showAll && type?.includes("dev")) {
                return;
            }
            const targets = version.metadata?.targets;
            const targetMatch = Boolean(
                targets?.filter((target) => this.props.labels?.includes(target))
                    .length
            );
            //Adding extra condition to render it if the firmware does not have targets
            if (this.props.labels?.length && !targetMatch && targets) {
                return;
            }
            const name = version.version;
            const description = version.description;
            const sort_id = version.sort_id;
            const rtt_on = version.metadata?.rtt_on;
            binary_options[sort_id] = `${type} - ${targets?.join(
                ","
            )} - ${name} - ${description} - RTT:${
                rtt_on == null ? "N/A" : rtt_on ? "ON" : "OFF"
            }`;
        });
        return (
            <Grid>
                <Grid item lg={12} xs={12}>
                    <Box>
                        <FormControl fullWidth>
                            <AutocompleteEditComponent
                                label="Firmware Version"
                                value={
                                    this.state.selectedFirmwareVersion.sort_id
                                }
                                defaultValue="choose firmware version"
                                required
                                onChange={
                                    //TODO make sure this works
                                    this.updateSelectedFirmwareVersion
                                }
                                multiple={false}
                                idsToLabels={binary_options}
                                sort={false}
                            />
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.showAll}
                                            onChange={(e) =>
                                                this.setState({
                                                    showAll: e.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="Show Development Versions"
                                />
                            </FormGroup>

                            {this.state.warnNotLatestProduction && (
                                <Typography variant="body2" color="error">
                                    <WarningIcon />
                                    Warning: You don't have the latest
                                    production firmware version selected
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(FirmwareVersionSelecor);
