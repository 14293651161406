import React, { Component } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    Tooltip,
    Typography,
    Container,
} from "@mui/material";
import { CheckCircle, Error, FlagCircle } from "@mui/icons-material";
import {
    FIRMWARE_BUOY_TEST_PASS,
    FIRMWARE_BUOY_TEST_PASS_WITH_WARNING,
} from "../helper/FirmwareBuoyServerBoundHelper";

export class FirmwareBuoyTestComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: props.timestamp,
            tests: props.tests,
        };
    }

    render() {
        return (
            <Container disableGutters={true}>
                <Typography>
                    Ran on {this.state.timestamp.year}-
                    {this.state.timestamp.month}-{this.state.timestamp.day} at{" "}
                    {this.state.timestamp.hour}:{this.state.timestamp.minute}:
                    {this.state.timestamp.second} UTC
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Test</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tests.map((row) => {
                                return (
                                    <TableRow key={row.test}>
                                        <TableCell component="th" scope="row">
                                            {row.test}
                                        </TableCell>
                                        <TableCell>
                                            {row.pass ==
                                            FIRMWARE_BUOY_TEST_PASS ? (
                                                <Tooltip
                                                    title={"Test Passed"}
                                                    placement="right"
                                                >
                                                    <Chip
                                                        icon={<CheckCircle />}
                                                        color="primary"
                                                    />
                                                </Tooltip>
                                            ) : row.pass ==
                                              FIRMWARE_BUOY_TEST_PASS_WITH_WARNING ? (
                                                <Tooltip
                                                    title={
                                                        "Test Passed with Warnings"
                                                    }
                                                    placement="right"
                                                >
                                                    <Chip
                                                        icon={<FlagCircle />}
                                                        color="warning"
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip
                                                    title={"Test Failed"}
                                                    placement="right"
                                                >
                                                    <Chip
                                                        icon={<Error />}
                                                        color="error"
                                                    />
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell>{row.message}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    }
}
