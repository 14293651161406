import React, { Component } from "react";
import Paper from "@mui/material/Paper";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";
import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import BuoyConfigSender from "../helper/BuoyConfigSender";
import FirmwareConfigSelector from "../components/FirmwareConfigSelector";

class BuoyControl extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.pushConfigs = this.pushConfigs.bind(this);
    }

    pushConfigs = async (configsObject, sendToBuoy) => {
        const apiClient = new APIClient();
        if (Object.entries(configsObject).length === 0) {
            return;
        }

        try {
            await apiClient.createConfigRequest(
                this.props.params.thing_id,
                configsObject,
                sendToBuoy
            );
            this.setState(successMessage(`Config successfully pushed!`));
        } catch (e) {
            this.setState(errorMessage(e));
        }
    };

    render() {
        return (
            <Paper>
                {this.props.selectorLabels.includes("ble-buoy") ? (
                    <FirmwareConfigSelector
                        {...this.props}
                        loadDefaultSchdule={true}
                        loadDefaultFrimwareVersion={true}
                        firmwareVersionSelectable={false}
                        setSelectedSchedule={() => {}}
                        setRequiresConfig={() => {}}
                        setFirmwareVersion={() => {}}
                        showForm={true}
                        labels={this.props.selectorLabels}
                    />
                ) : (
                    <BuoyConfigSender pushConfigs={this.pushConfigs} />
                )}

                {/*Show an error if the push request is not valid.*/}
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Paper>
        );
    }
}

export default BuoyControl;
